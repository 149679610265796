import React from "react"
import { RemoveCircle } from "@material-ui/icons"

import classes from "../../modals.module.css"

interface Props {
  padding?: boolean
  handleClick: () => void
  quantity: number
  typography: string
}

const ExtraCategoriesModalLeft = ({
  quantity,
  handleClick,
  padding,
  typography,
}: Props) => {
  return (
    <div
      style={{ borderLeft: `10px solid ${typography}`, color: typography, paddingTop: padding ? "0px" : "6px" }}
      className={classes.addtocart}
    >
      {quantity && (
        <div
          className={[
            "whitespace-no-wrap ml-2 mb-2 font-bold",
            padding && "pt-2 ",
          ].join(" ")}
        >
          {quantity}x
        </div>
      )}
      <div className={[classes.remove_button, padding && "pb-2"].join(" ")}>
        <RemoveCircle
          style={{ color: typography }}
          className="ml-1"
          onClick={handleClick}
        />
      </div>
    </div>
  )
}

export default ExtraCategoriesModalLeft
