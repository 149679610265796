import React from "react"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import DateRangeIcon from "@material-ui/icons/DateRange"
import moment from "moment"

import { Heading5, Label14Bold } from "@components/Text/Text"

export interface Props {
  item?: any
  onClick?: any
  typography?: string
}

const OrderListItem = ({ item, onClick, typography }: Props) => {
  return (
    <div
      className={
        "flex w-full justify-between items-center bg-white pl-6 pt-6 pb-4"
      }
      style={{ borderBottom: "0.5px solid #c1b9b9" }}
      onClick={onClick}
    >
      <div className="flex flex-col">
        <Heading5 style={{ fontSize: 15 }}>
          {item.type == "2" ? item.address.address : item.restaurantTitle}
        </Heading5>
        <div className={"flex mt-4 items-center"}>
          <DateRangeIcon
            style={{ fontSize: 22, color: typography, marginRight: 6 }}
          />
          <Label14Bold style={{ color: "gray" }}>
            {moment(item.createdAt).format("DD-MM-YYYY HH:mm")}
          </Label14Bold>
        </div>
      </div>
      <div className="flex items-center">
        {item.discountVoucherId ? (
          <>
            <Label14Bold
              style={{ color: "black", marginRight: 5 }}
              className="line-through"
            >
              {item.price + "€"}
            </Label14Bold>
            <Label14Bold style={{ color: typography }}>
              {item.price - item.discountVoucherAmount + "€"}
            </Label14Bold>
          </>
        ) : (
          <Label14Bold style={{ color: typography }}>
            {item.price.toFixed(2) + "€"}
          </Label14Bold>
        )}

        <ChevronRightIcon style={{ fontSize: 34, color: typography }} />
      </div>
    </div>
  )
}

export default OrderListItem
