import React from "react"
import { useDispatch } from "react-redux"

import { decreaseItemAmountOrderPage } from "@store/cart/cartActions"
import MenuItemMobile from "@modules/menuitem/mobile/MenuItemMobile"
import classes from "@components/tables/tablecenter/tableCenter.module.css"
import { Label19Bold } from "@components/Text/Text"

export interface Props {
  val: any
  handleMenuItemClick: (item: any) => void
  cartItems?: any
  categoryTitle?: string
}

const MobileCategoryItem = ({
  val,
  handleMenuItemClick,
  cartItems,
  categoryTitle,
}: Props) => {
  const dispatch = useDispatch()
  return (
    <div className={classes.item_list}>
      <ul>
        {val.menuList && val.menuList.length < 1 && (
          <li>
            <div
              className={"py-3 w-full flex items-center"}
              style={{ backgroundColor: "#F8F8F8" }}
            >
              <Label19Bold style={{ fontWeight: "bold", marginLeft: "0.5rem" }}>
                {categoryTitle}
              </Label19Bold>
            </div>
          </li>
        )}
        {val.menuList &&
          val.menuList.length > 0 &&
          val.menuList.map((item, i) => (
            <li>
              {i == 0 && (
                <div
                  className={"py-3 w-full flex items-center"}
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  <Label19Bold
                    style={{ fontWeight: "bold", marginLeft: "0.5rem" }}
                  >
                    {categoryTitle}
                  </Label19Bold>
                </div>
              )}
              <MenuItemMobile
                item={item}
                onClick={() => handleMenuItemClick(item)}
                quantity={
                  cartItems.find((cartItem) => cartItem._id === item._id)
                    ?.quantity
                }
                onItemDecrease={() =>
                  dispatch(decreaseItemAmountOrderPage({ item }))
                }
              />
            </li>
          ))}
      </ul>
    </div>
  )
}

export default MobileCategoryItem
