import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { Box, Typography } from "@material-ui/core"
import { MyMapComponent } from "@modules/address/MyMapComponent"
import CustomModal from "./CustomModal"
import Button from "@components/buttons/Button"

const AddressMapModal = ({
  showMap,
  typography,
  setOpenMap,
  clearData,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  onMapSubmit,
}) => {
  let [mapRef, setMapRef] = useState<any>(React.createRef())

  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const addresses = useSelector(({ user }: any) => user.addresses)

  const { contactInfo } = venueDetail
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        ? // Permission API is implemented
          navigator.permissions
            .query({
              name: "geolocation",
            })
            .then((permission) =>
              // is geolocation granted?
              permission.state === "granted"
                ? navigator.geolocation.getCurrentPosition(
                    (position) => {
                      const { latitude, longitude } = position.coords
                      setLatitude(latitude)
                      setLongitude(longitude)
                    },
                    (err) => {
                      if (contactInfo) {
                        const { latitude, longitude } = contactInfo
                        setLatitude(parseFloat(latitude))
                        setLongitude(parseFloat(longitude))
                      }
                    },
                  )
                : console.log("Permission denied"),
            )
        : console.log("Permission API is not supported")
    }
  }, [contactInfo])

  useEffect(() => {
    clearData()
  }, [addresses])

  const setRef = (ref) => {
    setMapRef(ref)
  }

  return (
    <CustomModal showModal={showMap} onCloseModal={() => setOpenMap(false)}>
      <Box
        className={"flex flex-col w-4/5 md:w-6/12 bg-white"}
        style={{ height: "60%" }}
      >
        <MyMapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDD3BEbF_rZGtnnM6uITB1irLf4qc4kbgM&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<Box style={{ height: "100%" }} />}
          containerElement={<Box style={{ height: "100%" }} />}
          mapElement={<Box style={{ height: "100%" }} />}
          onMarkerMounted={setRef}
          latitude={latitude}
          longitude={longitude}
          onPositionChanged={() => {
            const position = mapRef.getPosition()
            setLatitude(position.lat())
            setLongitude(position.lng())
          }}
        />

        <Button
          title={"Crear Dirección"}
          backgroundColor={typography}
          classes={
            "flex items-center mt-4 mb-4 justify-center self-center w-2/5 md:w-1/5 h-24  text-white py-2 px-4 border border-transparent cursor-pointer outline-none"
          }
          style={{
            borderRadius: "4px",
            fontSize: "14px",
          }}
          disabled={!latitude && !longitude}
          onClick={onMapSubmit}
        />
      </Box>
    </CustomModal>
  )
}

export default AddressMapModal
