import React, { FC, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  FormControlLabel,
  Checkbox,
  makeStyles,
  Theme,
} from "@material-ui/core"

import classes from "../../modals.module.css"
import { setUniProperty } from "@store/cart/cartActions"
import { Heading5, Body13 } from "@components/Text/Text"
import { Error } from "@material-ui/icons"
import clsx from "clsx"
import { formatImageUrl } from "@helpers/utilsHelper"

interface Props {
  item: ExtraCategory
  typography: string
  onRefChange?: any
}

interface StyleProps {
  typography: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 0,
    "&$checked": {
      color: "orange",
    },
  },
  checked: ({ typography }) => ({
    color: `${typography} !important`,
  }),
}))

const ExtraCategoriesMulti: FC<Props> = ({ item, typography, onRefChange }) => {
  const { title, description: itemDescription } = item
  const [familyProperty, setFamilyProperty] = useState(item)
  const [disabled, setDisabled] = useState(false)

  const sendTried = useSelector(({ cart }: RootState) => cart.newItem.sendTried)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)

  const styleProps: StyleProps = { typography }
  const matClasses = useStyles(styleProps)

  const dispatch = useDispatch()

  useEffect(() => {
    setFamilyProperty(item)
  }, [item])

  const updateMenuExtraCategoryItem = (index) => {
    const newFamilyItems: ExtraCategoryItem[] = familyProperty.items.map(
      (item) => {
        if (item.title === familyProperty.items[index].title) {
          return { ...item, selected: !item.selected }
        }
        return item
      },
    )

    const amountSelected = newFamilyItems.filter((item) => item.selected).length
    const hasError = item.hasMaxMin && item.minQuantity!! > amountSelected

    const familyPropertyUpdated = {
      ...familyProperty,
      hasError,
      items: newFamilyItems,
    }

    const payload = {
      extraCategory: familyPropertyUpdated,
    }

    if (item.maxQuantity === amountSelected) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }

    setFamilyProperty(familyPropertyUpdated)

    dispatch(setUniProperty(payload))
  }

  return (
    <>
      <div
        className={classes.category_title}
        ref={(ref) => {
          if (item.hasError && sendTried && ref) {
            onRefChange(ref, title)
          }
        }}
      >
        <Heading5
          className={clsx(
            "w-full flex flex-row self-start items-center",
            item.hasError && sendTried && "text-red",
          )}
        >
          {item.hasError && sendTried && <Error className="text-red mr-2" />}
          {title}
        </Heading5>
        <Body13>{itemDescription}</Body13>
      </div>
      {restaurant.accessLevel && restaurant.accessLevel !== "1" ? (
        <div className={[classes.options_items, "mx-12"].join(" ")}>
          {familyProperty.items?.map((item: ExtraCategoryItem, index) => {
            const disabledFinal = disabled && !item.selected
            return (
              <FormControlLabel
                labelPlacement="start"
                className="w-full"
                classes={matClasses}
                control={
                  <Checkbox
                    disabled={disabledFinal}
                    classes={{ checked: matClasses.checked }}
                    disableRipple
                    onChange={() => updateMenuExtraCategoryItem(index)}
                    color="primary"
                  />
                }
                label={
                  <div className={"flex items-center"}>
                    {item.image && (
                      <div className={"w-10 h-10 mt-2"}>
                        <img
                          src={formatImageUrl(item.image)}
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                            objectFit: "cover",
                            borderRadius: 4,
                          }}
                        />
                      </div>
                    )}
                    <Body13
                      className={classes.title}
                      style={{ marginLeft: 8, marginBottom: 8 }}
                    >
                      {item.title}
                      {item.price && Number(item.price) > 0 ? (
                        <span className="pl-4" style={{ color: "grey" }}>
                          + {item.price}€
                        </span>
                      ) : null}
                    </Body13>
                  </div>
                }
              />
            )
          })}
        </div>
      ) : (
        <div className={[classes.options_items, "mx-12"].join(" ")}>
          {familyProperty.items?.map((item: ExtraCategoryItem, index) => (
            <Body13
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              className={classes.title}
            >
              {item.title}
              {item.price && Number(item.price) > 0 ? (
                <span className="pl-4" style={{ color: "grey" }}>
                  + {item.price}€
                </span>
              ) : null}
            </Body13>
          ))}
        </div>
      )}
    </>
  )
}

export default ExtraCategoriesMulti
