import React from "react"
import { useSelector } from "react-redux"

import { cartCounter } from "@store/cart/cartHelpers"
import {
  hasFamilyPropertyItemSelected,
  getDiscountedPrice,
} from "@store/cart/cartHelpers"
import { Typography } from "@material-ui/core"

import { Heading2, Heading5, Label14Bold } from "@components/Text/Text"

interface Props {
  items: any
  typography?: string
  deliveryPrice?: number
  selectedOption: string
}

const ItemsDetails = ({ items, typography, deliveryPrice, selectedOption }) => {
  const { totalPrice } = cartCounter(items)
  const cart = useSelector(({ cart }: RootState) => cart)
  const total = parseFloat(totalPrice)

  const discountedPrice = getDiscountedPrice(
    totalPrice,
    cart.discountApplicable,
    cart.discountType,
    cart.discountAmount,
  )

  return (
    <div className={"flex flex-col w-full mt-8 mb-8"}>
      <Heading2 style={{ marginTop: 8, marginBottom: 8, marginLeft: 18 }}>
        Pedido
      </Heading2>
      <div
        className={"flex flex-col w-full"}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {items.map((item, index) => (
          <div
            className={
              "flex flex-row items-center justify-between w-full pl-4 pr-4"
            }
            style={{
              minHeight: 35,
              borderTop: "1px solid rgb(219 206 206)",
              backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
            }}
          >
            <div className={"flex flex-row justify-between pt-2 pb-2"}>
              <Heading5>{item.quantity + "x"}</Heading5>
              <div className={"flex flex-col"}>
                <Heading5 style={{ marginLeft: "0.4rem", fontWeight: 500 }}>
                  {item.title}
                </Heading5>
                {item.extraCategories &&
                  item.extraCategories.length > 0 &&
                  item.extraCategories.map(({ title, items }) => {
                    if (hasFamilyPropertyItemSelected(items)) {
                      return (
                        <>
                          <Typography variant="subtitle2">{title}</Typography>
                          {items.map(({ title, selected }) => {
                            if (selected) {
                              return (
                                <Typography
                                  style={{ color: "#c3c3c3" }}
                                  variant="body2"
                                >
                                  {title}
                                </Typography>
                              )
                            }
                            return null
                          })}
                        </>
                      )
                    }
                    return null
                  })}
              </div>
            </div>
            <div>
              <Heading5 style={{ marginTop: -4 }}>
                {(item.quantity * item.price).toFixed(2) + "€"}
              </Heading5>
            </div>
          </div>
        ))}
        <div
          className={"flex flex-col w-full"}
          style={{ borderTop: "1px solid rgb(219 206 206)" }}
        >
          <div
            className={"flex flex-row w-full justify-between items-center p-2"}
          >
            <Heading5 className={"text-black"}>IVA(10%)</Heading5>
            <Heading5 className={"text-black"}>
              {(total * 0.1).toFixed(2) + "€"}
            </Heading5>
          </div>
          {deliveryPrice > 0 && (
            <div
              className={
                "flex flex-row w-full justify-between items-center p-2"
              }
            >
              <Heading5 className={"text-black"}>Precio envio</Heading5>
              <Heading5 className={"text-black"}>
                {deliveryPrice.toFixed(2) + "€"}
              </Heading5>
            </div>
          )}
          <div
            className={"flex flex-row w-full justify-between items-center p-2"}
          >
            <Heading5 className={"text-black"}>Total</Heading5>
            <Heading5 className={"text-black"}>
              {deliveryPrice > 0 && selectedOption && selectedOption == "2"
                ? (total + deliveryPrice).toFixed(2) + "€"
                : total.toFixed(2) + "€"}
            </Heading5>
          </div>
          {cart.discountApplicable && (
            <>
              <div
                className={
                  "flex flex-row w-full justify-between items-center p-2"
                }
              >
                <Label14Bold className="text-red">
                  Discount {cart.discountAmount}
                  {cart.discountType === "percentage" ? "%" : "€"}
                </Label14Bold>
                <Label14Bold className="pull-left text-red">
                  -
                  {cart.discountType === "percentage"
                    ? (total * Number(cart.discountAmount)) / 100
                    : Number(cart.discountAmount)}{" "}
                  €
                </Label14Bold>
              </div>
              <div
                className={
                  "flex flex-row w-full justify-between items-center p-2"
                }
              >
                <Heading5 className={"text-black"}>Grand Total</Heading5>
                <Heading5 className={"text-black"}>
                  {deliveryPrice > 0 && selectedOption && selectedOption == "2"
                    ? (discountedPrice + deliveryPrice).toFixed(2) + "€"
                    : discountedPrice.toFixed(2) + "€"}
                </Heading5>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ItemsDetails
