import React from "react"
import { Heading5 } from "@components/Text/Text"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

export interface Props {
  title?: string
  typography?: string
  border?: boolean
  onClick?: any
}

const EditProfileButton = ({ title, typography, border, onClick }: Props) => {
  return (
    <div
      className={
        "flex w-full justify-between items-center pt-6 pb-6 cursor-pointer"
      }
      style={{ borderBottom: border ? "0.5px solid #c1b9b9" : "none" }}
      onClick={onClick}
    >
      <Heading5>{title}</Heading5>
      <ChevronRightIcon style={{ fontSize: 32, color: typography }} />
    </div>
  )
}

export default EditProfileButton
