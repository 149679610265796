import React from "react"
import { Modal, Card } from "@material-ui/core"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

import { Heading1, Label19Bold } from "@components/Text/Text"
import { useSelector } from "react-redux"
import { Menu } from "@models/Menu"
import { formatImageUrl } from "@helpers/utilsHelper"
import classes from "../tables/tableright/tableRight.module.css"

interface Props {
  title: string
  menus: Menu[]
  open: boolean
  close?: boolean
  onClose: () => void
  onButtonClick: (value?: string) => void
}

const MenuModal = ({
  menus,
  title,
  open,
  close = true,
  onButtonClick,
  onClose,
}: Props) => {
  const { selectedOption } = useSelector(({ venues }: RootState) => venues)

  return (
    <div>
      <Modal
        open={open}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Card
          className="flex flex-col shadow-none z-10 self-center items-center justify-center outline-none md:w-2/5 w-11/12 p-8 md:px-18"
          style={{ borderRadius: 35, maxHeight: "80%" }}
        >
          <div className="flex flex-row items-center p-8 w-full">
            {close && (
              <ArrowBackIosIcon
                style={{
                  color: "black",
                  fontSize: 32,
                  left: "1",
                  alignSelf: "left",
                }}
                onClick={onClose}
              />
            )}
            <Heading1 className="w-full text-center justify-self-center">
              {title}
            </Heading1>
          </div>
          <div className={classes.menuModelScroll}>
            {menus.map((menu) => (
              <Card
                className="mb-12 pt-24 pb-36 w-full items-center justify-center cursor-pointer text-center "
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),url("${formatImageUrl(
                    menu.image,
                  )}")`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                onClick={() => onButtonClick(menu._id)}
              >
                <Heading1
                  style={{ textAlign: "center" }}
                  className="text-center text-white m-0 "
                >
                  {menu.title}
                </Heading1>
              </Card>
            ))}
          </div>
        </Card>
      </Modal>
    </div>
  )
}
export default MenuModal
