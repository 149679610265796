import React from "react"
import { Link } from "react-router-dom"

class NotFoundPage extends React.Component {
  render() {
    return (
      <div className="h-screen flex flex-col justify-center items-center">
        <h1 style={{ fontSize: "8rem", margin: "1rem", color: "#ff994c" }}>
          Oops!
        </h1>
        <p style={{ textAlign: "center" }}>Restaurante no encontrado</p>
      </div>
    )
  }
}
export default NotFoundPage
