import React, { FC } from "react"

import Classes from "./buttons.module.css"

interface Props {
  title?: string
  quantity?: number
  price?: string
  classes?: string
  onClick?: (e?: any) => void
  disabled?: boolean
  value?: string
  backgroundColor?: string
}

const PriceButton: FC<Props> = ({
  title,
  quantity,
  price,
  onClick,
  classes,
  disabled,
  value,
  backgroundColor,
}) => {
  return (
    <button
      style={{ backgroundColor }}
      onClick={onClick}
      className={[Classes.button, classes, "cursor-pointer outline-none"].join(
        " ",
      )}
      value={value}
      disabled={disabled}
    >
      <div className="flex flex-row px-12">
        <span className="self-start ml-auto">{quantity}</span>
        <span className="self-center ml-auto">{title}</span>
        <span className="self-end ml-auto mr-auto">{price} €</span>
      </div>
    </button>
  )
}

export default PriceButton
