import React, { useState } from "react"
import { Modal, Card } from "@material-ui/core"

import { CartType } from "@models/Cart"
import { Heading1 } from "@components/Text/Text"
import deliveryImage from "@assets/Images/delivery11.png"
import collectionImage from "@assets/Images/collection.png"
import deliveryImageHover from "@assets/Images/delivery_black.png"
import collectionImageHover from "@assets/Images/collection_black.png"

interface Props {
  title?: string
  open: boolean
  onClose: () => void
  onButtonClick: (value?: string) => void
}

const VenueModal = ({ title, open, onClose, onButtonClick }: Props) => {
  const [deliveryHover, setDeliveryHover] = useState(false)
  const [collectionHover, setCollectionHover] = useState(false)

  const handleDeliveryMouseEnter = () => {
    setDeliveryHover(true)
  }
  const handleDeliveryMouseLeave = () => {
    setDeliveryHover(false)
  }
  const handleCollectionMouseEnter = () => {
    setCollectionHover(true)
  }
  const handleCollectionMouseLeave = () => {
    setCollectionHover(false)
  }
  return (
    <div>
      <Modal
        open={open}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          className="flex flex-col shadow-none z-10 items-center justify-center outline-none md:w-2/5 w-11/12 p-8"
          style={{ borderRadius: 35 }}
        >
          <Heading1 className="mt-12 mb-12 text-center">{title}</Heading1>
          <div className="flex flex-row justify-center items-center p-6">
            <div>
              <img
                src={deliveryHover ? deliveryImageHover : deliveryImage}
                className="p-2"
                style={{
                  objectFit: "cover",
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
                onMouseEnter={handleDeliveryMouseEnter}
                onMouseLeave={handleDeliveryMouseLeave}
                onClick={() => onButtonClick(CartType.DELIVERY)}
              />
            </div>
            <div>
              <img
                src={collectionHover ? collectionImageHover : collectionImage}
                className="p-2"
                style={{
                  objectFit: "cover",
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
                onMouseEnter={handleCollectionMouseEnter}
                onMouseLeave={handleCollectionMouseLeave}
                onClick={() => onButtonClick(CartType.COLLECT)}
              />
            </div>
          </div>
        </Card>
      </Modal>
    </div>
  )
}
export default VenueModal
