import { OrderFromTableActionTypes } from "@store/redux/actionTypes";

const INITIAL_STATE: OrderFromTableState = {
  orderFromTable: {},
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const OrderFromTableReducer = (
  state: OrderFromTableState = INITIAL_STATE,
  action: Action
): OrderFromTableState => {
  switch (action.type) {
    case OrderFromTableActionTypes.GET_TABLE_START: {
      return { ...state, 
        loading: true };
    }
    case OrderFromTableActionTypes.GET_TABLE_SUCCESS: {
      return {
        ...state,
        orderFromTable:action.payload,
        loading: false,
      };
    }
    case OrderFromTableActionTypes.GET_TABLE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default OrderFromTableReducer;
