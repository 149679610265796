import React, { useState, useEffect } from "react"

import MobileBasketPage from "@modules/basket/BasketPage"
import DesktopBasketPage from "@modules/address/AddressPage"

const BasketPage = (props) => {
  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
  }, [])
  const isMobile = width <= 600
  return (
    <>
      {isMobile ? (
        <MobileBasketPage {...props} />
      ) : (
        <DesktopBasketPage {...props} />
      )}
    </>
  )
}

export default BasketPage
