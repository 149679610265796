import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import classes from "./venuedetails.module.css"
import TableLeft from "@components/tables/tableleft/TableLeft"
import TableRight from "@components/tables/tableright/TableRight"
import TableCenter from "@components/tables/tablecenter/TableCenter"
import Footer from "@components/footer/Footer"
import VenueModal from "@components/modals/VenueModal"
import CustomLoader from "@components/Loaders/CustomLoader"
import {
  getMenuList,
  getAllergensList,
  getMenuDetail,
} from "@store/menu/MenuActions"
import {
  setSelectedOption,
  getClientDetail,
  getVenueDetail,
  checkCollectionAvailability,
  checkDeliveryAvailability,
} from "@store/venues/VenueActions"
import { Menu } from "@models/Menu"
import LoginModal from "@modules/login/LoginPage"
import SignUpModal from "@modules/signup/SignUpPage"
import { getUser } from "@store/user/UserActions"
import MainHeader from "@components/header/MainHeader"
import { CartType } from "@models/Cart"
import { toast } from "react-toastify"
import MenuModal from "@components/modals/MenuModal"

const scrollToRef = (ref) =>
  window.scrollTo({ top: ref.offsetTop, behavior: "smooth" })

const VenueDetails = (props) => {
  const { permalink, id, menuId } = props.match.params
  const dispatch = useDispatch()
  const history = useHistory()

  const selectedOption = useSelector(({ venues }: any) => venues.selectedOption)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const menuList: Menu = useSelector(({ menu }: RootState) => menu.menuList)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const menuLoading = useSelector(({ menu }: RootState) => menu.loading)
  const venueLoading = useSelector(({ venues }: RootState) => venues.loading)
  const venueList = useSelector(({ venues }: RootState) => venues.venueList)
  const menus = useSelector(({ menu }: any) => menu.menus)
  const cart = useSelector(({ cart }: RootState) => cart.cart)
  const { user } = useSelector(({ user }: RootState) => user)
  const { error, signUpError } = useSelector(({ auth }: RootState) => auth)

  const cartItems = cart.items
  const { _id: userId } = user
  const loading = menuLoading || venueLoading

  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const [menuIdState, setMenuIdState] = useState(menuId)
  const [width, setWidth] = useState(window.innerWidth)
  const [showModal, setShowModal] = useState(false)
  const [signUpModal, setSignUpModal] = useState(false)
  const [isBasket, setIsBasket] = useState(false)
  const [open, setOpen] = useState(false)
  const [openMenu, setOpenMenu] = React.useState(false)
  const [menusFiltered, setMenusFiltered] = React.useState([])
  const isMobile = width <= 600
  const [tabValue, setTabValue] = useState(isMobile ? 0 : -1)
  const { settings, permission } = restaurant
  const {
    delivery = null,
    collection = null,
    orderFromTable = null,
  } = settings || {}

  const checkForDetails = () => {
    let isOnlyOneVenue = false
    if (open) {
      return false
    } else if (!delivery || !collection || !orderFromTable) {
      return false
    } else if (
      (delivery?.payByCard === true || delivery?.payByCash === true) &&
      !permission?.hasCollection
    ) {
      isOnlyOneVenue = true
    } else if (
      (collection?.payByCard === true || collection?.payByCash === true) &&
      !permission?.hasDelivery
    ) {
      isOnlyOneVenue = true
    } else if (
      (orderFromTable?.payByCard === true ||
        orderFromTable?.payByCash === true) &&
      !permission?.hasDelivery &&
      !permission?.hasCollection
    ) {
      isOnlyOneVenue = true
    }

    const menuFiltered = menus.filter((menu) => {
      if (selectedOption == CartType.COLLECT) {
        return menu.venues.includes(id) && menu.availableOnCollection === true
      } else if (selectedOption == CartType.DELIVERY) {
        return menu.venues.includes(id) && menu.availableOnDelivery === true
      }
    })
    if (menuFiltered && menuFiltered.length === 1) {
      // history.push(`/${permalink}/menu/${val._id}`)
      setMenuIdState(menuFiltered[0]._id)
    }
    setMenusFiltered(menuFiltered || [])
    return menuFiltered && menuFiltered.length > 1 ? isOnlyOneVenue : false
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    dispatch(getClientDetail(permalink))
    dispatch(getAllergensList())

    let userId = localStorage.getItem("userId")
    let clientId = localStorage.getItem("clientId")
    if (userId) {
      dispatch(getUser(clientId))
    }
    dispatch(getVenueDetail(id))
  }, [])

  useEffect(() => {
    let type = ""
    if (selectedOption === CartType.COLLECT) {
      type = "collection"
    }

    if (selectedOption === CartType.DELIVERY) {
      type = "delivery"
    }

    if (selectedOption === CartType.ORDER_FROM_TABLE) {
      type = "orderFromTable"
    }

    if (selectedOption !== "" && menuIdState) {
      dispatch(getMenuDetail(menuIdState, type))
    } else if (menuIdState === undefined) {
      setOpen(true)
      // history.push(`/${permalink}`)
    }
  }, [menuIdState])

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])
  useEffect(() => {
    if (venueList && venueList.length === 1 && checkForDetails()) {
      setOpenMenu(true)
    }
  }, [venueList, settings, open, menus])

  useEffect(() => {
    if (
      !venueDetail.collectionAvailable &&
      !venueDetail.deliveryAvailable &&
      !menuIdState
    ) {
      dispatch(getMenuList(id, ""))
    }
  }, [venueDetail])

  useEffect(() => {
    if (userId) {
      setShowModal(false)
      setSignUpModal(false)
    }
  }, [userId])

  useEffect(() => {
    const { collectionAvailable, deliveryAvailable } = venueDetail
    const check = collectionAvailable && deliveryAvailable
    if (selectedOption === "" && check) {
      setOpen(true)
    } else if (!check) {
      setOpen(false)
    }
  }, [selectedOption, venueDetail])

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  const handleButtonClick = (value) => {
    dispatch(setSelectedOption(value))
    setOpen(false)
    const menuFiltered = menus.filter((menu) => {
      if (value == CartType.COLLECT) {
        return menu.venues.includes(id) && menu.availableOnCollection === true
      } else if (value == CartType.DELIVERY) {
        return menu.venues.includes(id) && menu.availableOnDelivery === true
      }
    })

    if (menuFiltered && menuFiltered.length === 1) {
      // history.push(`/${permalink}/menu/${val._id}`)
      setMenuIdState(menuFiltered[0]._id)
    } else if (menuFiltered && menuFiltered.length > 1) {
      setOpenMenu(true)
      setMenusFiltered(menuFiltered)
    } else {
      toast.error("No existe menus para este restaurante")
    }
  }

  const executeScroll = (index) => {
    setTabValue(index)
  }

  const toggleModal = () => {
    setOpen(!open)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSignUpCloseModal = () => {
    setSignUpModal(false)
  }

  const handleLinkClick = () => {
    if (!userId) {
      setShowModal(!showModal)
      setSignUpModal(false)
    }
  }
  const handleClick = () => {
    if (!userId) {
      setIsBasket(true)
      setShowModal(!showModal)
    } else {
      if (selectedOption == "1") {
        dispatch(checkCollectionAvailability(id, true, history))
      } else if (selectedOption == "2") {
        dispatch(checkDeliveryAvailability(id, true, history))
      } else {
        history.push(`/menu/basket/${id}`)
      }
    }
  }

  const handleSignUpClick = (value) => {
    setShowModal(false)
    if (value === "isAddress") {
      setIsBasket(false)
    }
    setTimeout(() => {
      setSignUpModal(true)
    }, 100)
  }
  const handleLogin = () => {
    setIsBasket(false)
    setShowModal(!showModal)
  }

  return (
    <CustomLoader loading={loading}>
      <div className={classes.container}>
        <MainHeader
          isMobile={isMobile}
          restaurant={restaurant}
          venueDetail={venueDetail}
          userId={userId}
          typography={typography}
          venueId={id}
          showLogin
          onLoginClick={handleLogin}
          showLanguage={true}
        />
        {menuList.categories && menuList.categories.length > 0 && (
          <div
            style={{ backgroundColor: isMobile ? typography : "" }}
            className={[classes.main, "px-48"].join(" ")}
          >
            <TableLeft
              isMobile={isMobile}
              items={menuList.categories}
              tabValue={tabValue}
              setTabValue={setTabValue}
              executeScroll={executeScroll}
              typography={typography}
            />
            <div className={classes.center}>
              <TableCenter
                isMobile={isMobile}
                scrollToRef={scrollToRef}
                tabValue={tabValue}
                category={menuList.categories}
                onClick={handleClick}
              />
            </div>
            {(venueDetail.collectionAvailable ||
              venueDetail.deliveryAvailable ||
              venueDetail.orderFromTableAvailable) && (
              <TableRight
                showDeliveryPrice={false}
                deliveryPrice={0}
                minOrderPrice={0}
                items={cartItems}
                onClick={handleClick}
              />
            )}
          </div>
        )}
        <div className={classes.footer}>
          <Footer
            facebook={
              venueDetail.contactInfo && venueDetail.contactInfo.facebook
            }
            instagram={
              venueDetail.contactInfo && venueDetail.contactInfo.instagram
            }
            twitter={venueDetail.contactInfo && venueDetail.contactInfo.twitter}
          />
        </div>
        <LoginModal
          typography={typography}
          showModal={showModal}
          onCloseModal={handleCloseModal}
          onSignUpClick={() => handleSignUpClick("isBasket")}
          error={error}
          isBasket={isBasket}
          venueId={id}
          checkCollection={selectedOption == "1" && isBasket}
          checkDelivery={selectedOption == "2" && isBasket}
          orderFromTable={selectedOption == "3" && isBasket}
        />
        <SignUpModal
          typography={typography}
          showModal={signUpModal}
          onCloseModal={handleSignUpCloseModal}
          error={signUpError}
          onLinkClick={handleLinkClick}
          venueId={id}
          isBasket={isBasket}
          checkCollection={selectedOption == "1" && isBasket}
          checkDelivery={selectedOption == "2" && isBasket}
          orderFromTable={selectedOption == "3" && isBasket}
        />
        <VenueModal
          title={"¿Cómo quieres tu pedido?"}
          open={open}
          onClose={toggleModal}
          onButtonClick={handleButtonClick}
        />
        <MenuModal
          title={"Selecciona Menú"}
          open={openMenu}
          close={false}
          menus={menusFiltered}
          onClose={() => setOpenMenu(!openMenu)}
          onButtonClick={(menu) => {
            setOpenMenu(!openMenu)
            setMenuIdState(menu)
            // history.push(`/${permalink}/menu/${id}/${menu}`)
          }}
        />
      </div>
    </CustomLoader>
  )
}

export default VenueDetails
