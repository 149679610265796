import React, { FC, useState, useEffect } from "react"
import { AddCircle, ListAlt } from "@material-ui/icons"
import { useSelector } from "react-redux"

import { Label19Bold, Body14, Label14Light } from "@components/Text/Text"
import MenuItemsCartDetails from "./components/MenuItemsCartDetails"
import ExtraCategoriesModalLeft from "./components/ExtraCategoriesModalLeft"
import ComplexItemModal from "../components/ComplexItemModal"
import SimpleItemModal from "../components/SimpleItemModal"
import classes from "../menuItem.module.css"
import { getAllergens, formatImageUrl } from "@helpers/utilsHelper"

interface Props {
  item: MenuItem
  onClick: () => void
  quantity?: number
  onItemDecrease: () => void
}

const MenuItemMobile: FC<Props> = ({
  item,
  onClick,
  quantity,
  onItemDecrease,
}) => {
  const { title, description, price, image, extraCategories, allergenes } = item
  const [modal, setModal] = useState(false)
  const [showDetail, setShowDetail] = useState(false)

  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const allergensList = useSelector(({ menu }: RootState) => menu.allergensList)
  const cart = useSelector(({ cart }: any) => cart.cart)
  const { items } = cart
  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const [allergensImages, setAllergensImages] = useState<any>([])

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const cartItems = useSelector(({ cart }: RootState) => cart.cart.items)

  const handleClick = () => {
    onClick()
    extraCategories && extraCategories.length > 0 && setModal(true)
  }

  const showItemDetail = () => {
    if (extraCategories && extraCategories.length > 0) {
      setModal(true)
      onClick()
    } else {
      setShowDetail(true)
    }
  }

  const getFilterAllergens = () => {
    const allergens = getAllergens(allergenes)
    if (
      allergens &&
      allergens.length > 0 &&
      allergensList &&
      allergensList.length > 0
    ) {
      return allergensList.map((item) => {
        if (allergens.find((i) => i == item._id)) {
          const temp = allergensImages
          temp.push(item)
          setAllergensImages(temp)
        }
      })
    }
  }
  useEffect(() => {
    getFilterAllergens()
  }, [allergensList])
  return (
    <>
      <div className={classes.menu_item}>
        {modal && (
          <ComplexItemModal
            typography={typography}
            item={item}
            onClose={() => setModal(false)}
            isMobile={true}
          />
        )}

        {showDetail && (
          <SimpleItemModal
            typography={typography}
            item={item}
            onClose={() => setShowDetail(false)}
            isMobile={true}
          />
        )}
        <div className="flex">
          {quantity &&
            restaurant.accessLevel &&
            restaurant.accessLevel !== "1" && (
              <ExtraCategoriesModalLeft
                typography={typography}
                quantity={quantity}
                handleClick={onItemDecrease}
              />
            )}

          <div className="flex flex-col w-full justify-between pr-3">
            <div
              className={classes.info}
              style={{
                left: quantity ? "34px" : "3px",
              }}
              onClick={showItemDetail}
            >
              {image && image.length > 0 && (
                <div className={classes.image}>
                  <div
                    style={{
                      background: `url('${formatImageUrl(
                        image,
                      )}') center center / cover no-repeat`,
                      height: 60,
                      width: 60,
                    }}
                  />
                </div>
              )}
              <div className={classes.details}>
                <Label19Bold>{title}</Label19Bold>
                <Body14 className={classes.subtitle}>{description}</Body14>
              </div>
            </div>
            <div className={"flex flex-wrap pl-1 -mt-3"}>
              {allergensImages &&
                allergensImages.length > 0 &&
                allergensImages.map((item) => (
                  <img
                    src={require(`@assets/allergenes/${item.title}.png`)}
                    className={"w-16 h-16 object-cover m-1"}
                  />
                ))}
            </div>
          </div>
          <div className="flex flex-col justify-between items-center pb-1 pr-1">
            <Label14Light className="whitespace-no-wrap mt-3">
              {price} €
            </Label14Light>
            {restaurant.accessLevel && restaurant.accessLevel !== "1" && (
              <AddCircle
                style={{ color: typography }}
                className={classes.icon}
                onClick={handleClick}
              />
            )}
          </div>
        </div>
      </div>
      {cartItems
        .filter(
          (cartItem) =>
            cartItem._id.substring(0, cartItem._id.indexOf("-")) === item._id,
        )
        .map((item) => (
          <MenuItemsCartDetails typography={typography} item={item} />
        ))}
    </>
  )
}

export default MenuItemMobile
