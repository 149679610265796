import React, { useState, useEffect } from "react"
import { Card } from "@material-ui/core"
import CreditCard from "@material-ui/icons/CreditCard"
import { Label14, Heading3, Label17 } from "@components/Text/Text"
import AddIcon from "@material-ui/icons/Add"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { Card as CardModal } from "@models/Card"
import { getCardImage } from "@store/cart/cartHelpers"
export interface StyleProps {
  typography?: string
}

export interface Props {
  typography?: string
  cards?: CardModal[]
  selectedCard?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: () => ({
    boxShadow:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    borderRadius: "8px",
  }),
}))

const PaymentCard = ({ typography, cards, selectedCard }: Props) => {
  const styleProps: StyleProps = { typography }
  const classes = useStyles(styleProps)

  const history = useHistory()

  const onSelectionClick = () => {
    if (cards && cards?.length < 1) {
      history.push("/add-card")
    } else {
      history.push("/cards-list")
    }
  }

  const [card, setCard] = useState(
    cards?.find((card) => card.id === selectedCard),
  )

  useEffect(() => {
    setCard(cards?.find((card) => card.id === selectedCard))
  }, [selectedCard])

  return (
    <Card
      elevation={0}
      className={[
        "flex flex-col self-center w-11/12 mt-16 bg-white shadow-md rounded-4",
        classes.root,
      ].join()}
      style={{ minHeight: "5rem" }}
    >
      <div
        className={
          "flex flex-row  items-center justify-between w-full mt-6 pr-2"
        }
      >
        <div className={"flex flex-row justify-between"}>
          <CreditCard style={{ color: typography }} className={"ml-4 mr-6"} />
          <Heading3 className="m-0">Método de pago</Heading3>
        </div>
        {cards && cards?.length > 0 && (
          <Label14
            className={"font-700"}
            style={{
              marginBottom: 2,
              color: typography,
            }}
            onClick={onSelectionClick}
          >
            Elegir Tarjeta
          </Label14>
        )}
      </div>
      {cards && cards?.length < 1 && (
        <div className={"flex flex-row items-center mt-8 mb-4"}>
          <Label14 className={"font-700"} style={{ color: typography }}>
            <div
              className="flex items-center"
              onClick={(e) => history.push("/add-card")}
            >
              <AddIcon className={"ml-4 mr-6"} />
              <div>Añadir nueva tarjeta</div>
            </div>
          </Label14>
        </div>
      )}
      {selectedCard && selectedCard !== "" && card && (
        <div className={"flex flex-row w-full pl-24 pb-12 pt-12"}>
          <img width="40" src={getCardImage(card.brand)}></img>
          <Label17 className="m-0 ml-6 font-700">{`**** **** **** ${card.last4}`}</Label17>
        </div>
      )}
    </Card>
  )
}

export default PaymentCard
