import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import classes from "@modules/venueDetails/venuedetails.module.css"
import Footer from "@components/footer/Footer"
import CustomLoader from "@components/Loaders/CustomLoader"
import MainHeader from "@components/header/MainHeader"
import ProfileLeftTable from "./components/ProfileLeftTable"
import { logout } from "@store/auth/AuthActions"
import UpdateProfileForm from "../MobileProfile/UpdateProfile/components/UpdateProfileForm"
import ChangePasswordForm from "../MobileProfile/ChangePassword/components/ChangePasswordForm"
import { getOrdersList, getOrderDetail } from "@store/user/UserActions"
import MyOrders from "./MyOrders/MyOrders"
import MyOrderDetails from "./MyOrders/MyOrderDetails"

const DesktopProfile = (props) => {
  const { id, venueId, orderId } = props.match.params
  const dispatch = useDispatch()

  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)
  const { loading, user, orders, orderDetail } = useSelector(
    ({ user }: any) => user,
  )

  const [typography, setTypography] = useState("rgb(202, 156, 77)")

  const [order, setOrder] = useState(orderDetail)
  const [centerCard, setCenterCard] = useState("1")
  const [selectedOption, setSelectedOption] = useState("Editar Perfil")
  const [showOrderDetails, setShowOrderDetails] = useState(false)

  useEffect(() => {
    dispatch(getOrdersList())
  }, [])
  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetail(orderId))
      setShowOrderDetails(true)
      setCenterCard("")
      setSelectedOption("Mis Ordenes")
    }
  }, [])
  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])
  useEffect(() => {
    if (orderDetail) {
      setOrder(orderDetail)
    }
  }, [orderDetail])
  const handleLogoutClick = () => {
    setSelectedOption("Salir")
    let userId = localStorage.getItem("userId")
    if (userId) dispatch(logout(true))
  }
  const handleEditProfileClick = () => {
    setCenterCard("1")
    setSelectedOption("Editar Perfil")
  }
  const handleChangePasswordClick = () => {
    setCenterCard("2")
    setSelectedOption("Cambiar Contraseña")
  }
  const handleMyOrderClick = () => {
    setShowOrderDetails(false)
    setCenterCard("")
    setSelectedOption("Mis Ordenes")
  }
  const handleOrderItemClick = (id) => {
    dispatch(getOrderDetail(id))
    setShowOrderDetails(true)
    setCenterCard("")
  }
  return (
    <CustomLoader loading={loading}>
      <div className={classes.container}>
        <MainHeader
          restaurant={restaurant}
          venueDetail={venueDetail}
          userId={user._id}
          typography={typography}
          venueId={venueId}
          showLogin
        />
        <div className={"flex pb-40"}>
          <ProfileLeftTable
            typography={typography}
            onLogoutClick={handleLogoutClick}
            onEditProfileClick={handleEditProfileClick}
            onChangePasswordClick={handleChangePasswordClick}
            onMyOrderClick={handleMyOrderClick}
            selectedOption={selectedOption}
          />
          <div className={"flex w-3/5 pt-5"}>
            {centerCard == "1" ? (
              <UpdateProfileForm typography={typography} />
            ) : centerCard == "2" ? (
              <ChangePasswordForm typography={typography} />
            ) : !showOrderDetails ? (
              <MyOrders
                orders={orders}
                typography={typography}
                onOrderItemClick={handleOrderItemClick}
              />
            ) : (
              <MyOrderDetails order={order} typography={typography} />
            )}
          </div>
        </div>

        <div className={classes.footer}>
          <Footer
            facebook={
              venueDetail.contactInfo && venueDetail.contactInfo.facebook
            }
            instagram={
              venueDetail.contactInfo && venueDetail.contactInfo.instagram
            }
            twitter={venueDetail.contactInfo && venueDetail.contactInfo.twitter}
          />
        </div>
      </div>
    </CustomLoader>
  )
}

export default DesktopProfile
