import React from "react"

import OrderListItem from "../../MobileProfile/MyOrders/components/OrderListItem"
export interface Props {
  orders?: any
  typography?: string
  onOrderItemClick?: any
}

const MyOrders = ({ orders, typography, onOrderItemClick }: Props) => {
  return (
    <div className={"flex flex-col w-full self-center cursor-pointer"}>
      {orders &&
        orders.map((order) => (
          <OrderListItem
            item={order}
            typography={typography}
            onClick={() => 
              onOrderItemClick(order._id)
            }
          />
        ))}
    </div>
  )
}

export default MyOrders
