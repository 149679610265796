import React, { FC } from "react"
import { StickyContainer, Sticky } from "react-sticky"
import { Theme } from "@material-ui/core"

import { Category } from "@models/Menu"
import classes from "./tableLeft.module.css"
import { ArrowForwardIos } from "@material-ui/icons"

import { makeStyles } from "@material-ui/core/styles"
import { Heading2, Label14Bold, Label13 } from "@components/Text/Text"
import Button from "@components/buttons/Button"
import { useTranslatedTextV2 } from "@helpers/utilsHelper"
interface Props {
  items?: Category[]
  tabValue?: any
  setTabValue?: any
  executeScroll: (index: number) => void
  isMobile: boolean
  typography?: string
}

interface StyleProps {
  backgroundColor?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    zIndex: 1,
    backgroundColor: props.backgroundColor,
    "& .Mui-selected": {
      color: "#fff",
      fontSize: "14px",
      fontWeight: 600,
      borderTop: 0,
      margin: "0 0.6rem",
    },
    "& .PrivateTabIndicator-colorSecondary-5 ": {
      backgroundColor: "#fff",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#fff",
    },
  }),
  tab: {
    color: "#edededbd",
    fontSize: "12px",
    fontWeight: 500,
    padding: "0.2rem 0.3rem",
  },
  rootTabs: {
    "& .MuiTab-wrapper": {
      fontFamily: "GilroyMedium",
    },
  },
}))

const TableLeft: FC<Props> = ({
  items,
  tabValue,
  setTabValue,
  executeScroll,
  isMobile,
  typography,
}) => {
  const styleProps: StyleProps = { backgroundColor: typography }
  const Classes = useStyles(styleProps)

  const categoryText = useTranslatedTextV2({
    index: 1,
    defaultTxt: "Categorías",
  })
  const mobileText = useTranslatedTextV2({
    index: 2,
    defaultTxt: "Este menu es una demo, obtén el tuyo gratis",
  })

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue)
  }
  const clientPermalink = localStorage.getItem("clientPermalink")
  const renderSection = () => {
    if (isMobile) {
      return (
        <div className={classes.container}>
          {clientPermalink == "trattoria" && (
            <div
              className={"flex justify-around items-center w-full py-2"}
              style={{ backgroundColor: typography, bottom: 0 }}
            >
              <Label13 className={"text-white text-center"}>
                {mobileText}
              </Label13>
              <Button
                color={typography}
                classes={
                  "border-none py-1 px-2 rounded cursor-pointer outline-none"
                }
                title="Regístrate Gratis"
                onClick={() => {
                  window.open("https://app.banzzu.com/register/es", "_blank")
                }}
                fontSize={"13px"}
                style={{ backgroundColor: "white", fontSize: "12px" }}
              />
            </div>
          )}
        </div>
      )
    } else {
      return (
        <StickyContainer className="w-4/12 lg:w-3/12 mx-12">
          <Sticky disableCompensation>
            {({ style, isSticky }) => {
              return (
                <div
                  style={style}
                  className={isSticky ? classes.stickyLeft : ""}
                >
                  <div className={classes.container}>
                    <div className={classes.categoriesTitle}>
                      <Heading2>{categoryText}</Heading2>
                    </div>
                    <div className={classes.tablelist}>
                      <ul className={classes.tableul}>
                        {items &&
                          items.map((val: Category, idx) => (
                            <li onClick={() => executeScroll(idx)}>
                              <span className="flex">
                                <Label14Bold>{val.title}</Label14Bold>
                                <Label14Bold className={classes.menuLength}>
                                  ({val.menuList && val.menuList.length})
                                </Label14Bold>
                              </span>
                              <span className={classes.arrow}>
                                <ArrowForwardIos
                                  style={{ fontSize: "14px", color: "gray" }}
                                />
                              </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )
            }}
          </Sticky>
        </StickyContainer>
      )
    }
  }

  return renderSection()
}

export default TableLeft
