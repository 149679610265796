import axios, { AxiosResponse, AxiosRequestConfig } from "axios"

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

const ResponseInterceptor = (response: AxiosResponse) => {
  return response
}
const RequestInterceptor = (config: AxiosRequestConfig) => {
  config.headers.Authorization = "Bearer " + localStorage.getItem("accessToken")
  return config
}
axiosInstance.interceptors.request.use(RequestInterceptor)
// Alter defaults after instance has been created
axiosInstance.interceptors.response.use(ResponseInterceptor, (error) => {
  const expectedErrors =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500
  if (!expectedErrors) {
    console.log("error", error)
    return
  } else {
    if (error.response.status === 401) {
      console.log("error.response :>> ", error.response.data.message)
      localStorage.removeItem("accessToken")
      localStorage.removeItem("userId")
      window.location.href = "/" + localStorage.getItem("clientPermalink")
    }
    return Promise.reject(error)
  }
})
