import React from "react"

import MenuItem from "@modules/menuitem/desktop/MenuItem"
import { Body14, Heading5, Label13 } from "@components/Text/Text"
import classes from "../tableCenter.module.css"

interface Props {
  category: any
  handleMenuItemClick: (item: any) => void
  tabValue: number
  scrollToRef: (ref: any) => void
}

const DesktopCenter = ({
  category,
  handleMenuItemClick,
  tabValue,
  scrollToRef,
}: Props) => {
  let content
  if (category) {
    content = category.map((val, idx) => (
      <>
        <div
          className={[classes.header_title, "mt-2"].join(" ")}
          ref={(ref) => {
            if (tabValue === idx) {
              if (ref) {
                scrollToRef(ref)
              }
            }
          }}
        >
          <Heading5>{val.title}</Heading5>
          {val.description && <Body14>{val.description}</Body14>}
        </div>
        <div className={classes.item_list}>
          <ul className={"m-0"}>
            {val.menuList.map((item) => (
              <li className={"mb-1"}>
                <MenuItem
                  item={item}
                  title={item.title}
                  subtitle={item.description}
                  price={item.price}
                  image={item.image}
                  extraCategories={item.extraCategories}
                  onClick={() => handleMenuItemClick(item)}
                />
              </li>
            ))}
          </ul>
        </div>
      </>
    ))
  } else {
    content = null
  }

  return content
}

export default DesktopCenter
