import React from "react"
import { StickyContainer, Sticky } from "react-sticky"

import classes from "@components/tables/tableleft/tableLeft.module.css"
import EditProfileButton from "./EditProfileButton"
import { Heading2 } from "@components/Text/Text"

export interface Props {
  typography?: string
  onLogoutClick?: any
  onEditProfileClick?: any
  onChangePasswordClick?: any
  onMyOrderClick?: any
  selectedOption?: string
}

const ProfileLeftTable = ({
  typography,
  onLogoutClick,
  onEditProfileClick,
  onChangePasswordClick,
  onMyOrderClick,
  selectedOption,
}: Props) => {
  return (
    <StickyContainer className="w-4/12 lg:w-3/12 mx-12 pt-5">
      <Sticky disableCompensation>
        {({ style, isSticky }) => {
          return (
            <div style={style} className={isSticky ? classes.stickyLeft : ""}>
              <div className={classes.container}>
                <div className={classes.categoriesTitle}>
                  <Heading2>Perfil</Heading2>
                </div>
                <div className={classes.tablelist}>
                  <ul className={classes.tableul}>
                    <EditProfileButton
                      title={"Editar Perfil"}
                      selected={
                        selectedOption == "Editar Perfil" ? true : false
                      }
                      typography={typography}
                      onClick={onEditProfileClick}
                    />
                    <EditProfileButton
                      title={"Cambiar Contraseña"}
                      typography={typography}
                      onClick={onChangePasswordClick}
                      selected={
                        selectedOption == "Cambiar Contraseña" ? true : false
                      }
                    />
                    <EditProfileButton
                      title={"Mis Ordenes"}
                      typography={typography}
                      onClick={onMyOrderClick}
                      selected={selectedOption == "Mis Ordenes" ? true : false}
                    />
                    <EditProfileButton
                      title={"Salir"}
                      typography={typography}
                      onClick={onLogoutClick}
                      selected={selectedOption == "Salir" ? true : false}
                    />
                  </ul>
                </div>
              </div>
            </div>
          )
        }}
      </Sticky>
    </StickyContainer>
  )
}

export default ProfileLeftTable
