import React from "react"

import { Heading5, Label14Bold } from "@components/Text/Text"

export interface Props {
  referenceNo: string
  price: number
}

const TopHeader = ({ referenceNo, price }: Props) => {
  return (
    <div className={"flex flex-row w-full bg-white p-8"}>
      <div className={"flex flex-col items-center w-1/2"}>
        <Label14Bold style={{ color: "#b3b0b0" }}>N Order</Label14Bold>
        <Heading5 style={{ marginTop: 5 }}>{referenceNo}</Heading5>
      </div>
      <div style={{ borderLeft: "1px solid #b3b0b0" }}></div>
      <div className={"flex flex-col items-center w-1/2"}>
        <Label14Bold style={{ color: "#b3b0b0" }}>Total</Label14Bold>
        <Heading5 style={{ marginTop: 5 }}>{price.toFixed(2) + "€"}</Heading5>
      </div>
    </div>
  )
}

export default TopHeader
