import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import MobileHeader from "@components/header/MobileHeader"
import ChangePasswordForm from "./components/ChangePasswordForm"
import CustomLoader from "@components/Loaders/CustomLoader"

const ChangePassword = () => {
  const history = useHistory()

  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const { loading } = useSelector(({ user }: any) => user)

  const [typography, setTypography] = useState("rgb(202, 156, 77)")

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  return (
    <CustomLoader loading={loading}>
      <MobileHeader
        title={"Cambiar Contraseña"}
        typography={typography}
        onBack={() => history.goBack()}
        style={{ marginBottom: 0 }}
      >
        <div className={"flex w-11/12 mt-48 self-center"}>
          <ChangePasswordForm typography={typography} />
        </div>
      </MobileHeader>
    </CustomLoader>
  )
}

export default ChangePassword
