export const GET_MENU = "app/order/menuByVenue"

export const ORDER_MENU_BY_ID = "app/order/menuById"

export const GET_ALL_MENUS = "/app/menu"

export const AUTH_CLIENT = "web/auth/client/"

export const VENUE = "web/venue"

export const SECRET = "web/secret"

export const REDSYS_FORM = "app/order/redsysForm"

export const REDSYS_FORM_WEB = "web/order/redsysForm"

export const CONFIRM_PENDING_ORDER = "web/order/confirmPendingOrder"

export const ORDER = "web/order"
export const APPLY_DISCOUNT_VOUCHER = "web/apply-promo-code"

export const getVenueListUrl = () => {
  return encodeURI(VENUE + "?clientId=" + localStorage.getItem("clientId"))
}

export const LOGIN = "web/auth/webUser/login"
export const LOGIN_SOCIAL = "web/auth/socialLogin"

export const REGISTER = "web/auth/webUser"

export const CHECK_TOKEN = "web/auth/validateToken"
export const RESET_PASSWORD = "web/auth/resetPassword"

export const address = "web/user/address"
export const getMenuListUrl = (id, type) => {
  return encodeURI(GET_MENU + "?venueId=" + id + "&type=" + type)
}

export const getOrderMenuByIdUrl = (menuId, type) => {
  return encodeURI(ORDER_MENU_BY_ID + "?menuId=" + menuId + "&type=" + type)
}

export const getAllMenuListUrl = (clientId) => {
  return encodeURI(GET_ALL_MENUS + "/?clientId=" + clientId)
}

export const getUserDetailUrl = (clientId) => {
  return encodeURI("web/auth/webUser/" + clientId)
}

export const getLogoutUserUrl = () => {
  return encodeURI("web/auth/webUser/logout")
}

export const saveCardDetailUrl = () => {
  return encodeURI("web/payment/card")
}

export const getCardListUrl = () => {
  return encodeURI(
    "web/payment/getCardsByUser?userId=" + localStorage.getItem("userId"),
  )
}

export const getTableUrl = (id) => {
  return encodeURI("/web/orderFromTable/" + id)
}

export const getForgotPasswordUrl = () => {
  return encodeURI("/web/auth/forgotPassword")
}

export const getCheckAvailabilityCollectionUrl = (clientId) => {
  return encodeURI(ORDER + "/checkValidCollection/" + clientId)
}

export const getCheckAvailabilityDeliveryUrl = (clientId) => {
  return encodeURI(ORDER + "/checkValidDelivery/" + clientId)
}
export const getUpdateUserUrl = () => {
  return encodeURI("/web/auth/updateUser")
}

export const getChangePasswordUrl = () => {
  return encodeURI("/web/auth/changePassword")
}
export const getOrderListUrl = (userId) => {
  return encodeURI("/web/order/userOrders/" + userId)
}
export const getOrderDetailUrl = (orderId) => {
  return encodeURI("/web/order/" + orderId)
}
export const getOrderCancelUrl = () => {
  return encodeURI("/web/order/cancelOrder")
}
export const getRedsysFormUrl = (clientId, price, referenceNo) => {
  return encodeURI(
    REDSYS_FORM +
      "?clientId=" +
      clientId +
      "&price=" +
      price +
      "&referenceNo=" +
      referenceNo,
  )
}
