import React, { FC, useState, useEffect } from "react"
import { Sticky, StickyContainer } from "react-sticky"
import {
  RemoveCircleOutline,
  AddCircleOutline,
  ShoppingCartOutlined,
} from "@material-ui/icons"
import { TextField, makeStyles, Theme } from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux"

import classes from "./tableRight.module.css"
import Button from "@components/buttons/Button"
import { ItemOrderMenu, CartType } from "@models/Cart"
import { cartCounter, getDiscountedPrice } from "@store/cart/cartHelpers"
import {
  increaseItemAmountOrderPage,
  decreaseItemAmountOrderPage,
  applyDiscountVoucher,
} from "@store/cart/cartActions"
import { hasFamilyPropertyItemSelected } from "@store/cart/cartHelpers"
import {
  Label10,
  Heading2,
  Label14Bold,
  Label13,
  Label17,
  Label14,
} from "@components/Text/Text"
import clsx from "clsx"
import { getClientDetail } from "@store/venues/VenueActions"
import { useTranslatedTextV2 } from "@helpers/utilsHelper"

interface Props {
  deliveryPrice: number
  minOrderPrice: number
  items?: ItemOrderMenu[]
  onClick?: () => void
  canBeSubmit?: boolean
  checkout?: boolean
  showDeliveryPrice?: boolean
}

interface StyleProps {
  typography?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    marginTop: "0.4rem",
    marginBottom: "0.4rem",
    "& .Mui-focused": {
      color: props.typography,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: props.typography,
    },
  }),
}))

const TableRight: FC<Props> = ({
  items,
  onClick,
  deliveryPrice,
  minOrderPrice,
  canBeSubmit,
  checkout,
  showDeliveryPrice = false,
}) => {
  const [subTotal, setSubTotal] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [discountVoucherCode, setDiscountVoucherCode] = useState("")
  const { restaurant, selectedOption } = useSelector(
    ({ venues }: RootState) => venues,
  )
  const orderText = useTranslatedTextV2({
    index: 3,
    defaultTxt: "Tu Orden",
  })
  const hacerText = useTranslatedTextV2({
    index: 4,
    defaultTxt: "Hacer Pedido",
  })
  const discountText = useTranslatedTextV2({
    index: 5,
    defaultTxt: "¿Tienes un código de descuento?",
  })
  const codigoText = useTranslatedTextV2({
    index: 6,
    defaultTxt: "Código descuento",
  })
  const aplicarText = useTranslatedTextV2({
    index: 7,
    defaultTxt: "aplicarText",
  })
  const inValidDiscountText = useTranslatedTextV2({
    index: 8,
    defaultTxt: `El codigo de descuento no existe`,
  })
  const usedDiscountText = useTranslatedTextV2({
    index: 9,
    defaultTxt: `Discount code already used`,
  })
  const minOrderText = useTranslatedTextV2({
    index: 10,
    defaultTxt: `Mínimo orden`,
  })
  const homeDeliveryText = useTranslatedTextV2({
    index: 11,
    defaultTxt: `Envío a Domicilio`,
  })
  const totalText = useTranslatedTextV2({
    index: 12,
    defaultTxt: `total`,
  })
  const subtotalText = useTranslatedTextV2({
    index: 13,
    defaultTxt: `Subtotal`,
  })
  const {
    discountApplicable,
    discountAmount,
    discountType,
    discountCode,
    addMoreItems,
    discountQuantity,
    invalidDiscountCode,
    alreadyUsed,
    isVoucherMatchWithOrderType = true,
  } = useSelector(({ cart }: RootState) => cart)

  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const styleProps: StyleProps = { typography }
  const Classes = useStyles(styleProps)

  useEffect(() => {
    const permalink = localStorage.getItem("clientPermalink")
    if (permalink) {
      dispatch(getClientDetail(permalink))
    }
    if (discountCode) {
      dispatch(applyDiscountVoucher(discountVoucherCode))
    }
  }, [])

  useEffect(() => {
    if (discountCode && !discountVoucherCode) {
      setDiscountVoucherCode(discountCode)
    }
  }, [discountCode])

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const dispatch = useDispatch()
  useEffect(() => {
    if (items) {
      let { totalPrice } = cartCounter(items)

      let discountedPrice = getDiscountedPrice(
        totalPrice,
        discountApplicable,
        discountType,
        discountAmount,
      )

      let grandTotal =
        selectedOption && selectedOption == "2" && deliveryPrice
          ? Number(discountedPrice.toFixed(2)) + Number(deliveryPrice)
          : Number(discountedPrice.toFixed(2))

      if (discountQuantity && discountQuantity > Number(totalPrice)) {
        dispatch(applyDiscountVoucher(discountVoucherCode))
      }

      setTotalPrice(grandTotal)
      setSubTotal(Number(totalPrice))
    }
  }, [items, deliveryPrice, discountApplicable])

  const decreaseQuantity = (item) => {
    dispatch(decreaseItemAmountOrderPage({ item }))
  }

  const increaseQuantity = (item) => {
    dispatch(increaseItemAmountOrderPage(item._id))
  }

  return (
    <StickyContainer className="w-4/12 lg:w-3/12 mx-12 hidden md:block">
      <Sticky disableCompensation>
        {({ style, isSticky }) => {
          return (
            <div style={style} className={isSticky ? classes.rightSticky : ""}>
              <div className={classes.container}>
                <div className={classes.cart_box}>
                  <div className={classes.cart_items}>
                    <Heading2>{orderText}</Heading2>
                    <ShoppingCartOutlined />
                  </div>

                  <table>
                    <tbody>
                      {items &&
                        items.map((item, idx) => (
                          <>
                            {idx !== 0 && <hr></hr>}
                            <tr
                              className={[
                                classes.tablerow,
                                "w-full table",
                              ].join(" ")}
                              key={idx}
                            >
                              <td>
                                <RemoveCircleOutline
                                  style={{ color: typography }}
                                  onClick={() => decreaseQuantity(item)}
                                  className="cursor-pointer text-xs align-middle"
                                />
                              </td>
                              <td>
                                <Label14Bold
                                  className={[
                                    classes.quantity,
                                    "overflow-hidden whitespace-no-wrap",
                                  ].join(" ")}
                                >
                                  {item.quantity + " x"}
                                </Label14Bold>
                              </td>
                              <td>
                                <div className="text-center">
                                  <Label17>{item.title}</Label17>
                                </div>
                                {item &&
                                  item.extraCategories &&
                                  item.extraCategories.length > 0 && (
                                    <ul className="p-4 m-0 ml-12">
                                      {item.extraCategories.map(
                                        ({ title, items }) => {
                                          if (
                                            hasFamilyPropertyItemSelected(items)
                                          ) {
                                            return (
                                              <>
                                                <Label13>{title}</Label13>
                                                {items.map(
                                                  ({ selected, title }) => {
                                                    if (selected) {
                                                      return (
                                                        <li
                                                          style={{
                                                            color: typography,
                                                          }}
                                                        >
                                                          <Label10>
                                                            {title}
                                                          </Label10>
                                                        </li>
                                                      )
                                                    }
                                                    return null
                                                  },
                                                )}
                                              </>
                                            )
                                          }
                                        },
                                      )}
                                    </ul>
                                  )}
                              </td>
                              <td>
                                <AddCircleOutline
                                  style={{ color: typography }}
                                  onClick={() => increaseQuantity(item)}
                                  className="cursor-pointer text-xs align-middle"
                                />
                              </td>
                              <td>
                                <Label14Bold className={classes.price}>
                                  {item.price.toFixed(2)}€
                                </Label14Bold>
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                  {/* <hr />
                  <div className={classes.options}>
                    <div className={classes.options_item}>
                      <label>
                        <input
                          type="radio"
                          value=""
                          checked
                          name="option_2"
                          className="icheck"
                        />
                        Delivery
                      </label>
                    </div>
                    <div className={classes.options_item}>
                      <label>
                        <input
                          type="radio"
                          value=""
                          name="option_2"
                          className="icheck"
                        />
                        Take Away
                      </label>
                    </div>
                  </div> */}

                  {checkout && restaurant.permission?.hasDiscountVoucher && (
                    <>
                      <hr />
                      <Label14>{discountText}</Label14>

                      <div className="flex flex-row">
                        <TextField
                          label={<Label17>{codigoText}</Label17>}
                          name="discountVoucherCode"
                          value={discountVoucherCode}
                          className={clsx(Classes.root, "w-full")}
                          onChange={(e) =>
                            setDiscountVoucherCode(e.target.value)
                          }
                        />

                        <Button
                          title={aplicarText}
                          backgroundColor={typography}
                          classes={
                            "flex items-center justify-center self-center w-1/3 text-white py-2 px-4 border border-transparent cursor-pointer outline-none mt-6"
                          }
                          style={{
                            borderRadius: "25px",
                            fontSize: "8px",
                            bottom: 30,
                          }}
                          onClick={() =>
                            dispatch(
                              applyDiscountVoucher(
                                discountVoucherCode,
                                selectedOption,
                              ),
                            )
                          }
                        />
                      </div>
                      <div className="flex flex-row">
                        {addMoreItems && (
                          <Label13
                            className={"text-red"}
                          >{`El código de descuento es correcto pero la cantidad minima para solicitarlo es ${discountQuantity}€`}</Label13>
                        )}
                        {!isVoucherMatchWithOrderType &&
                          !addMoreItems &&
                          !invalidDiscountCode &&
                          !alreadyUsed && (
                            <Label13
                              className={"text-red"}
                            >{`Cupón no disponible para este tipo de orden`}</Label13>
                          )}
                        {invalidDiscountCode && (
                          <Label13 className={"text-red"}>
                            {inValidDiscountText}
                          </Label13>
                        )}
                        {alreadyUsed && (
                          <Label13 className={"text-red"}>
                            {usedDiscountText}
                          </Label13>
                        )}
                      </div>
                    </>
                  )}

                  <hr />

                  <div className={classes.items_total}>
                    {selectedOption == CartType.DELIVERY &&
                      items?.length != 0 &&
                      subTotal < minOrderPrice &&
                      showDeliveryPrice && (
                        <div className={classes.totals}>
                          <Label14Bold className={"text-red"}>
                            {minOrderText}
                          </Label14Bold>
                          <Label14Bold className="pull-left text-red">
                            {minOrderPrice} €
                          </Label14Bold>
                        </div>
                      )}
                    <div className={classes.totals}>
                      <Label14Bold>{subtotalText}</Label14Bold>
                      <Label14Bold className="pull-left">
                        {subTotal} €
                      </Label14Bold>
                    </div>
                    {checkout && discountApplicable && (
                      <div className={classes.totals}>
                        <Label14Bold className="text-red">
                          Discount {discountAmount}
                          {discountType === "percentage" ? "%" : "€"}
                        </Label14Bold>
                        <Label14Bold className="pull-left text-red">
                          -
                          {discountType === "percentage"
                            ? (subTotal * Number(discountAmount)) / 100
                            : Number(discountAmount)}{" "}
                          €
                        </Label14Bold>
                      </div>
                    )}

                    {showDeliveryPrice &&
                      selectedOption &&
                      selectedOption == "2" &&
                      deliveryPrice > 0 && (
                        <div className={classes.totals}>
                          <Label14Bold>{homeDeliveryText}</Label14Bold>
                          <Label14Bold className="pull-left">
                            {deliveryPrice} €
                          </Label14Bold>
                        </div>
                      )}
                    <div className={classes.grand_total}>
                      <Heading2>{totalText}</Heading2>
                      <span className="pull-left">{totalPrice} €</span>
                    </div>
                  </div>
                  <hr />
                  <div className={classes.buttonContainer}>
                    <Button
                      disabled={
                        items?.length === 0 ||
                        (selectedOption == CartType.DELIVERY &&
                          subTotal < minOrderPrice) ||
                        canBeSubmit
                      }
                      backgroundColor={typography}
                      classes={classes.button}
                      title={hacerText}
                      onClick={onClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </Sticky>
    </StickyContainer>
  )
}

export default TableRight
