import React from "react"
import { Card } from "@material-ui/core"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { Heading2 } from "@components/Text/Text"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"

export interface Props {
  venueName: string
  typography?: string
}

interface StyleProps {
  typography?: string
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: () => ({
    boxShadow:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    borderRadius: "0px",
  }),
}))
const VenueCard = ({ venueName, typography }: Props) => {
  const styleProps: StyleProps = { typography }
  const classes = useStyles(styleProps)
  return (
    <Card
      elevation={0}
      className={[
        "flex flex-row self-center items-center w-full h-32 mt-12 mb-8 bg-white shadow-md",
        classes.root,
      ].join()}
    >
      <LocationOnIcon style={{ color: typography }} className={"ml-4 mr-6"} />
      <Heading2
        style={{ color: "rgb(105, 105, 105)", marginTop: "0.1rem" }}
        className={"m-0"}
      >
        {venueName}
      </Heading2>
    </Card>
  )
}

export default VenueCard
