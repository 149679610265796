import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Box } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import { Theme } from "@material-ui/core"
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded"
import Button from "@components/buttons/Button"

import classes from "./orderConfirmed.module.css"

import Footer from "@components/footer/Footer"
import { CartType } from "@models/Cart"
import { Heading2 } from "@components/Text/Text"
import MainHeader from "@components/header/MainHeader"
import Header from "./Header"
import ItemsDetails from "./ItemsDetails"
import MobileOrderConfirmed from "./MobileOrderConfirmed"

interface StyleProps {
  typography?: string
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: () => ({
    boxShadow:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  }),
}))
const OrderConfirmed = (props) => {
  const history = useHistory()
  const restaurant = useSelector(({ venues }: any) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const { _id: userId } = useSelector(({ user }: RootState) => user.user)
  const { orderId } = useSelector(({ user }: RootState) => user)
  const selectedOption = useSelector(({ venues }: any) => venues.selectedOption)
  const { venueId } = props.match.params

  const [width, setWidth] = useState(window.innerWidth)

  const [deliveryPrice, setDeliveryPrice] = useState(0)

  const cart = useSelector(({ cart }: RootState) => cart.cart)
  const cartDetail = useSelector(({ cart }: RootState) => cart)
  const cartItems = cart.items

  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  useEffect(() => {
    if (
      selectedOption === CartType.DELIVERY &&
      cartDetail &&
      cartDetail.address &&
      cartDetail.address.minDeliveryPrice
    ) {
      setDeliveryPrice(Number(cartDetail.address.minDeliveryPrice))
    }
  }, [cartDetail && cartDetail.address])

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
  }, [])

  const isMobile = width <= 600

  const styleProps: StyleProps = { typography }
  const Classes = useStyles(styleProps)
  return (
    <>
      {isMobile ? (
        <MobileOrderConfirmed typography={typography} orderId={orderId} />
      ) : (
        <div className={classes.container}>
          <MainHeader
            isMobile={isMobile}
            restaurant={restaurant}
            venueDetail={venueDetail}
            userId={userId}
            typography={typography}
            venueId={venueId}
            showLogin
            showLanguage={true}
          />

          <Box className={"flex w-full justify-center"}>
            <Card
              className={[
                "flex flex-col w-5/12 bg-black mt-10 mb-8",
                Classes.root,
              ].join(" ")}
              style={{ minHeight: "25rem" }}
            >
              <Header typography={typography} />
              <div
                className={"flex flex-col w-full justify-center items-center"}
              >
                <CheckCircleOutlineRoundedIcon
                  style={{ color: typography, fontSize: 120, marginTop: 10 }}
                />
                <Heading2 className={"mt-4"}>Gracias!</Heading2>
              </div>
              <ItemsDetails
                items={cartItems}
                typography={typography}
                deliveryPrice={deliveryPrice}
                selectedOption={selectedOption}
              />
              <Button
                title={"Continuar"}
                backgroundColor={typography}
                classes={
                  "flex items-center justify-center self-center mt-1 mb-5 w-4/5 h-24 text-white py-2 px-4 border border-transparent cursor-pointer outline-none"
                }
                style={{
                  borderRadius: "25px",
                  fontSize: "12px",
                }}
                onClick={() => {
                  window.location.href = `/profile/${orderId}`
                }}
              />
            </Card>
          </Box>
          <div className={classes.footer}>
            <Footer
              facebook={
                venueDetail.contactInfo && venueDetail.contactInfo.facebook
              }
              instagram={
                venueDetail.contactInfo && venueDetail.contactInfo.instagram
              }
              twitter={
                venueDetail.contactInfo && venueDetail.contactInfo.twitter
              }
            />
          </div>
        </div>
      )}
    </>
  )
}

export default OrderConfirmed
