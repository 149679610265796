import React, { useState } from "react"
import { Card } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { useDispatch } from "react-redux"

import { changePassword } from "@store/user/UserActions"
import Button from "@components/buttons/Button"

export interface Props {
  typography?: string
}

interface StyleProps {
  typography?: string
}
const ChangePasswordForm = ({ typography }: Props) => {
  const dispatch = useDispatch()
  const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: (props) => ({
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      borderRadius: "8px",
    }),
  }))
  const useTextFieldStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: (props) => ({
      "& .MuiInput-underline:after": {
        borderBottomColor: props.typography,
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: props.typography,
      },
    }),
  }))
  const [form, setForm] = useState<any>({})

  const handleChange = (e) => {
    const { target: input } = e
    let newForm = { ...form }
    newForm[input.name] = input.value
    setForm(newForm)
  }
  const handleSubmit = () => {
    dispatch(changePassword(form.password))
  }
  const canBeSubmit = () => {
    const { password, confirmPassword } = form
    if (
      password &&
      password.length > 5 &&
      confirmPassword &&
      confirmPassword.length > 5 &&
      password === confirmPassword
    ) {
      return false
    }
    return true
  }
  const styleProps: StyleProps = { typography }
  const Classes = useStyles(styleProps)
  const TextFieldClasses = useTextFieldStyles(styleProps)
  return (
    <div className={"flex flex-col w-full"}>
      <Card
        className={[
          "flex flex-col bg-white md:p-16 p-8 pb-10",
          Classes.root,
        ].join(" ")}
      >
        <form className={"flex flex-col"} noValidate autoComplete="off">
          <TextField
            className={TextFieldClasses.root}
            id="password"
            name="password"
            label="Contraseña"
            type="password"
            value={form.password}
            onChange={handleChange}
            error={
              (form.password && form.password.length < 6) ||
              (form.confirmPassword && form.password !== form.confirmPassword)
            }
            helperText={
              form.password && form.password.length < 6
                ? "La contraseña debe tener más de 5 caracteres."
                : ""
            }
            required
          />
          <TextField
            className={TextFieldClasses.root}
            style={{ marginTop: 10 }}
            id="confirmPassword"
            name="confirmPassword"
            label="Confirmar contraseña"
            type="password"
            value={form.confirmPassword}
            onChange={handleChange}
            error={form.confirmPassword && form.confirmPassword.length < 6}
            helperText={
              form.confirmPassword && form.confirmPassword.length < 6
                ? "Confirmar contraseña debe tener más de 5 caracteres"
                : ""
            }
            required
          />
        </form>
      </Card>
      <Button
        title={"Enviar"}
        backgroundColor={typography}
        classes={
          "flex items-center justify-center self-center w-11/12 h-24 text-white py-2 px-4 border border-transparent cursor-pointer outline-none md:relative fixed mt-0 md:mt-10"
        }
        style={{
          borderRadius: "25px",
          fontSize: "14px",
          bottom: 10,
        }}
        onClick={handleSubmit}
        disabled={canBeSubmit()}
      />
    </div>
  )
}

export default ChangePasswordForm
