import { MenuActionTypes } from "@store/redux/actionTypes"

const INITIAL_STATE: MenuState = {
  menuList: {},
  menus: [],
  allergensList: [],
  menuTranslation: [],
  loading: false,
}

interface Action {
  payload: any
  type: string
}

const VenueReducer = (
  state: MenuState = INITIAL_STATE,
  action: Action,
): MenuState => {
  switch (action.type) {
    case MenuActionTypes.GET_ALL_MENU_LIST_START: {
      return { ...state, loading: true }
    }
    case MenuActionTypes.GET_ALL_MENU_LIST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        menus: data,
        loading: false,
      }
    }
    case MenuActionTypes.GET_ALL_MENU_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case MenuActionTypes.GET_MENU_LIST_START: {
      return { ...state, loading: true }
    }
    case MenuActionTypes.GET_MENU_LIST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        menuList: data,
        loading: false,
      }
    }
    case MenuActionTypes.GET_MENU_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }

    case MenuActionTypes.GET_MENU_DETAIL_START: {
      return { ...state, loading: true }
    }
    case MenuActionTypes.GET_MENU_DETAIL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        menuList: data,
        loading: false,
      }
    }
    case MenuActionTypes.GET_MENU_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }

    case MenuActionTypes.GET_ALLERGENS_LIST_START: {
      return { ...state, loading: true }
    }
    case MenuActionTypes.GET_ALLERGENS_LIST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        allergensList: data,
        loading: false,
      }
    }
    case MenuActionTypes.GET_ALLERGENS_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case MenuActionTypes.MENU_LANGUAGE_TRANSLATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        menuTranslation: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default VenueReducer
