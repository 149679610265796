import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe, Stripe } from "@stripe/stripe-js"

import MobileHeader from "@components/header/MobileHeader"
import CustomLoader from "@components/Loaders/CustomLoader"
import MobileCheckoutForm from "@modules/basket/MobileCheckoutForm"

const BasketPage = (props) => {
  const { venueId } = props.match.params

  const history = useHistory()

  const restaurant = useSelector(({ venues }: any) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const userLoading = useSelector(({ user }: RootState) => user.loading)
  const authLoading = useSelector(({ auth }: RootState) => auth.loading)
  const venueLoading = useSelector(({ venues }: RootState) => venues.loading)
  const cartLoading = useSelector(({ cart }: RootState) => cart.loading)

  const loading = userLoading || venueLoading || cartLoading || authLoading

  const [stripePromise] = useState<Promise<Stripe | null>>(
    loadStripe(restaurant.stripePublicKey),
  )
  const [typography, setTypography] = useState("rgb(202, 156, 77)")

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])
  const clientPermalink = localStorage.getItem("clientPermalink")
  return (
    <CustomLoader loading={loading}>
      <MobileHeader
        title={venueDetail.title}
        typography={typography}
        onBack={() =>
          history.push(`/${clientPermalink}/menu/${venueDetail._id}`)
        }
      >
        <Elements stripe={stripePromise}>
          <MobileCheckoutForm venueId={venueId} />
        </Elements>
      </MobileHeader>
    </CustomLoader>
  )
}

export default BasketPage
