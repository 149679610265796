import { axiosInstance } from "@api/axios"
import { toast } from "react-toastify"

import { AuthActionTypes, UserActionTypes } from "@store/redux/actionTypes"
import {
  REGISTER,
  LOGIN,
  LOGIN_SOCIAL,
  getLogoutUserUrl,
  getForgotPasswordUrl,
  RESET_PASSWORD,
  CHECK_TOKEN
} from "@api/endPoints"
import { getUser, removeUser } from "@store/user/UserActions"
import { emptyCart, setSelectedAddress } from "@store/cart/cartActions"
import { User } from "@models/User"
import {
  checkCollectionAvailability,
  checkDeliveryAvailability,
} from "@store/venues/VenueActions"

export const login = (
  venueId: string,
  user?: User,
  isBasket?: boolean,
  checkDelivery?: boolean,
  checkCollection?: boolean,
  history?: any,
  orderFromTable?: boolean,
) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.USER_LOGIN_START,
    })

    const url = LOGIN
    const clientId = localStorage.getItem("clientId")
    const data = {
      ...user,
      clientId,
    }

    axiosInstance
      .post(url, data)
      .then((res) => {
        loginSuccess(
          dispatch,
          res.data.data,
          isBasket,
          clientId,
          venueId,
          checkDelivery,
          checkCollection,
          history,
          orderFromTable,
        )
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 420")
          loginFail(dispatch, "Correo electrónico o contraseña no válidos")
        else loginFail(dispatch, "There was an error connection")
      })
  }
}

const loginFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.USER_LOGIN_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const loginSuccess = (
  dispatch,
  data,
  isBasket,
  clientId,
  venueId,
  checkDelivery,
  checkCollection,
  history,
  orderFromTable,
) => {
  localStorage.setItem("accessToken", data.accessToken)
  localStorage.setItem("userId", data.userAuth._id)
  toast.success("Acceso concedido correctamente")
  dispatch({
    type: AuthActionTypes.USER_LOGIN_SUCCESS,
    payload: data.userAuth,
  })
  dispatch(getUser(clientId))
  if (orderFromTable) {
    history.push(`/menu/basket/${venueId}`)
  }
  if (checkDelivery) {
    dispatch(checkDeliveryAvailability(venueId, true, history))
  }
  if (checkCollection) {
    dispatch(checkCollectionAvailability(venueId, true, history))
  }
}

export const socialLogin = (
  venueId: string,
  user?: User,
  isBasket?: boolean,
  checkDelivery?: boolean,
  checkCollection?: boolean,
  history?: any,
  orderFromTable?: boolean,
) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.USER_LOGIN_START,
    })

    const url = LOGIN_SOCIAL
    const clientId = localStorage.getItem("clientId")
    const data = {
      user,
      clientId,
    }

    axiosInstance
      .post(url, data)
      .then((res) => {
        socialLoginSuccess(
          dispatch,
          res.data.data,
          isBasket,
          clientId,
          venueId,
          checkDelivery,
          checkCollection,
          history,
          orderFromTable,
        )
      })
      .catch((error) => {
        console.log(error)
        socialLoginFail(dispatch, "There was an error connection")
      })
  }
}

const socialLoginFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.USER_LOGIN_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const socialLoginSuccess = (
  dispatch,
  data,
  isBasket,
  clientId,
  venueId,
  checkDelivery,
  checkCollection,
  history,
  orderFromTable,
) => {
  localStorage.setItem("accessToken", data.accessToken)
  localStorage.setItem("userId", data.userAuth._id)
  toast.success("Acceso concedido correctamente")
  dispatch({
    type: AuthActionTypes.USER_LOGIN_SUCCESS,
    payload: data.userAuth,
  })
  dispatch(getUser(clientId))
  if (orderFromTable) {
    history.push(`/menu/basket/${venueId}`)
  }
  if (checkDelivery) {
    dispatch(checkDeliveryAvailability(venueId, true, history))
  }
  if (checkCollection) {
    dispatch(checkCollectionAvailability(venueId, true, history))
  }
}

export const registerUser = (
  venueId: string,
  user,
  isBasket?: boolean,
  checkDelivery?: boolean,
  checkCollection?: boolean,
  history?: any,
  orderFromTable?: boolean,
) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.USER_REGISTER_START,
    })
    const data = {
      ...user,
      clientId: localStorage.getItem("clientId"),
    }
    const url = REGISTER
    axiosInstance
      .post(url, data)
      .then((res) => {
        registerUserSuccess(
          dispatch,
          res.data.data,
          user,
          venueId,
          isBasket,
          checkDelivery,
          checkCollection,
          history,
          orderFromTable,
        )
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 406")
          registerUserFail(dispatch, "El email ya existe")
        else registerUserFail(dispatch, "There was an error connection")
      })
  }
}

const registerUserFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.USER_REGISTER_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const registerUserSuccess = (
  dispatch,
  data,
  user,
  venueId,
  isBasket,
  checkDelivery,
  checkCollection,
  history,
  orderFromTable,
) => {
  dispatch({
    type: AuthActionTypes.USER_REGISTER_SUCCESS,
    payload: data,
  })
  dispatch(
    login(
      venueId,
      { email: user.email, password: user.password },
      isBasket,
      checkDelivery,
      checkCollection,
      history,
      orderFromTable,
    ),
  )
}
export const logout = (redirect?: boolean) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.USER_LOGOUT_START,
    })
    const url = getLogoutUserUrl()

    axiosInstance
      .get(url)
      .then((res) => {
        logOutSuccess(dispatch, res.data.data, redirect)
      })
      .catch((error) => {
        logOutFail(dispatch, "There was an error connection")
      })
  }
}

const logOutFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.USER_LOGOUT_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const logOutSuccess = (dispatch, data, redirect) => {
  localStorage.removeItem("accessToken")
  localStorage.removeItem("userId")
  toast.success("Te has deslogeado correctamente")
  dispatch({
    type: UserActionTypes.GET_USER_DETAILS_SUCCESS,
    payload: {},
  })

  emptyCart()
  removeUser()
  dispatch(setSelectedAddress({}))
  if (redirect) {
    window.location.href = `/${localStorage.getItem("clientPermalink")}`
  }
  dispatch({
    type: AuthActionTypes.USER_LOGOUT_SUCCESS,
    payload: data,
  })
}

export const forgotPassword = (email) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.FORGOT_PASSWORD_START,
    })

    const url = getForgotPasswordUrl()
    const clientId = localStorage.getItem("clientId")
    const data = {
      email,
      clientId,
    }
    axiosInstance
      .post(url, data)
      .then((res) => {
        forgotPasswordSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 404")
          forgotPasswordFail(dispatch, "Este correo electrónico no existe")
        else forgotPasswordFail(dispatch, "There was an error connection")
      })
  }
}

const forgotPasswordFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.FORGOT_PASSWORD_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const forgotPasswordSuccess = (dispatch, data) => {
  toast.success("Consultar su correo electrónico")
  dispatch({
    type: AuthActionTypes.FORGOT_PASSWORD_SUCCESS,
    payload: data,
  })
}

export const resetPassword = (data,permalink) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.RESET_PASSWORD_START,
    })

    const url = RESET_PASSWORD
    const request=data 
    axiosInstance
      .put(url, request)
      .then((res) => {
        resetPasswordSuccess(dispatch, res.data.data,permalink)
      })
      .catch((error) => {
         resetPasswordFail(dispatch, "There was an error connection")
      })
  }
}

const resetPasswordFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.RESET_PASSWORD_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const resetPasswordSuccess = (dispatch, data,permalink) => {
  toast.success("Contraseña cambiada con éxito")
  dispatch({
    type: AuthActionTypes.RESET_PASSWORD_SUCCESS,
    payload: data,
  })
  window.location.href="/"+permalink
}
export const validateToken = (token) => {
  return (dispatch) => {
    dispatch({
      type: AuthActionTypes.CHECK_VALID_TOKEN_START,
    })

    const url = CHECK_TOKEN
    const data = {
      token,
    }
    axiosInstance
      .post(url, data)
      .then((res) => {
        validateTokenSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 404")
        
          validateTokenFail(dispatch, "There was an error connection")
        else validateTokenFail(dispatch, "There was an error connection")
      })
  }
}

const validateTokenFail = (dispatch, errorMessage) => {
  dispatch({
    type: AuthActionTypes.CHECK_VALID_TOKEN_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const validateTokenSuccess = (dispatch, data) => {
  dispatch({
    type: AuthActionTypes.CHECK_VALID_TOKEN_SUCCESS,
    payload: data,
  })
}