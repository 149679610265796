import React from "react"
import { useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { confirmPendingOrder } from "@store/cart/cartActions"

interface Props {
  match?: any
}

const RedsysSuccessPage = ({ match }: Props) => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(confirmPendingOrder(match.params.orderId))
  }, [])
  return <></>
}

export default RedsysSuccessPage
