import React, { useState, useEffect } from "react"
import { Box } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { useStripe } from "@stripe/react-stripe-js"
import { useHistory } from "react-router-dom"

import TableRight from "@components/tables/tableright/TableRight"
import {
  getRedsysParam,
  setCartPendingPayment,
  submitOrder,
} from "@store/cart/cartActions"
import { CartType, ItemOrderMenu } from "@models/Cart"
import CommentsCard from "../CommentsCard"
import VenueCard from "../VenueCard"
import { getUser } from "@store/user/UserActions"
import CartOptionsCard from "@components/optionsCard/CartOptionsCard"
import AddCardModal from "@components/modals/AddCardModal"
import AddAddressModal from "@components/modals/AddAddressModal"
import { setSelectedAddress, selectCard } from "@store/cart/cartActions"
import { userAddressList, getCardList } from "@store/user/UserActions"
import {
  checkCollectionAvailability,
  checkDeliveryAvailability,
} from "@store/venues/VenueActions"
import AddPhoneNumberModal from "@components/modals/AddPhoneNumberModal"

interface Props {
  typography: string
  venueId: string
  restaurant: Restaurant
}

const CheckoutForm = ({ typography, venueId, restaurant }: Props) => {
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const addresses = useSelector(({ user }: any) => user.addresses)
  const cards = useSelector(({ user }: any) => user.cards)
  const menuList = useSelector(({ menu }: RootState) => menu.menuList)

  const stripe = useStripe()
  const history = useHistory()

  const {
    address: selectedAddress,
    selectedCard,
    secret,
  } = useSelector(({ cart }: any) => cart)
  const orderFromTable = useSelector(
    ({ orderFromTable }: any) => orderFromTable.orderFromTable,
  )
  const settings = useSelector(
    ({ venues }: RootState) => venues.restaurant.settings,
  )
  const cart = useSelector(({ cart }: RootState) => cart.cart)
  const cartAddress = useSelector(({ cart }: RootState) => cart.cart)

  const cartDetail = useSelector(({ cart }: RootState) => cart)
  const hasRedsys = settings?.redsys ? settings?.redsys : false

  const cartItems = cart.items
  const selectedOption = useSelector(({ venues }: any) => venues.selectedOption)
  const { _id: userId } = useSelector(({ user }: RootState) => user.user)
  const { user } = useSelector(({ user }: RootState) => user)

  const [openAddress, setOpenAddress] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [comments, setComments] = useState("")
  const [selectedPaymentValue, setSelectedPaymentValue] = useState("withCard")
  const [openPhoneModal, setPhoneModal] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      selectedAddress &&
      Object.keys(selectedAddress).length < 1 &&
      addresses &&
      addresses.length > 0 &&
      selectedOption == "2"
    ) {
      const address = addresses.find(
        (address) => address.isValidAddress == true,
      )
      address && dispatch(setSelectedAddress(address))
    }
    if (
      selectedCard == "" &&
      cards &&
      cards.length > 0 &&
      selectedPaymentValue == "withCard"
    )
      dispatch(selectCard(cards[0].id))
  }, [cards, addresses])

  useEffect(() => {
    const payByCash = restaurant.settings && restaurant.settings.payByCash
    const payByCard = restaurant.settings && restaurant.settings.payByCard

    if (payByCash && !payByCard) {
      setSelectedPaymentValue("withCash")
    }
    if (!payByCash && payByCard) {
      setSelectedPaymentValue("withCard")
    }
  }, [restaurant])

  useEffect(() => {
    let userId = localStorage.getItem("userId")
    let clientId = localStorage.getItem("clientId")
    if (userId) {
      dispatch(getUser(clientId))
    }
    dispatch(getCardList())
  }, [])

  useEffect(() => {
    if (Object.keys(venueDetail) && venueDetail && venueDetail._id)
      dispatch(userAddressList(localStorage.getItem("userId"), venueDetail._id))
  }, [venueDetail])

  const handleAddAddress = () => {
    setOpenAddress(true)
  }

  const handleOrderSubmit = async () => {
    let deliveryPrice = 0

    if (
      selectedOption === CartType.DELIVERY &&
      cartDetail.address &&
      cartDetail.address.minDeliveryPrice
    ) {
      deliveryPrice = Number(cartDetail.address.minDeliveryPrice)
    }
    let totalPrice = 0
    cart.items.map((item: ItemOrderMenu) => {
      totalPrice = totalPrice + Number(item.price) * item.quantity
    })
    let payload = {
      clientId: localStorage.getItem("clientId"),
      userId,
      price: Number(totalPrice.toFixed(2)),
      items: cart.items,
      type: selectedOption,
      addressId: cartDetail.address._id,
      venueId: venueDetail._id,
      deliveryPrice,
      orderTime: "",
      orderTable: orderFromTable._id,
      comments,
      card: selectedPaymentValue == "withCard" ? cartDetail.selectedCard : "",
      payByCard: selectedPaymentValue == "withCard" ? true : false,
      discountVoucherId: cartDetail.discountVoucherId,
      payByRedsys:
        hasRedsys && selectedPaymentValue == "withCard" ? true : false,
      isFromSinqro: menuList.isSinqro === true,
    }

    if (canBeSubmit()) {
      if (!user.phone || user.phone == "") {
        setPhoneModal(true)
        return
      }
      if (selectedOption === CartType.ORDER_FROM_TABLE) {
        if (payload.payByRedsys) {
          dispatch(setCartPendingPayment(payload))
          dispatch(getRedsysParam(payload))
        } else {
          dispatch(submitOrder(payload, stripe))
        }
      } else if (selectedOption === CartType.COLLECT) {
        dispatch(
          checkCollectionAvailability(
            venueId,
            false,
            history,
            true,
            payload,
            stripe,
          ),
        )
      } else if (selectedOption === CartType.DELIVERY) {
        dispatch(
          checkDeliveryAvailability(
            venueId,
            false,
            history,
            true,
            payload,
            stripe,
          ),
        )
      }
    }
  }
  const canBeSubmit = () => {
    if (selectedOption == "2" && cartDetail.address._id == undefined) {
      return false
    }
    if (
      selectedPaymentValue == "withCard" &&
      !hasRedsys &&
      cartDetail.selectedCard == ""
    ) {
      return false
    }
    return true
  }
  const onAddressClick = (address) => {
    if (address.isValidAddress) dispatch(setSelectedAddress(address))
  }

  const onCardClick = (card) => {
    dispatch(selectCard(card.id))
  }

  const handleAddCard = () => {
    setShowModal(true)
  }

  const handleRadioChange = (e) => {
    setSelectedPaymentValue(e.target.value)
    if (e.target.value == "withCash") {
      dispatch(selectCard(""))
    }

    if (
      e.target.value == "withCard" &&
      selectedCard == "" &&
      cards &&
      cards.length > 0
    ) {
      dispatch(selectCard(cards[0].id))
    }
  }
  return (
    <Box
      className={"flex w-full justify-end mb-8 md:mt-4 md:pb-4 md:pl-4"}
      style={{ marginTop: "4rem" }}
    >
      <Box className={"w-full md:w-1/2 md:mt-2"}>
        <>
          {selectedOption === "1" || selectedOption === "3" ? (
            <VenueCard venueName={venueDetail.title} typography={typography} />
          ) : (
            <CartOptionsCard
              title={"Elige dirección de envío"}
              addTitle={"Añadir dirección"}
              innerCards={addresses.map((address) => ({
                ...address,
                description:
                  address.address && address.address.length > 85
                    ? address.address.substr(0, 85) + "..."
                    : address.address,
                isValidAddress: address.isValidAddress,
              }))}
              itemClickHandler={onAddressClick}
              addClickHandler={handleAddAddress}
              selectedValue={cartDetail.address._id}
              typography={typography}
              address={true}
            />
          )}
          <div className={"mt-12"}>
            <CartOptionsCard
              title={"Elige metodo de pago"}
              addTitle={"Añadir tarjeta"}
              innerCards={
                cards && cards.length > 0
                  ? cards.map((card) => ({
                      ...card,
                      _id: card.id,
                      title: `**** **** **** ${card.last4}`,
                    }))
                  : []
              }
              itemClickHandler={onCardClick}
              addClickHandler={handleAddCard}
              selectedValue={cartDetail.selectedCard}
              typography={typography}
              paymentOptionCard
              selectedPaymentValue={selectedPaymentValue}
              onRadioChange={handleRadioChange}
            />
          </div>
          <CommentsCard
            value={comments}
            onValueChange={(v) => {
              setComments(v.currentTarget.value)
            }}
            typography={typography}
          />
        </>
      </Box>
      <TableRight
        showDeliveryPrice={true}
        deliveryPrice={Number(
          cartDetail &&
            cartDetail.address &&
            cartDetail.address.minDeliveryPrice
            ? cartDetail.address.minDeliveryPrice
            : 0,
        )}
        minOrderPrice={Number(
          cartDetail && cartDetail.address && cartDetail.address.minOrderPrice
            ? cartDetail.address.minOrderPrice
            : 0,
        )}
        items={cartItems}
        onClick={handleOrderSubmit}
        canBeSubmit={!canBeSubmit()}
        checkout
      />
      <AddCardModal
        showModal={showModal}
        onCloseModal={() => setShowModal(false)}
      />
      <AddAddressModal
        venueId={venueDetail._id}
        setOpenAddress={setOpenAddress}
        openAddress={openAddress}
      />
      <AddPhoneNumberModal
        setOpen={setPhoneModal}
        open={openPhoneModal}
        user={user}
        typography={typography}
      />
    </Box>
  )
}

export default CheckoutForm
