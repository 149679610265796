import React from "react"
import { Card } from "@material-ui/core"
import LocationOnIcon from "@material-ui/icons/LocationOn"

import { Label14, Label17, Heading3 } from "@components/Text/Text"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"

export interface StyleProps {
  typography?: string
}
export interface Props {
  typography?: string
  addresses?: any
  addressItem?: any
  addAddress?: () => void
  viewAddressList?: () => void
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: () => ({
    boxShadow:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    borderRadius: "8px",
  }),
}))
const LocationCard = ({ typography, addressItem }: Props) => {
  const styleProps: StyleProps = { typography }
  const classes = useStyles(styleProps)
  return (
    <Card
      elevation={0}
      className={[
        "flex flex-col self-center md:w-full w-11/12 mt-16 bg-white shadow-md rounded-4",
        classes.root,
      ].join()}
      style={{ minHeight: "5rem" }}
    >
      <div
        className={
          "flex flex-row  items-center justify-between w-full mt-6 pr-2"
        }
      >
        <div className={"flex flex-row justify-between"}>
          <LocationOnIcon
            style={{ color: typography }}
            className={"ml-4 mr-6"}
          />
          <Heading3 className="m-0">Dirección de envío</Heading3>
        </div>
      </div>
      {addressItem && Object.keys(addressItem).length > 0 && (
        <div className={"flex flex-col w-full pl-24 mb-2 pt-8"}>
          <Label17 className="m-0 font-700">{addressItem.title}</Label17>
          <Label14 className="pt-4 font-700">
            {addressItem.address && addressItem.address.length > 25
              ? addressItem.address.substr(0, 25) + "..."
              : addressItem.address}
          </Label14>
          <Label14 className={"text-gray-800 font-700"}>
            {addressItem.postcode + ", "}
            {addressItem.city}
          </Label14>
        </div>
      )}
    </Card>
  )
}

export default LocationCard
