import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"

import MobileHeader from "@components/header/MobileHeader"
import { Body13, Heading5 } from "@components/Text/Text"
import Button from "@components/buttons/Button"
import { setUserAddress } from "@store/user/UserActions"

interface StyleProps {
  typography?: string
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    boxShadow:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    borderRadius: "8px",
  }),
}))
const useTextFieldStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    "& .MuiInput-underline:after": {
      borderBottomColor: props.typography,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: props.typography,
    },
  }),
}))
function MobileAddressNamePage(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const restaurant = useSelector(({ venues }: any) => venues.restaurant)
  const { address } = useSelector(({ user }: any) => user)
  const [typography, setTypography] = useState("rgb(202, 156, 77)")

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])
  const [form, setForm] = useState<any>(address)

  useEffect(() => {
    setForm(address)
  }, [address])
  const handleChange = (e) => {
    const { target: input } = e
    let newForm = { ...form }
    newForm[input.name] = input.value
    setForm(newForm)
  }
  const handleSubmit = () => {
    dispatch(
      setUserAddress({
        ...address,
        title: form.title,
      }),
    )
    history.push(`/mobileAddressDetailsPage`)
  }
  const canBeSubmit = () => {
    const { title } = form
    if (title && title.length > 2) {
      return false
    }
    return true
  }
  const styleProps: StyleProps = { typography }
  const Classes = useStyles(styleProps)
  const TextFieldClasses = useTextFieldStyles(styleProps)

  return (
    <MobileHeader
      title={""}
      typography={typography}
      onBack={() => history.goBack()}
      style={{ marginBottom: 0 }}
    >
      <div className="flex w-11/12 self-center mt-48">
        <div className={"flex flex-col w-full"}>
          <img
            className="w-40 h-40 object-contain"
            src={require("@assets/Images/addressname.png")}
          />
          <Heading5 style={{ marginTop: "1rem" }}>Introduce nombre</Heading5>

          <form className={"flex flex-col pt-5"} noValidate autoComplete="off">
            <TextField
              className={TextFieldClasses.root}
              id="title"
              name="title"
              label="Nombre"
              value={form.title}
              onChange={handleChange}
              required
            />
            <Body13 style={{ marginTop: "0.5rem", color: "grey" }}>
              Por Ejemplo: Casa Trabajo
            </Body13>
          </form>
          <Button
            title={"Añadir"}
            backgroundColor={typography}
            classes={
              "flex items-center justify-center self-center w-11/12 h-24 text-white py-2 px-4 border border-transparent cursor-pointer outline-none md:relative fixed mt-0 md:mt-10"
            }
            style={{
              borderRadius: "25px",
              fontSize: "14px",
              bottom: 20,
            }}
            onClick={handleSubmit}
            disabled={canBeSubmit()}
          />
        </div>
      </div>
    </MobileHeader>
  )
}

export default MobileAddressNamePage
