export interface Cart {
    deliveryPrice?: number
    items: ItemOrderMenu[]
    type?: CartType
}


export interface ItemOrderMenu {
    _id: string
    allergenes?: string
    categoryId?: string
    description?: string
    extraCategories?: ExtraCategory[]
    //TODO TO BE REMOVED after migration
    familyPropertiesMulti?: FamilyPropertiesMulti[]
    familyPropertiesUni?: FamilyPropertiesUni[]
    hasImage?: string
    image?: string
    price: number
    quantity: number
    sendTried?: boolean
    title: string
}

export interface FamilyPropertiesMulti {
    description?: string
    hasAmount?: boolean
    hasError?: boolean
    id: string
    items: FamilyPropertiesItem[]
    maxAmount?: number
    minAmount?: number
    title: string
}

export interface FamilyPropertiesUni {
    description?: string
    hasError?: boolean
    id: string
    items: FamilyPropertiesItem[]
    title: string
}

export interface FamilyPropertiesItem {
    extraPrice: number
    selected: boolean
    title: string
}

export enum CartType {
    COLLECT = "1",
    DELIVERY = "2",
    ORDER_FROM_TABLE = "3",
    FUTURE_ORDER = "4",
}