import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import MobileHeader from "@components/header/MobileHeader"
import MobileDetailCard from "@components/MobileDetailCard/MobileDetailCard"
import { selectCard } from "@store/cart/cartActions"
import { getCardImage } from "@store/cart/cartHelpers"

const CardsListPage = () => {
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)

  const { cards } = useSelector(({ user }: RootState) => user)

  const [typography, setTypography] = useState("rgb(202, 156, 77)")

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const selectCardHandler = (cardId?: string) => {
    if (cardId) {
      dispatch(selectCard(cardId))
      history.goBack()
    }
  }

  return (
    <MobileHeader
      title={"Método de pago"}
      typography={typography}
      rightTitle={"Añadir"}
      rightButton
      rightButtonPress={() => history.push("add-card")}
    >
      <div className="mt-36 p-6">
        {cards &&
          cards.length > 0 &&
          cards.map((card) => (
            <MobileDetailCard
              image={getCardImage(card.brand)}
              title={`**** **** **** ${card.last4}`}
              key={card.id}
              onClick={() => selectCardHandler(card.id)}
            />
          ))}
      </div>
    </MobileHeader>
  )
}

export default CardsListPage
