import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux"

import Button from "@components/buttons/Button"
import { Label14 } from '@components/Text/Text';

const Layout = (props) => {
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
  }, [])
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }
  const isMobile = width <= 600
  const clientPermalink = localStorage.getItem("clientPermalink")
  return (
    <div className={"relative"}>
      {props.children}
      {!isMobile && clientPermalink == "trattoria" && <div className={"flex justify-center items-center w-full fixed"} style={{ backgroundColor: typography, bottom: 0 }}><Label14 className={"text-white text-center py-3"}>Este menu es una demo, si te gusta y quieres tener uno igual regístrate gratuitamente en Banzzu</Label14>
        <Button
          color={typography}
          classes={
            "border-none py-1 px-2 rounded cursor-pointer outline-none ml-2"
          }
          title="Regístrate Gratis"
          onClick={() => { window.open("https://app.banzzu.com/register/es", '_blank'); }}
          fontSize={"14px"}
          style={{ backgroundColor: "white", fontSize: "12px" }}
        // disabled={canBeSubmit()}
        /></div>}
    </div>
  );
};

export default Layout;
