export const VenueActionTypes = {
  GET_VENUE_LIST_START: " GET_VENUE_LIST_START",
  GET_VENUE_LIST_SUCCESS: "  GET_VENUE_LIST_SUCCESS",
  GET_VENUE_LIST_FAIL: "  GET_VENUE_LIST_FAIL",

  GET_VENUE_DETAIL_START: " GET_VENUE_DETAIL_START",
  GET_VENUE_DETAIL_SUCCESS: "  GET_VENUE_DETAIL_SUCCESS",
  GET_VENUE_DETAIL_FAIL: "  GET_VENUE_DETAIL_FAIL",

  GET_CLIENT_DETAIL_START: " GET_CLIENT_DETAIL_START",
  GET_CLIENT_DETAIL_SUCCESS: "  GET_CLIENT_DETAIL_SUCCESS",
  GET_CLIENT_DETAIL_FAIL: "  GET_CLIENT_DETAIL_FAIL",

  SET_SELECT_OPTION: "SET_SELECT_OPTION",

  CHECK_DELIVERY_AVAILABILITY_START: "CHECK_DELIVERY_AVAILABILITY_START",
  CHECK_DELIVERY_AVAILABILITY_SUCCESS: "CHECK_DELIVERY_AVAILABILITY_SUCCESS",
  CHECK_DELIVERY_AVAILABILITY_FAIL: "CHECK_DELIVERY_AVAILABILITY_FAIL",

  CHECK_COLLECTION_AVAILABILITY_START: "CHECK_COLLECTION_AVAILABILITY_START",
  CHECK_COLLECTION_AVAILABILITY_SUCCESS:
    "CHECK_COLLECTION_AVAILABILITY_SUCCESS",
  CHECK_COLLECTION_AVAILABILITY_FAIL: "CHECK_COLLECTION_AVAILABILITY_FAIL",

  EMPTY_VENUE_DETAIL: "EMPTY_VENUE_DETAIL",

  SET_PAYMENT_SETTING: "SET_PAYMENT_SETTING",
}

export const MenuActionTypes = {
  GET_ALL_MENU_LIST_START: " GET_ALL_MENU_LIST_START",
  GET_ALL_MENU_LIST_SUCCESS: "  GET_ALL_MENU_LIST_SUCCESS",
  GET_ALL_MENU_LIST_FAIL: "  GET_ALL_MENU_LIST_FAIL",

  GET_MENU_LIST_START: " GET_MENU_LIST_START",
  GET_MENU_LIST_SUCCESS: "  GET_MENU_LIST_SUCCESS",
  GET_MENU_LIST_FAIL: "  GET_MENU_LIST__FAIL",

  GET_MENU_DETAIL_START: " GET_MENU_DETAIL_START",
  GET_MENU_DETAIL_SUCCESS: "  GET_MENU_DETAIL_SUCCESS",
  GET_MENU_DETAIL_FAIL: "  GET_MENU_DETAIL_FAIL",

  GET_ALLERGENS_LIST_START: " GET_ALLERGENS_LIST_START",
  GET_ALLERGENS_LIST_SUCCESS: "GET_ALLERGENS_LIST_SUCCESS",
  GET_ALLERGENS_LIST_FAIL: "GET_ALLERGENS_LIST__FAIL",

  MENU_CHANGE_LANGUAGE: "MENU_CHANGE_LANGUAGE",
  MENU_LANGUAGE_TRANSLATION: "MENU_LANGUAGE_TRANSLATION",
  MENU_LANGUAGE_TRANSLATION_SUCCESS: "MENU_LANGUAGE_TRANSLATION_SUCCESS",
}

export const CartActionTypes = {
  SELECT_UNI_SELECT: "SELECT_UNI_SELECT",

  SET_ITEM_SELECTED: "SET_ITEM_SELECTED",

  GET_ORDER_MENU_LIST_SUCCESS: "GET_ORDER_MENU_LIST_SUCCESS",

  ADD_ITEM_CART_START: "ADD_ITEM_CART_START",

  ADD_MULTI_ITEM_CART_START: "ADD_MULTI_ITEM_CART_START",

  SET_CART_PENDING_PAYMENT: "SET_CART_PENDING_PAYMENT",

  ORDER_SUBMIT_START: "ORDER_SUBMIT_START",
  ORDER_SUBMIT_SUCCESS: "ORDER_SUBMIT_SUCCESS",
  ORDER_SUBMIT_FAIL: "ORDER_SUBMIT_FAIL",

  INCREASE_ITEM_AMOUNT_ORDER_PAGE: "INCREASE_ITEM_AMOUNT_ORDER_PAGE",

  DECREASE_ITEM_AMOUNT_ORDER_PAGE: "DECREASE_ITEM_AMOUNT_ORDER_PAGE",

  INCREASE_NEW_ITEM_CART_START: "INCREASE_NEW_ITEM_CART_START",

  DECREASE_NEW_ITEM_CART_START: "DECREASE_NEW_ITEM_CART_START",

  ADD_ITEM_WITH_ERROR: "ADD_ITEM_WITH_ERROR",

  SET_SELECTED_ADDRESS: "SET_SELECTED_ADDRESS",

  SET_CART_EMPTY: " SET_CART_EMPTY",

  SET_SELECTED_CARD: "SET_SELECTED_CARD",

  GET_CART_SECRET_START: " GET_CART_SECRET_START",
  GET_CART_SECRET_SUCCESS: "  GET_CART_SECRET_SUCCESS",
  GET_CART_SECRET_FAIL: "  GET_CART_SECRET_FAIL",

  APPLY_DISCOUNT_VOUCHER_START: " APPLY_DISCOUNT_VOUCHER_START",
  APPLY_DISCOUNT_VOUCHER_SUCCESS: "  APPLY_DISCOUNT_VOUCHER_SUCCESS",
  APPLY_DISCOUNT_VOUCHER_FAIL: "  APPLY_DISCOUNT_VOUCHER_FAIL",

  GET_REDSYS_FORM_START: "GET_REDSYS_FORM_START",
  GET_REDSYS_FORM_SUCCESS: "GET_REDSYS_FORM_SUCCESS",
  GET_REDSYS_FORM_FAIL: "GET_REDSYS_FORM_FAIL",

  CONFIRM_PENDING_ORDER_START: "CONFIRM_PENDING_ORDER_START",
  CONFIRM_PENDING_ORDER_SUCCESS: "CONFIRM_PENDING_ORDER_SUCCESS",
  CONFIRM_PENDING_ORDER_FAIL: "CONFIRM_PENDING_ORDER_FAIL",
}

export const AuthActionTypes = {
  USER_LOGIN_START: " USER_LOGIN_START",
  USER_LOGIN_SUCCESS: "  USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "  USER_LOGIN__FAIL",

  USER_REGISTER_START: " USER_REGISTER_START",
  USER_REGISTER_SUCCESS: "  USER_REGISTER_SUCCESS",
  USER_REGISTER_FAIL: "  USER_REGISTER__FAIL",

  USER_LOGOUT_START: " USER_LOGOUT_START",
  USER_LOGOUT_SUCCESS: "  USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAIL: "  USER_LOGOUT__FAIL",

  FORGOT_PASSWORD_START: "FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAIL: "FORGOT_PASSWORD_FAIL",

  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",

  CHECK_VALID_TOKEN_START: "CHECK_VALID_TOKEN_START",
  CHECK_VALID_TOKEN_SUCCESS: "CHECK_VALID_TOKEN_SUCCESS",
  CHECK_VALID_TOKEN_FAIL: "CHECK_VALID_TOKEN_FAIL",
}

export const UserActionTypes = {
  USER_ADD_ADDRESS_START: " USER_ADD_ADDRESS_START",
  USER_ADD_ADDRESS_SUCCESS: "  USER_ADD_ADDRESS_SUCCESS",
  USER_ADD_ADDRESS_FAIL: "  USER_ADD_ADDRESS__FAIL",

  GET_POSTION_FROM_ADDRESS_START: "GET_POSTION_FROM_ADDRESS_START",
  GET_POSTION_FROM_ADDRESS_SUCCESS: "GET_POSTION_FROM_ADDRESS_SUCCESS",
  GET_POSTION_FROM_ADDRESS_FAIL: "GET_POSTION_FROM_ADDRESS_FAIL",

  ADD_USER_PHONE_START: " ADD_USER_PHONE_START",
  ADD_USER_PHONE_SUCCESS: "  ADD_USER_PHONE_SUCCESS",
  ADD_USER_PHONE_FAIL: "  ADD_USER_PHONE__FAIL",

  USER_ADDRESS_LIST_START: " USER_ADDRESS_LIST_START",
  USER_ADDRESS_LIST_SUCCESS: "  USER_ADDRESS_LIST_SUCCESS",
  USER_ADDRESS_LIST_FAIL: "  USER_ADDRESS_LIST__FAIL",

  SET_USER_ADDRESS: "SET_USER_ADDRESS",

  GET_USER_DETAILS_START: "GET_USER_DETAILS_START",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAIL: "GET_USER_DETAILS__FAIL",

  GET_CARD_LIST_START: "GET_CARD_LIST_START",
  GET_CARD_LIST_SUCCESS: "GET_CARD_LIST_SUCCESS",
  GET_CARD_LIST_FAIL: "GET_CARD_LIST__FAIL",

  SAVE_CARD_DETAILS_START: "SAVE_CARD_DETAILS_START",
  SAVE_CARD_DETAILS_SUCCESS: "SAVE_CARD_DETAILS_SUCCESS",
  SAVE_CARD_DETAILS_FAIL: "SAVE_CARD_DETAILS__FAIL",

  DELETE_USER_DETAILS_SUCCESS: "DELETE_USER_DETAILS_SUCCESS",

  UPDATE_USER_START: "UPDATE_USER_START",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: "UPDATE_USER__FAIL",

  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",

  GET_ORDERS_LIST_START: "GET_ORDERS_LIST_START",
  GET_ORDERS_LIST_SUCCESS: "GET_ORDERS_LIST_SUCCESS",
  GET_ORDERS_LIST_FAIL: "GET_ORDERS_LIST__FAIL",

  GET_ORDER_DETAIL_START: "GET_ORDER_DETAIL_START",
  GET_ORDER_DETAIL_SUCCESS: "GET_ORDER_DETAIL_SUCCESS",
  GET_ORDER_DETAIL_FAIL: "GET_ORDER_DETAIL__FAIL",

  ORDER_CANCEL_START: "ORDER_CANCEL_START",
  ORDER_CANCEL_SUCCESS: "ORDER_CANCEL_SUCCESS",
  ORDER_CANCEL_FAIL: "ORDER_CANCEL_FAIL",

  SET_ORDER_ID: "SET_ORDER_ID",
}

export const OrderFromTableActionTypes = {
  GET_TABLE_START: "GET_TABLE_START",
  GET_TABLE_SUCCESS: "GET_TABLE_SUCCESS",
  GET_TABLE_FAIL: "GET_TABLE_FAIL",
}
