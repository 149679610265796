import { axiosInstance } from "@api/axios"
import axios from "axios"
import { toast } from "react-toastify"

import { UserActionTypes } from "@store/redux/actionTypes"
import {
  address,
  getUserDetailUrl,
  saveCardDetailUrl,
  getCardListUrl,
  getChangePasswordUrl,
  getUpdateUserUrl,
  getOrderDetailUrl,
  getOrderCancelUrl,
} from "@api/endPoints"
import { Card } from "@models/Card"
import History from "@history"
import { getOrderListUrl } from "@api/endPoints"

export const setUserAddress = (addressData) => {
  return {
    type: UserActionTypes.SET_USER_ADDRESS,
    payload: addressData,
  }
}
export const addAddress = (
  addressData,
  venueId,
  history?,
  isMobile?,
  isAddressList?,
  isBasket?,
) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.USER_ADD_ADDRESS_START,
    })

    const url = address

    axiosInstance
      .post(url, addressData)
      .then((res) => {
        addAddressSuccess(
          dispatch,
          res.data.data,
          addressData.userId,
          venueId,
          history,
          isMobile,
          isAddressList,
          isBasket,
        )
      })
      .catch((error) => {
        addAddressFail(dispatch, "There was an error connection")
      })
  }
}

const addAddressFail = (dispatch, errorMessage) => {
  toast.error("Lo sentimos ha ocurrido un error")
  dispatch({
    type: UserActionTypes.USER_ADD_ADDRESS_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const addAddressSuccess = (
  dispatch,
  data,
  userId,
  venueId,
  history,
  isMobile,
  isAddressList,
  isBasket,
) => {
  toast.success("Dirección añadida correctamente")
  dispatch({
    type: UserActionTypes.USER_ADD_ADDRESS_SUCCESS,
    payload: data,
  })
  dispatch(userAddressList(userId, venueId))
  if (history && isMobile && isAddressList) {
    history.push("/menu/myAddress/" + venueId)
  }
  if (history && isMobile && isBasket) {
    history.push("/menu/basket/" + venueId)
  }
  dispatch({
    type: UserActionTypes.SET_USER_ADDRESS,
    payload: {
      _id: "",
      addressId: "",
      title: "",
      address: "",
      city: "",
      postcode: "",
      details: "",
      latitude: 0,
      longitude: 0,
    },
  })
}

export const getPositionFromAddress = (address, history?, isMobile?) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.GET_POSTION_FROM_ADDRESS_START,
    })
    const addressFormatted = `${address.address} ,${address.city} ${address.postcode}`
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${addressFormatted}&key=AIzaSyDD3BEbF_rZGtnnM6uITB1irLf4qc4kbgM`

    axios
      .get(url)
      .then((res) => {
        getPositionFromAddressSuccess(dispatch, res.data, history, isMobile)
      })
      .catch((error) => {
        getPositionFromAddressFail(dispatch, "There was an error connection")
      })
  }
}

const getPositionFromAddressFail = (dispatch, errorMessage) => {
  dispatch({
    type: UserActionTypes.GET_POSTION_FROM_ADDRESS_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getPositionFromAddressSuccess = (dispatch, data, history, isMobile) => {
  const addressInfo = data.results[0].geometry.location
  dispatch({
    type: UserActionTypes.GET_POSTION_FROM_ADDRESS_SUCCESS,
    payload: {
      latitude: addressInfo.lat,
      longitude: addressInfo.lng,
    },
  })
  if (history && isMobile) {
    history.push(`/mobileAddressMapPage`)
  }
}
export const userAddressList = (userId, venueId) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.USER_ADDRESS_LIST_START,
    })

    const url = address + "/" + userId + "/" + venueId
    axiosInstance
      .get(url)
      .then((res) => {
        userAddressListSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        userAddressListFail(dispatch, "There was an error connection")
      })
  }
}

const userAddressListFail = (dispatch, errorMessage) => {
  toast.error("Lo sentimos ha ocurrido un error")
  dispatch({
    type: UserActionTypes.USER_ADDRESS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const userAddressListSuccess = (dispatch, data) => {
  dispatch({
    type: UserActionTypes.USER_ADDRESS_LIST_SUCCESS,
    payload: data,
  })
}
export const getUser = (clientId) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.GET_USER_DETAILS_START,
    })

    const url = getUserDetailUrl(clientId)

    axiosInstance
      .get(url)
      .then((res) => {
        getUserSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        getUserFail(dispatch, "Lo sentimos ha ocurrido un error")
      })
  }
}

const getUserFail = (dispatch, errorMessage) => {
  toast.error(errorMessage)
  dispatch({
    type: UserActionTypes.GET_USER_DETAILS_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getUserSuccess = (dispatch, data) => {
  dispatch({
    type: UserActionTypes.GET_USER_DETAILS_SUCCESS,
    payload: data,
  })
}

export const saveCard = (carDetails: Card, isMobile?: boolean) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.SAVE_CARD_DETAILS_START,
    })

    const url = saveCardDetailUrl()

    axiosInstance
      .post(url, carDetails)
      .then((res) => {
        saveCardSuccess(dispatch, res.data.data, isMobile)
      })
      .catch((error) => {
        saveCardFail(dispatch, "There was an error connection")
      })
  }
}

const saveCardFail = (dispatch, errorMessage) => {
  toast.error("Lo sentimos ha ocurrido un error")
  dispatch({
    type: UserActionTypes.SAVE_CARD_DETAILS_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const saveCardSuccess = (dispatch, data, isMobile) => {
  dispatch(getCardList())
  toast.success("Tarjeta añadida correctamente")
  dispatch({
    type: UserActionTypes.SAVE_CARD_DETAILS_SUCCESS,
    payload: data,
  })
  if (isMobile) {
    History.replace("cards-list")
  }
}

export const getCardList = () => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.GET_CARD_LIST_START,
    })

    const url = getCardListUrl()

    axiosInstance
      .get(url)
      .then((res) => {
        getCardListSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        getCardListFail(dispatch, "There was an error connection")
      })
  }
}

const getCardListFail = (dispatch, errorMessage) => {
  toast.error("Lo sentimos ha ocurrido un error")
  dispatch({
    type: UserActionTypes.GET_CARD_LIST_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getCardListSuccess = (dispatch, data) => {
  dispatch({
    type: UserActionTypes.GET_CARD_LIST_SUCCESS,
    payload: data,
  })
}

export const removeUser = () => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.DELETE_USER_DETAILS_SUCCESS,
    })
  }
}

export const updateUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.UPDATE_USER_START,
    })

    const url = getUpdateUserUrl()

    axiosInstance
      .put(url, data)
      .then((res) => {
        updateUserSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        updateUserFail(dispatch, "Lo sentimos ha ocurrido un error")
      })
  }
}

const updateUserFail = (dispatch, errorMessage) => {
  toast.error(errorMessage)
  dispatch({
    type: UserActionTypes.UPDATE_USER_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const updateUserSuccess = (dispatch, data) => {
  toast.success("Perfil actualizado con éxito")
  dispatch({
    type: UserActionTypes.UPDATE_USER_SUCCESS,
    payload: data,
  })
  let userId = localStorage.getItem("userId")
  let clientId = localStorage.getItem("clientId")
  if (userId) {
    dispatch(getUser(clientId))
  }
}
export const addUserPhone = (data) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.ADD_USER_PHONE_START,
    })

    const url = getUpdateUserUrl()

    axiosInstance
      .put(url, data)
      .then((res) => {
        addUserPhoneSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        addUserPhoneFail(dispatch, "Lo sentimos ha ocurrido un error")
      })
  }
}

const addUserPhoneFail = (dispatch, errorMessage) => {
  toast.error(errorMessage)
  dispatch({
    type: UserActionTypes.ADD_USER_PHONE_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const addUserPhoneSuccess = (dispatch, data) => {
  toast.success("Número de teléfono agregado correctamente")
  dispatch({
    type: UserActionTypes.ADD_USER_PHONE_SUCCESS,
    payload: data,
  })
  let userId = localStorage.getItem("userId")
  let clientId = localStorage.getItem("clientId")
  if (userId) {
    dispatch(getUser(clientId))
  }
}
export const changePassword = (password) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.CHANGE_PASSWORD_START,
    })

    const url = getChangePasswordUrl()
    let userId = localStorage.getItem("userId")
    axiosInstance
      .put(url, { userId, password })
      .then((res) => {
        changePasswordSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        changePasswordFail(dispatch, "Lo sentimos ha ocurrido un error")
      })
  }
}

const changePasswordFail = (dispatch, errorMessage) => {
  toast.error(errorMessage)
  dispatch({
    type: UserActionTypes.CHANGE_PASSWORD_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const changePasswordSuccess = (dispatch, data) => {
  toast.success("Contraseña cambiada con éxito")
  dispatch({
    type: UserActionTypes.CHANGE_PASSWORD_SUCCESS,
    payload: data,
  })
}

export const getOrdersList = () => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.GET_ORDERS_LIST_START,
    })
    const userId = localStorage.getItem("userId")
    const url = getOrderListUrl(userId)

    axiosInstance
      .get(url)
      .then((res) => {
        getOrderListSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        getOrderListFail(dispatch, "There was an error connection")
      })
  }
}

const getOrderListFail = (dispatch, errorMessage) => {
  toast.error("Lo sentimos ha ocurrido un error")
  dispatch({
    type: UserActionTypes.GET_USER_DETAILS_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getOrderListSuccess = (dispatch, data) => {
  dispatch({
    type: UserActionTypes.GET_ORDERS_LIST_SUCCESS,
    payload: data,
  })
}

export const getOrderDetail = (orderId) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.GET_ORDER_DETAIL_START,
    })
    const url = getOrderDetailUrl(orderId)

    axiosInstance
      .get(url)
      .then((res) => {
        getOrderDetailSuccess(dispatch, res.data.data)
      })
      .catch((error) => {
        getOrderDetailFail(dispatch, "There was an error connection")
      })
  }
}

const getOrderDetailFail = (dispatch, errorMessage) => {
  toast.error("Lo sentimos ha ocurrido un error")
  dispatch({
    type: UserActionTypes.GET_USER_DETAILS_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getOrderDetailSuccess = (dispatch, data) => {
  dispatch({
    type: UserActionTypes.GET_ORDER_DETAIL_SUCCESS,
    payload: data,
  })
}
export const orderCancel = (id, status) => {
  return (dispatch) => {
    dispatch({
      type: UserActionTypes.ORDER_CANCEL_START,
    })
    const url = getOrderCancelUrl()

    axiosInstance
      .put(url, { id, status })
      .then((res) => {
        orderCancelSuccess(dispatch, res.data.data, id)
      })
      .catch((error) => {
        orderCancelFail(dispatch, "There was an error connection")
      })
  }
}

const orderCancelFail = (dispatch, errorMessage) => {
  toast.error("Lo sentimos ha ocurrido un error")
  dispatch({
    type: UserActionTypes.ORDER_CANCEL_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const orderCancelSuccess = (dispatch, data, id) => {
  toast.success("Pedido cancelado con éxito")
  dispatch({
    type: UserActionTypes.ORDER_CANCEL_SUCCESS,
    payload: data,
  })
  dispatch(getOrderDetail(id))
}
export const setOrderId = (id) => ({
  type: UserActionTypes.SET_ORDER_ID,
  payload: id,
})
