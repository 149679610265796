import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { Card } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { useDispatch, useSelector } from "react-redux"

import Button from "@components/buttons/Button"
import MobileHeader from "@components/header/MobileHeader"
import { setUserAddress, getPositionFromAddress } from "@store/user/UserActions"
import CustomLoader from "@components/Loaders/CustomLoader"

interface StyleProps {
  typography?: string
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    boxShadow:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    borderRadius: "8px",
  }),
}))
const useTextFieldStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    "& .MuiInput-underline:after": {
      borderBottomColor: props.typography,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: props.typography,
    },
  }),
}))
function MobileAddressDetailsPage(props) {
  const history = useHistory()
  const restaurant = useSelector(({ venues }: any) => venues.restaurant)
  const { address, loading } = useSelector(({ user }: any) => user)
  const [typography, setTypography] = useState("rgb(202, 156, 77)")

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const dispatch = useDispatch()

  const [form, setForm] = useState<any>(address)

  useEffect(() => {
    setForm(address)
  }, [address])

  const handleChange = (e) => {
    const { target: input } = e
    let newForm = { ...form }
    newForm[input.name] = input.value
    setForm(newForm)
  }
  const handleSubmit = () => {
    dispatch(
      setUserAddress({
        ...address,
        address: form.address,
        city: form.city,
        postcode: form.postcode,
        details: form.details,
      }),
    )
    dispatch(
      getPositionFromAddress(
        { address: form.address, city: form.city, postcode: form.postcode },
        history,
        true,
      ),
    )
  }
  const canBeSubmit = () => {
    const { title, address, city, postcode } = form
    if (
      title &&
      title.length > 2 &&
      address &&
      address.length > 2 &&
      city &&
      city.length > 2 &&
      postcode &&
      postcode.length > 4
    ) {
      return false
    }
    return true
  }

  const styleProps: StyleProps = { typography }
  const Classes = useStyles(styleProps)
  const TextFieldClasses = useTextFieldStyles(styleProps)
  return (
    <CustomLoader loading={loading}>
      <MobileHeader
        title={"Introduce tu dirección"}
        typography={typography}
        onBack={() => history.goBack()}
        style={{ marginBottom: 0 }}
      >
        <div className={"flex w-11/12 self-center mt-48"}>
          <div className={"flex flex-col w-full"}>
            <Card
              className={[
                "flex flex-col bg-white md:p-16 p-8 pb-10",
                Classes.root,
              ].join(" ")}
            >
              <form className={"flex flex-col"} noValidate autoComplete="off">
                <TextField
                  className={TextFieldClasses.root}
                  id="address"
                  name="address"
                  label="Dirección"
                  value={form.address}
                  onChange={handleChange}
                  required
                />
                <TextField
                  className={TextFieldClasses.root}
                  style={{ marginTop: 10 }}
                  id="postcode"
                  name="postcode"
                  label="Còdigo Postal"
                  value={form.postcode}
                  // type="number"
                  onChange={handleChange}
                  required
                />
                <TextField
                  className={TextFieldClasses.root}
                  style={{ marginTop: 10 }}
                  id="city"
                  name="city"
                  label="Ciudad"
                  value={form.city}
                  onChange={handleChange}
                  required
                />
                <TextField
                  className={TextFieldClasses.root}
                  style={{ marginTop: 10 }}
                  id="details"
                  name="details"
                  label="Detalles Especials (opcional)"
                  value={form.details}
                  onChange={handleChange}
                  multiline
                  rows={2}
                  rowsMax={4}
                />
              </form>
            </Card>
            <Button
              title={"Añadir"}
              backgroundColor={typography}
              classes={
                "flex items-center justify-center self-center w-11/12 h-24 text-white py-2 px-4 border border-transparent cursor-pointer outline-none md:relative fixed mt-0 md:mt-10"
              }
              style={{
                borderRadius: "25px",
                fontSize: "14px",
                bottom: 20,
              }}
              onClick={handleSubmit}
              disabled={canBeSubmit()}
            />
          </div>
        </div>
      </MobileHeader>
    </CustomLoader>
  )
}

export default MobileAddressDetailsPage
