import React from "react"
import { Card } from "@material-ui/core"
import { Heading3 } from "@components/Text/Text"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"

export interface Props {
  venueName: string
  typography?: string
  icon: any
}

interface StyleProps {
  typography?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: () => ({
    boxShadow:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    borderRadius: "8px",
  }),
}))

const VenueCard = ({ venueName, typography, icon }: Props) => {
  const styleProps: StyleProps = { typography }
  const classes = useStyles(styleProps)
  return (
    <Card
      elevation={0}
      className={[
        "flex flex-row self-center items-center md:w-full w-11/12 h-32 mt-16 bg-white shadow-md rounded-4",
        classes.root,
      ].join()}
    >
      {icon}
      <Heading3>{venueName}</Heading3>
    </Card>
  )
}

export default VenueCard
