import React, { useState, useEffect } from "react"
import MobileProfile from "./MobileProfile/MobileProfilePage"
import DesktopProfile from "./DesktopProfile/DesktopProfilePage"

const ProfilePage = (props) => {
  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
  }, [])
  const isMobile = width <= 600
  return (
    <>
      {isMobile ? <MobileProfile {...props} /> : <DesktopProfile {...props} />}
    </>
  )
}

export default ProfilePage
