import React, { FC } from "react"

import { Home } from "@material-ui/icons"
import { Theme } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import { Heading2, Body14, Label19, Label14Bold } from "@components/Text/Text"
import { makeStyles } from "@material-ui/core/styles"
import { getCardImage } from "@store/cart/cartHelpers"
import { convertHex } from "@helpers/utilsHelper"
interface Props {
  title?: string
  onClick?: () => void
  description?: string
  brand?: string
  isSelected: boolean
  typography: string
  address?: boolean
  isValidAddress?: boolean
}

interface StyleProps {
  isSelected?: boolean
  typography: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: ({ isSelected, typography }) => ({
    boxShadow: !isSelected
      ? "0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)"
      : `${convertHex(
          typography,
          60,
        )} 0px 0px 15px 0px, rgba(250, 122, 13, 0) 0px 0px 0px 0px`,
  }),
}))

const AddressCard: FC<Props> = ({
  title,
  onClick,
  description,
  brand,
  isSelected,
  typography,
  address,
  isValidAddress,
}) => {
  const styleProps: StyleProps = { isSelected, typography }
  const Classes = useStyles(styleProps)

  return (
    <Card
      className={[
        "flex flex-col w-4/5 md:w-5/12 m-6 shadow-lg cursor-pointer p-32 mt-12 relative",
        Classes.root,
      ].join(" ")}
      onClick={onClick}
      key={title}
    >
      {address && !isValidAddress && (
        <Label14Bold
          className="text-red ml-2 text-center absolute"
          style={{ top: "15px", left: "25px" }}
        >
          {"Fuera de rango"}
        </Label14Bold>
      )}

      <div className={"flex flex-col w-full items-start"}>
        <div className="flex flex-row">
          {brand ? (
            <div className="flex items-center">
              <img className="mr-1" width="50" src={getCardImage(brand)}></img>
              <Label19 className="whitespace-no-wrap">{title}</Label19>
            </div>
          ) : (
            <>
              <Home
                className={"mx-4"}
                style={{ fontSize: 24, color: "rgb(105, 105, 105)" }}
              />
              <Heading2
                style={{ color: "rgb(105, 105, 105)" }}
                className={"m-0"}
              >
                {title}
              </Heading2>
            </>
          )}
        </div>

        {description && (
          <Body14
            className="w-full"
            style={{ textAlign: "center", color: "rgb(105, 105, 105)" }}
          >
            {description}
          </Body14>
        )}
      </div>
    </Card>
  )
}

export default AddressCard
