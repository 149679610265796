import React from "react"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"

interface Props {
  match?: any
}

const RedsysErrorPage = ({ match }: Props) => {
  const history = useHistory()
  {
  }
  useEffect(() => {
    history.push(`/menu/basket/${match.params.venueId}`)
  }, [])

  return <></>
}

export default RedsysErrorPage
