import React from "react"
import { Card, makeStyles } from "@material-ui/core"
import { Add } from "@material-ui/icons"

import { Body14 } from "@components/Text/Text"

interface Props {
  title: string
  onClick: () => void
}

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)",
  },
}))

const AddNewItemCard = ({ onClick, title }: Props) => {
  const Classes = useStyles()
  return (
    <Card
      onClick={onClick}
      className={[
        "flex w-4/5 md:w-5/12 items-center justify-center shadow-lg m-6  cursor-pointer p-32",
        Classes.root,
      ].join(" ")}
    >
      <Add style={{ fontSize: 50, color: "rgb(105, 105, 105)" }} />
      <Body14
        className="w-full"
        style={{ textAlign: "center", color: "rgb(105, 105, 105)" }}
      >
        {title}
      </Body14>
    </Card>
  )
}

export default AddNewItemCard
