import React, { useState } from "react"
import { Box, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"

import CustomModal from "@components/modals/CustomModal"
import Button from "@components/buttons/Button"
import { useDispatch } from "react-redux"
import { Heading3, Body16 } from "@components/Text/Text"
import { addUserPhone } from "@store/user/UserActions"
interface StyleProps {
  typography?: string
}
const AddPhoneNumberModal = ({ open, setOpen, typography, user }) => {
  const dispatch = useDispatch()
  const [form, setForm] = useState<any>(user)
  const canBeSubmit = () => {
    const { phone } = form
    if (phone && phone.length > 3) {
      return false
    }
    return true
  }

  const handleChange = (e) => {
    const { target: input } = e
    let newForm = { ...form }
    newForm[input.name] = input.value
    setForm(newForm)
  }
  const handleSubmit = () => {
    console.log("form", form)
    dispatch(addUserPhone(form))
    setOpen(false)
  }
  const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: (props) => ({
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      borderRadius: "8px",
    }),
  }))
  const useTextFieldStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: (props) => ({
      "& .MuiInput-underline:after": {
        borderBottomColor: props.typography,
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: props.typography,
      },
    }),
  }))
  const styleProps: StyleProps = { typography }
  const Classes = useStyles(styleProps)
  const TextFieldClasses = useTextFieldStyles(styleProps)
  return (
    <CustomModal showModal={open} onCloseModal={() => setOpen(false)}>
      <Box
        className={
          "flex flex-col items-center w-11/12 md:w-2/5 bg-white rounded p-8 md:py-16"
        }
      >
        <Box className={"w-11/12 flex flex-col p-4"}>
          <Heading3 className={"m-0 flex self-center"}>
            ¡Por favor añade tu teléfono!
          </Heading3>
          <Body16 className={"m-0 mt-5 text-gray-600"}>
            Déjanos tu teléfono para contactarte en relación con tu pedido
          </Body16>
          <div className={"flex flex-col"}>
            <TextField
              className={TextFieldClasses.root}
              style={{ marginTop: 10 }}
              id="phone"
              name="phone"
              label="Telefono"
              type="phone"
              value={form.phone}
              onChange={handleChange}
            />
            <Button
              title={"Continuar"}
              backgroundColor={typography}
              classes={
                "flex items-center justify-center self-center w-3/5 md:4/5 h-24 text-white py-2 px-4 border border-transparent cursor-pointer outline-none mt-5"
              }
              style={{
                borderRadius: "25px",
                fontSize: "14px",
              }}
              onClick={handleSubmit}
              disabled={canBeSubmit()}
            />
          </div>
        </Box>
      </Box>
    </CustomModal>
  )
}

export default AddPhoneNumberModal
