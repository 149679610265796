import React, { useRef } from "react"
import { Modal } from "@material-ui/core"
import { RemoveCircle, AddCircle } from "@material-ui/icons"
import { useDispatch, useSelector } from "react-redux"

import {
  addMultiItemCart,
  increaseNewItemCart,
  decreaseNewItemCart,
  addItemWithError,
} from "@store/cart/cartActions"
import PriceButton from "@components/buttons/PriceButton"
import CloseButton from "@components/buttons/CloseButton"
import { totalPriceCustomItem } from "@store/cart/cartHelpers"
import { Heading5, Body13 } from "@components/Text/Text"
import ExtraCategoriesMulti from "../mobile/components/ExtraCategoriesMulti"
import ExtraCategoriesUni from "../mobile/components/ExtraCategoriesUni"
import classes from "../modals.module.css"
import { formatImageUrl } from "@helpers/utilsHelper"

interface Props {
  item: MenuItem
  onClose: () => void
  typography: string
  isMobile?: boolean
}

const ExtraCategoriesModalRight = ({
  onClose,
  item,
  typography,
  isMobile,
}: Props) => {
  const { extraCategories, image, title, description } = item
  const dispatch = useDispatch()
  const modalRef = useRef<HTMLDivElement>(document.createElement("div"))

  const newItem = useSelector(({ cart }: RootState) => cart.newItem)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)

  let refs: any = []

  const { quantity } = newItem

  const executeScroll = (r?: any) => {
    if (r && r.offsetTop) {
      modalRef.current.scrollTo({ top: r.offsetTop, behavior: "smooth" })
    } else {
      modalRef.current.scrollTo(0, modalRef.current.offsetTop)
    }
  }

  const onSubmit = () => {
    let error = false
    //LOOK FOR ERRORS
    newItem.extraCategories?.map((item) => {
      if (item.hasError) {
        error = true
      }
    })

    if (error) {
      dispatch(addItemWithError())
    } else {
      dispatch(addMultiItemCart())
      onClose()
    }
  }
  const handleRefChange = (r, title) => {
    refs.push(r)
    executeScroll(refs[0])
  }
  return (
    <Modal
      className="flex justify-between items-center"
      open={true}
      onClose={onClose}
    >
      <div
        style={{ width: isMobile ? "90%" : "20rem" }}
        className={[classes.category_right, "outline-none relative"].join(" ")}
      >
        <div
          style={{
            maxHeight:
              restaurant.accessLevel && restaurant.accessLevel !== "1"
                ? "75%"
                : "100%",
          }}
          ref={modalRef}
          className={classes.modal_right}
        >
          <CloseButton
            margin
            onClick={onClose}
            style={{ marginRight: 15, position: "absolute" }}
            color={image ? "white" : "black"}
          />
          {image && (
            <div className={"flex w-full"} style={{ minHeight: "30%" }}>
              <img
                src={formatImageUrl(image)}
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )}
          <div
            className={[classes.info, "ml-2"].join(" ")}
            style={{ marginTop: image ? 0 : 15 }}
          >
            <Heading5>{title}</Heading5>
            <Body13>{description}</Body13>
          </div>

          {newItem.extraCategories &&
            newItem.extraCategories.map((extraCategory: ExtraCategory) => {
              if (extraCategory.type === "uniSelect") {
                return (
                  <div className={classes.extracategory_uni}>
                    <ExtraCategoriesUni
                      typography={typography}
                      item={extraCategory}
                      onRefChange={handleRefChange}
                    />
                  </div>
                )
              } else {
                return (
                  <div className={classes.extracategory_multi}>
                    <ExtraCategoriesMulti
                      typography={typography}
                      item={extraCategory}
                      onRefChange={handleRefChange}
                    />
                  </div>
                )
              }
            })}
        </div>
        {(venueDetail.collectionAvailable ||
          venueDetail.deliveryAvailable ||
          venueDetail.orderFromTableAvailable) && (
          <div className={classes.extraCategory_buttons}>
            <div className={classes.extraCategory_quantity}>
              <div className={classes.button_decrease}>
                <RemoveCircle
                  style={{ color: typography }}
                  onClick={() => dispatch(decreaseNewItemCart())}
                  className={classes.buttons}
                />
              </div>
              <div className={classes.quantity}>{quantity}</div>
              <div className={classes.button_increase}>
                <AddCircle
                  style={{ color: typography }}
                  onClick={() => dispatch(increaseNewItemCart())}
                  className={classes.buttons}
                />
              </div>
            </div>
            <div className="flex justify-center w-full">
              <PriceButton
                backgroundColor={typography}
                onClick={onSubmit}
                classes={classes.extraCategory_submit_button}
                title="Añadir al Pedido"
                price={(quantity * totalPriceCustomItem(newItem)).toFixed(2)}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ExtraCategoriesModalRight
