import { AuthActionTypes } from "@store/redux/actionTypes"

const INITIAL_STATE: AuthState = {
  loginStatus: false,
  error: "",
  signUpError: "",
  userId: "",
  loading: false,
  isValidToken:false
}

interface Action {
  payload: any
  type: string
}

const AuthReducer = (
  state: AuthState = INITIAL_STATE,
  action: Action,
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.USER_LOGIN_START: {
      return { ...state, loading: true, error: "", loginStatus: false }
    }
    case AuthActionTypes.USER_LOGIN_SUCCESS: {
      return {
        ...state,
        userId: action.payload._id,
        loginStatus: true,
        loading: false,
      }
    }
    case AuthActionTypes.USER_LOGIN_FAIL: {
      return {
        ...state,
        error: action.payload.errorMessage,
        loginStatus: false,
        loading: false,
      }
    }

    case AuthActionTypes.USER_REGISTER_START: {
      return { ...state, signUpError: "", loading: true }
    }
    case AuthActionTypes.USER_REGISTER_SUCCESS: {
      return {
        ...state,
        userId: action.payload._id,
        loading: false,
      }
    }
    case AuthActionTypes.USER_REGISTER_FAIL: {
      return {
        ...state,
        signUpError: action.payload.errorMessage,
        loading: false,
      }
    }
    case AuthActionTypes.USER_LOGOUT_START: {
      return { ...state, loading: true, error: "", loginStatus: false }
    }
    case AuthActionTypes.USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        userId: "",
      }
    }
    case AuthActionTypes.USER_LOGOUT_FAIL: {
      return {
        ...state,
        error: action.payload.errorMessage,
        loading: false,
      }
    }
    case AuthActionTypes.FORGOT_PASSWORD_START: {
      return { ...state, loading: true, error: "" }
    }
    case AuthActionTypes.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case AuthActionTypes.FORGOT_PASSWORD_FAIL: {
      return {
        ...state,
        error: action.payload.errorMessage,
        loading: false,
      }
    }
    case AuthActionTypes.RESET_PASSWORD_START: {
      return { ...state, loading: true };
    }
    case AuthActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.CHECK_VALID_TOKEN_START: {
      return { ...state, loading: true, isValidToken: false, userId: "" };
    }
    case AuthActionTypes.CHECK_VALID_TOKEN_SUCCESS: {
      return {
        ...state,
        userId: action.payload.userId,
        isValidToken: action.payload.isValidToken,
        loading: false,
      };
    }
    case AuthActionTypes.CHECK_VALID_TOKEN_FAIL: {
      return {
        ...state,
        isValidToken: false,
        loading: false,
      };
    }
    default: {
      return state
    }
  }
}

export default AuthReducer
