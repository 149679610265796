import React from "react"
import { ArrowForwardIos } from "@material-ui/icons"

import classes from "@components/tables/tableleft/tableLeft.module.css"
import { Label14Bold } from "@components/Text/Text"

export interface Props {
  title?: string
  typography?: string
  onClick?: any
  selected?: boolean
}

const EditProfileButton = ({ title, onClick, typography, selected }: Props) => {
  return (
    <li onClick={onClick}>
      <span className="flex">
        <Label14Bold style={{ color: selected ? "black" : "#999" }}>
          {title}
        </Label14Bold>
      </span>
      <span className={classes.arrow}>
        <ArrowForwardIos style={{ fontSize: "14px", color: typography }} />
      </span>
    </li>
  )
}

export default EditProfileButton
