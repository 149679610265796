import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe, Stripe } from "@stripe/stripe-js"

import classes from "./addressPage.module.css"
import Footer from "@components/footer/Footer"
import MainHeader from "@components/header/MainHeader"
import CustomLoader from "@components/Loaders/CustomLoader"
import CheckoutForm from "./components/CheckoutForm"

const AddressPage = (props) => {
  const restaurant = useSelector(({ venues }: any) => venues.restaurant)
  const userLoading = useSelector(({ user }: RootState) => user.loading)
  const authLoading = useSelector(({ auth }: RootState) => auth.loading)
  const venueLoading = useSelector(({ venues }: RootState) => venues.loading)
  const cartLoading = useSelector(({ cart }: RootState) => cart.loading)
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const { _id: userId } = useSelector(({ user }: RootState) => user.user)

  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const [width, setWidth] = useState(window.innerWidth)
  const [stripePromise] = useState<Promise<Stripe | null>>(
    loadStripe(restaurant.stripePublicKey),
  )

  const { venueId } = props.match.params
  const isMobile = width <= 600
  const loading = userLoading || venueLoading || cartLoading || authLoading

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
  }, [])

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  return (
    <CustomLoader loading={loading}>
      <div className={classes.container}>
        <MainHeader
          isMobile={isMobile}
          restaurant={restaurant}
          venueDetail={venueDetail}
          userId={userId}
          typography={typography}
          venueId={venueId}
          showLogin
        />
        {stripePromise && (
          <Elements stripe={stripePromise}>
            <CheckoutForm
              typography={typography}
              venueId={venueId}
              restaurant={restaurant}
            />
          </Elements>
        )}
        <div className={classes.footer}>
          <Footer
            facebook={
              venueDetail.contactInfo && venueDetail.contactInfo.facebook
            }
            instagram={
              venueDetail.contactInfo && venueDetail.contactInfo.instagram
            }
            twitter={venueDetail.contactInfo && venueDetail.contactInfo.twitter}
          />
        </div>
      </div>
    </CustomLoader>
  )
}

export default AddressPage
