import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import classes from "./homePage.module.css"
import VenueCard from "@components/VenueCard/VenueCard"
import {
  setSelectedOption,
  getClientDetail,
  emptyVenueDetail,
} from "@store/venues/VenueActions"
import { emptyCart, setSelectedAddress } from "@store/cart/cartActions"
import { removeUser } from "@store/user/UserActions"
import placeHolder from "@assets/Images/menuplaceholder.png"
import { getTable } from "@store/orderFromTable/OrderFromTableActions"
import { CartType } from "@models/Cart"
import { formatImageUrl } from "@helpers/utilsHelper"
import NotFoundPage from "@components/NotFoundPage"
import MenuModal from "@components/modals/MenuModal"

interface Props {
  match?: any
}

const HomePage = ({ match }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { permalink, tableId } = match.params

  const [open, setOpen] = React.useState(false)
  const [closeArrow, setCloseArrow] = React.useState(true)
  const [menusFiltered, setMenusFiltered] = React.useState([])
  const [venueSelected, setVenueSelected] = React.useState("")

  const venueList = useSelector(({ venues }: any) => venues.venueList)
  const restaurant = useSelector(({ venues }: any) => venues.restaurant)
  const menus = useSelector(({ menu }: any) => menu.menus)

  const orderFromTable = useSelector(
    ({ orderFromTable }: any) => orderFromTable.orderFromTable,
  )

  useEffect(() => {
    dispatch(setSelectedOption(""))
    if (tableId) {
      dispatch(setSelectedOption(CartType.ORDER_FROM_TABLE))
      dispatch(getTable(tableId))
    }

    if (!localStorage.getItem("userId")) {
      dispatch(removeUser())
    }
    dispatch(emptyCart())
    dispatch(emptyVenueDetail())
    dispatch(setSelectedAddress({}))
    dispatch(getClientDetail(permalink))
  }, [])

  useEffect(() => {
    if (
      tableId &&
      orderFromTable &&
      Object.keys(orderFromTable).length > 0 &&
      venueList &&
      venueList.length > 0 &&
      menus &&
      menus.length > 0
    ) {
      const menuFiltered = menus.filter((menu) => {
        return (
          menu.venues.includes(orderFromTable.venueId) &&
          menu.availableOnOrderFromTable === true
        )
      })
      setVenueSelected(orderFromTable.venueId)

      if (menuFiltered && menuFiltered.length === 1) {
        history.push(
          `/${permalink}/menu/${orderFromTable.venueId}/${menuFiltered[0]._id}`,
        )
      } else if (menuFiltered && menuFiltered.length > 1) {
        setCloseArrow(false)
        setMenusFiltered(menuFiltered)
        setOpen(true)
      } else {
        toast.error("No existe menus para este restaurante")
      }
    }
  }, [orderFromTable, venueList, menus])

  return (
    <div className={classes.container}>
      {Object.keys(restaurant).length > 0 ? (
        <>
          <div className={classes.Nav_tab}>
            <img
              className={[classes.nav_image, "object-contain"].join(" ")}
              src={
                restaurant.logo ? formatImageUrl(restaurant.logo) : placeHolder
              }
              alt={restaurant.title}
            />
          </div>
          <div className={classes.row}>
            <div className={classes.restaurants}>
              {venueList && venueList.length === 1 && tableId === undefined
                ? history.push(`/${permalink}/menu/${venueList[0]._id}`)
                : venueList.map((val) => (
                    <VenueCard
                      image={val.image ? val.image : ""}
                      title={val.title}
                      collectionAvailable={val.collectionAvailable}
                      deliveryAvailable={val.deliveryAvailable}
                      address={val.contactInfo ? val.contactInfo.address : ""}
                      onClick={(value) => {
                        value && dispatch(setSelectedOption(value))
                        setVenueSelected(val._id)
                        const menuFiltered = menus.filter((menu) => {
                          if (value == CartType.COLLECT) {
                            return (
                              menu.venues.includes(val._id) &&
                              menu.availableOnCollection === true
                            )
                          } else if (value == CartType.DELIVERY) {
                            return (
                              menu.venues.includes(val._id) &&
                              menu.availableOnDelivery === true
                            )
                          }
                        })

                        if (menuFiltered && menuFiltered.length === 1) {
                          history.push(
                            `/${permalink}/menu/${val._id}/${menuFiltered[0]._id}`,
                          )
                        } else if (menuFiltered && menuFiltered.length > 1) {
                          setCloseArrow(true)
                          setMenusFiltered(menuFiltered)
                          setOpen(true)
                        } else {
                          toast.error("No existe menus para este restaurante")
                        }
                      }}
                    />
                  ))}
            </div>
          </div>
          <MenuModal
            title={"Selecciona Menú"}
            open={open}
            menus={menusFiltered}
            close={closeArrow}
            onClose={() => setOpen(!open)}
            onButtonClick={(menuId) => {
              history.push(`/${permalink}/menu/${venueSelected}/${menuId}`)
            }}
          />
        </>
      ) : (
        <NotFoundPage />
      )}
    </div>
  )
}

export default HomePage
