import React, { useCallback } from "react"
import { PinDrop } from "@material-ui/icons"
import Button from "@material-ui/core/Button"
import { useDispatch, useSelector } from "react-redux"
import PersonIcon from "@material-ui/icons/Person"
import { useHistory } from "react-router-dom"

import placeHolder from "@assets/Images/menuplaceholder.png"
import { Heading1, Label15 } from "@components/Text/Text"
import classes from "@modules/venueDetails/venuedetails.module.css"
import { Venue } from "@models/Venue"
import { formatImageUrl, useTranslatedTextV2 } from "@helpers/utilsHelper"

import bgHeader from "@assets/backgroundimage.jpg"
import { LanguagesList } from "@components/tables/tableleft/LanguagesList"
import { setMenuLanguage } from "@store/menu/MenuActions"
import { q } from "./data"

interface Props {
  restaurant: Restaurant
  isMobile?: boolean
  typography?: string
  userId?: string
  venueDetail: Venue
  venueId: string
  showLogin?: boolean
  showLanguage?: boolean
  onLoginClick?: () => void
}

const MainHeader = ({
  restaurant,
  isMobile,
  typography,
  userId,
  venueDetail,
  venueId,
  showLogin,
  showLanguage,
  onLoginClick,
}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const _txt = useTranslatedTextV2({ index: 0, defaultTxt: "Acceder" })

  const handleProfile = () => {
    const pathname = window.location.pathname
    if (userId && pathname != "/profile") {
      history.push("/profile")
    }
  }
  const headerImage =
    restaurant && restaurant.settings && restaurant.settings.headerImage
      ? `url('${formatImageUrl(
          restaurant.settings.headerImage,
        )}') no-repeat center`
      : `url(${bgHeader})`

  return (
    <div
      style={{
        background: headerImage,
      }}
      className={classes.header}
    >
      <div
        style={{ backgroundColor: isMobile ? headerImage : "" }}
        className={classes.centered}
      >
        {/* <LanguagesList
          show={showLanguage}
          onlanguagechange={(ev, preLang, currentLang) => {
            ev.preventDefault()
            dispatch(setMenuLanguage(preLang, currentLang, q))
          }}
        /> */}
        {restaurant.accessLevel && restaurant.accessLevel !== "1" && showLogin && (
          <Button
            color="primary"
            style={{
              position: "absolute",
              top: "1rem",
              right: isMobile ? "0.2rem" : "1.5rem",
              color: "white",
            }}
            onClick={userId ? handleProfile : onLoginClick}
          >
            {userId ? <PersonIcon style={{ fontSize: 28 }} /> : _txt}
          </Button>
        )}

        <div className={classes.sub_content}>
          <div className={classes.image}>
            <img
              className="object-contain"
              src={
                restaurant.logo ? formatImageUrl(restaurant.logo) : placeHolder
              }
              alt={restaurant.title ? restaurant.title : ""}
            />
          </div>

          <Heading1>{venueDetail.title}</Heading1>

          <div
            className={classes.address}
            onClick={() =>
              venueDetail.contactInfo &&
              window.open(
                `https://maps.google.com/?q=${venueDetail.contactInfo.latitude},${venueDetail.contactInfo.longitude}`,
              )
            }
          >
            <Label15>
              <PinDrop />
              {venueDetail.contactInfo && venueDetail.contactInfo.address}
            </Label15>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainHeader
