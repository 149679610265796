import React from "react"
import classes from "./footer.module.css"
// import Button from "../buttons/Button"
// import cards from "../../assets/Images/cards.png"
import banzzuLogo from "@assets/Images/banzzu.png"
import {
  Facebook,
  Twitter,
  Instagram,
  Pinterest,
  YouTube,
} from "@material-ui/icons"

interface Props {
  facebook?: string
  twitter?: string
  pinterest?: string
  instagram?: string
  youtube?: string
}

const Footer = ({
  facebook,
  twitter,
  pinterest,
  instagram,
  youtube,
}: Props) => {
  return (
    <div className={classes.footer}>
      <div className={classes.footer_container}>
        {/* <div className={classes.footer_row}>
          <div className={classes.footer_item_large}>
            <h3>Secure payments with</h3>
            <p>
              <img src={cards} alt="" className="img-responsive" />
            </p>
          </div>
          <div className={classes.footer_item}>
            <h3>About</h3>
            <ul>
              <li>
                <a href="about.html">About us</a>
              </li>
              <li>
                <a href="faq.html">Faq</a>
              </li>
              <li>
                <a href="contacts.html">Contacts</a>
              </li>
              <li>
                <a href="#0" data-toggle="modal" data-target="#login_2">
                  Login
                </a>
              </li>
              <li>
                <a href="#0" data-toggle="modal" data-target="#register">
                  Register
                </a>
              </li>
              <li>
                <a href="#0">Terms and conditions</a>
              </li>
            </ul>
          </div>
          <div className={classes.footer_item} id="newsletter">
            <h3>Newsletter</h3>
            <p>
              Join our newsletter to keep be informed about offers and news.
            </p>
            <div id="message-newsletter_2"></div>
            <div className={classes.form_group}>
              <input
                name="email_newsletter_2"
                id="email_newsletter_2"
                type="email"
                placeholder="Your mail"
                className={classes.form_control}
              />
            </div>
            <Button classes={classes.footer_button} title="Subscribe" />
          </div>
          <div className={classes.footer_item_small}>
            <h3>Settings</h3>
            <div className={classes.styled_select}>
              <select className="form-control" name="lang" id="lang">
                <option value="English" selected>
                  English
                </option>
                <option value="French">French</option>
                <option value="Spanish">Spanish</option>
                <option value="Russian">Russian</option>
              </select>
            </div>
            <div className={classes.styled_select}>
              <select className="form-control" name="currency" id="currency">
                <option value="USD" selected>
                  USD
                </option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                <option value="RUB">RUB</option>
              </select>
            </div>
          </div>
        </div>
        <hr /> */}
        <div className={classes.footer_row}>
          <div className={classes.col_lg}>
            <div className={classes.social_footer}>
              <ul>
                {facebook && (
                  <li onClick={() => window.open(facebook)}>
                    <Facebook />
                  </li>
                )}
                {twitter && (
                  <li onClick={() => window.open(twitter)}>
                    <Twitter />
                  </li>
                )}
                {pinterest && (
                  <li onClick={() => window.open(pinterest)}>
                    <Pinterest />
                  </li>
                )}
                {instagram && (
                  <li onClick={() => window.open(instagram)}>
                    <Instagram />
                  </li>
                )}
                {youtube && (
                  <li onClick={() => window.open(instagram)}>
                    <YouTube />
                  </li>
                )}
              </ul>
              <img width="50" src={banzzuLogo} alt="Powered by Banzzu" />
              <p>© Banzzu {new Date().getFullYear()}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
