import React from "react"
import { Card } from "@material-ui/core"
import ListAltIcon from "@material-ui/icons/ListAlt"
import { Heading3 } from "@components/Text/Text"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"

export interface Props {
  typography?: string
  value?: string
  onValueChange?: (e: any) => void
}
interface StyleProps {
  typography?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: () => ({
    boxShadow:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    borderRadius: "8px",
  }),
}))

const useCardStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    "& .Mui-focused": {
      color: props.typography,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: props.typography,
    },
  }),
}))

const CommentsCard = ({ typography, value, onValueChange }: Props) => {
  const styleProps: StyleProps = { typography }
  const commentClasses = useCardStyles(styleProps)
  const classes = useStyles(styleProps)
  return (
    <Card
      elevation={0}
      className={[
        "flex flex-col self-center justify-start w-11/12  mt-16 bg-white shadow-md rounded-4",
        classes.root,
      ].join()}
    >
      <div className={"flex flex-row mt-8"}>
        <ListAltIcon style={{ color: typography }} className={"ml-4 mr-6"} />
        <Heading3 className={"font-700 m-0"}>Comentarios</Heading3>
      </div>
      <form
        className={"flex self-center w-11/12 mt-6 mb-2"}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="standard-basic"
          label="Comentarios"
          className={["w-full", commentClasses.root].join(" ")}
          onChange={onValueChange}
          value={value}
          multiline
          rowsMax={3}
        />
      </form>
    </Card>
  )
}

export default CommentsCard
