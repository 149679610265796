import React from "react"
import { Heading3, Label14 } from "@components/Text/Text"

export interface Props {
  total: number
  deliveryPrice: number
  selectedOption: string
}

const TopCard = ({ total, deliveryPrice, selectedOption }: Props) => {
  return (
    <div
      className={
        "flex flex-row justify-around bg-white items-center w-full h-48 shadow-md mt-32"
      }
    >
      <div className={"flex flex-col"}>
        <Label14 className={"text-center text-gray-800"}>N Orden</Label14>
        <Heading3 className="m-0">Pendiente</Heading3>
      </div>
      <div className={"w-px h-2text-gray-500"} />
      <div className={"flex flex-col"}>
        <Label14 className={"text-center text-gray-800"}>Total</Label14>
        <Heading3 className="m-0">
          {deliveryPrice > 0 && selectedOption && selectedOption == "2"
            ? (total + deliveryPrice).toFixed(2) + "€"
            : total.toFixed(2) + "€"}
        </Heading3>
      </div>
    </div>
  )
}

export default TopCard
