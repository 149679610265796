import React, { FC } from "react"
import { useSelector } from "react-redux"

import VenueModal from "../modals/VenueModal"
import { Heading5, Label17 } from "@components/Text/Text"
import { CartType } from "@models/Cart"
import { Card } from "@material-ui/core"

interface Props {
  title?: string
  image?: string
  onClick?: (e: any) => void
  address?: string
  collectionAvailable?: boolean
  deliveryAvailable: boolean
  orderFromTableAvailable?: boolean
}

const VenueCard: FC<Props> = ({
  title,
  image,
  onClick,
  address,
  collectionAvailable,
  deliveryAvailable,
}) => {
  const [open, setOpen] = React.useState(false)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const permission = useSelector(
    ({ venues }: RootState) => venues.restaurant.permission,
  )

  const toggleModal = () => {
    let orderType = ""
    if (collectionAvailable) {
      orderType = CartType.COLLECT
    }

    if (deliveryAvailable) {
      orderType = CartType.DELIVERY
    }

    collectionAvailable && deliveryAvailable
      ? setOpen(!open)
      : onClick && onClick(orderType)
  }

  const handleButtonClick = (value) => {
    onClick && onClick(value)
  }

  return (
    <div
      className="sm:w-1/2 md:w-1/3 w-full p-16"
      key={title}
      onClick={toggleModal}
    >
      <Card className="p-24 w-full items-center justify-center cursor-pointer text-center">
        <Heading5
          style={{ textAlign: "center", marginBottom: 8 }}
          className="text-center flex-1"
        >
          {title}
        </Heading5>
        <Label17 className="text-center justify-center text-grey-dark">
          {address}
        </Label17>
      </Card>
      <VenueModal
        title={"¿Cómo quieres tu pedido?"}
        open={open}
        onClose={toggleModal}
        onButtonClick={handleButtonClick}
      />
    </div>
  )
}

export default VenueCard
