import { CartType } from "@models/Cart"
import { store } from "@store/index"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

export function validateEmail(email: string) {
  const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  return re.test(email)
}

export function convertHex(hexCode, opacity) {
  var hex = hexCode.replace("#", "")

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }

  var r = parseInt(hex.substring(0, 2), 16),
    g = parseInt(hex.substring(2, 4), 16),
    b = parseInt(hex.substring(4, 6), 16)

  return "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")"
}
export function getAllergens(allergens) {
  let allergenList = []
  if (allergens) {
    let temp = allergens.split(",")
    if (temp && temp.length > 0)
      allergenList = temp.filter((item) => item != "")
  }
  return allergenList
}
export function formatImageUrl(imageUrl) {
  if (imageUrl) {
    let image = imageUrl
    image =
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? imageUrl.replace(
            "http://server.banzzu.com:8003/",
            "https://server.banzzu.com:8009/",
          )
        : imageUrl.replace(
            "http://server.banzzu.com:8002/",
            "https://server.banzzu.com:8008/",
          )

    return image
  }
  return imageUrl
}

export const randomString = (length) => {
  let result = ""
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWYXZ1234567890"
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
export const getSelectedOptionData = (value = "") => {
  const _storeData = store.getState()
  const {
    delivery = {},
    collection = {},
    orderFromTable = {},
  } = _storeData.venues.restaurant.settings || {}

  let paymentData = {}
  if (
    value === CartType.ORDER_FROM_TABLE &&
    orderFromTable &&
    orderFromTable.payByCard !== undefined &&
    orderFromTable.payByCash !== undefined
  ) {
    paymentData = orderFromTable
  } else if (
    value === CartType.COLLECT &&
    collection &&
    collection.payByCard !== undefined &&
    collection.payByCash !== undefined
  ) {
    paymentData = collection
  } else if (
    value === CartType.DELIVERY &&
    delivery &&
    delivery.payByCard !== undefined &&
    delivery.payByCash !== undefined
  ) {
    paymentData = delivery
  }
  return paymentData
}
export const detectLanguage = (index = 0) => {
  const _storeData = store.getState()
  const { selectedLanguages = [] } = _storeData.menu.menuList || {}
  let lang = "es"

  if (selectedLanguages[index] === "English") lang = "en"
  else if (selectedLanguages[index] === "Italian") lang = "it"
  else if (selectedLanguages[index] === "French") lang = "fr"
  else if (selectedLanguages[index] === "German") lang = "de"
  else if (selectedLanguages[index] === "Catalan") lang = "ca"
  return lang
}
export const detectFlag = (countryName = "Spanish") => {
  let lang = "es"

  if (countryName === "English") lang = "en"
  else if (countryName === "Italian") lang = "it"
  else if (countryName === "French") lang = "fr"
  else if (countryName === "German") lang = "de"
  else if (countryName === "Catalan") lang = "ca"
  return lang
}

interface Props {
  index: number
  defaultTxt: string
}

export const useTranslatedTextV2 = (props: Props) => {
  const { index = 0, defaultTxt = "" } = props
  const [transaltedText, setTranslatedText] = useState("")
  const { menuTranslation = [] } = useSelector(({ menu }: RootState) => menu)

  useEffect(() => {
    if (
      menuTranslation &&
      menuTranslation.length &&
      menuTranslation[index] &&
      menuTranslation[index].translatedText
    ) {
      setTranslatedText(menuTranslation[index].translatedText)
    } else {
      setTranslatedText(defaultTxt)
    }
  }, [menuTranslation, defaultTxt])

  return transaltedText
}
