import React from "react"
import Card from "@material-ui/core/Card"
import { makeStyles } from "@material-ui/core/styles"
import EventNoteIcon from "@material-ui/icons/EventNote"

import { Heading3 } from "@components/Text/Text"

interface Props {
  items: any
  typography?: string
  minOrderPrice: number
}

const MinOrderCard = ({ items, typography, minOrderPrice }) => {
  const useStyles = makeStyles(() => ({
    root: () => ({
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    }),
  }))
  const Classes = useStyles()
  return (
    <Card
      elevation={0}
      className={[
        "flex flex-row self-center items-center justify-start w-11/12 mt-16 bg-white shadow-2xl rounded-4",
        Classes.root,
      ].join()}
    >
      <EventNoteIcon
        style={{ color: typography, fontSize: 25, marginLeft: 6 }}
      />
      <Heading3 style={{ marginTop: 8, marginBottom: 8, marginLeft: 14 }}>
        Mínimo orden {minOrderPrice} €
      </Heading3>
    </Card>
  )
}

export default MinOrderCard
