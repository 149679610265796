import React, { useEffect } from "react"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"

interface Props {
  children: any
  showModal: boolean
  onCloseModal: () => void
}
export default function CustomModal({
  children,
  onCloseModal,
  showModal,
}: Props) {
  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={showModal}
      onClose={onCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal} style={{ outline: "none" }}>
        {children}
      </Fade>
    </Modal>
  )
}
