import React from "react"
import { AddCircle } from "@material-ui/icons"
import { useDispatch } from "react-redux"

import { ItemOrderMenu } from "@models/Cart"
import { hasFamilyPropertyItemSelected } from "@store/cart/cartHelpers"
import {
  increaseItemAmountOrderPage,
  decreaseItemAmountOrderPage,
} from "@store/cart/cartActions"
import ExtraCategoriesModalLeft from "./ExtraCategoriesModalLeft"
import classes from "../../menuItem.module.css"
import { Label17, Label14Light, Label15, Label10 } from "@components/Text/Text"

interface Props {
  item: ItemOrderMenu
  typography: string
}

const MenuItemsCartDetails = ({ item, typography }: Props) => {
  const { title, extraCategories, price, quantity, _id } = item
  const dispatch = useDispatch()
  return (
    <div
      className="flex flex-row justify-between"
      style={{
        backgroundColor: "rgb(247 247 247)",
        borderBottom: "1px solid rgb(224 224 224)",
      }}
    >
      <div className="flex flex-row">
        <ExtraCategoriesModalLeft
          typography={typography}
          padding
          quantity={quantity}
          handleClick={() => dispatch(decreaseItemAmountOrderPage({ item }))}
        />
        <div className="flex flex-col mt-2 ml-2 items-start pb-2">
          <Label17>{title}</Label17>
          {extraCategories &&
            extraCategories.length > 0 &&
            extraCategories.map(({ title, items }) => {
              if (hasFamilyPropertyItemSelected(items)) {
                return (
                  <>
                    <Label15 className="mt-6">{title}</Label15>
                    {items.map(({ title, selected }) => {
                      if (selected) {
                        return (
                          <Label14Light
                            className="mt-1"
                            style={{ color: "rgb(123 123 123)" }}
                          >
                            {title}
                          </Label14Light>
                        )
                      }
                      return null
                    })}
                  </>
                )
              }
              return null
            })}
        </div>
      </div>

      <div className="mt-2 mr-2 pb-2 flex flex-col justify-between items-end">
        <Label14Light>{price.toFixed(2)}€</Label14Light>
        <AddCircle
          style={{ color: typography }}
          className={classes.icon}
          onClick={() => dispatch(increaseItemAmountOrderPage(_id))}
        />
      </div>
    </div>
  )
}

export default MenuItemsCartDetails
