import { getOrderMenuByIdUrl } from "./../../@api/endPoints"
import { axiosInstance } from "@api/axios"

import { MenuActionTypes } from "@store/redux/actionTypes"
import { getAllMenuListUrl, getMenuListUrl } from "@api/endPoints"
import { getOrderMenuList } from "@store/cart/cartActions"

import Axios from "axios"
import { store } from "@store/index"

export const getAllMenuList = (clientId) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.GET_ALL_MENU_LIST_START,
    })

    const url = getAllMenuListUrl(clientId)

    axiosInstance
      .get(url)
      .then((res) => {
        getAllMenuListSuccess(dispatch, res.data)
        dispatch(getOrderMenuList(res.data))
      })
      .catch(() => {
        getAllMenuListFail(dispatch, "There was an error connection")
      })
  }
}

const getAllMenuListFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.GET_ALL_MENU_LIST_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getAllMenuListSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.GET_ALL_MENU_LIST_SUCCESS,
    payload: data,
  })
}

export const getMenuList = (id, type) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.GET_MENU_LIST_START,
    })

    const url = getMenuListUrl(id, type)

    axiosInstance
      .get(url)
      .then((res) => {
        getMenuListSuccess(dispatch, res.data)
        dispatch(getOrderMenuList(res.data))
      })
      .catch(() => {
        getMenuListFail(dispatch, "There was an error connection")
      })
  }
}

const getMenuListFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.GET_MENU_LIST_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getMenuListSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.GET_MENU_LIST_SUCCESS,
    payload: data,
  })
}

export const getMenuDetail = (id, type) => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.GET_MENU_DETAIL_START,
    })

    const url = getOrderMenuByIdUrl(id, type)

    axiosInstance
      .get(url)
      .then((res) => {
        getMenuDetailSuccess(dispatch, res.data)
        dispatch(getOrderMenuList(res.data))
      })
      .catch(() => {
        getMenuDetailFail(dispatch, "There was an error connection")
      })
  }
}

export const setMenuLanguage = (source, target, q) => {
  return (dispatch) => {
    let _txtArr: string[] = []
    const _storeData = store.getState()
    const { menuTranslation = [] } = _storeData.menu || {}
    menuTranslation &&
      menuTranslation.length &&
      menuTranslation.map((item) => {
        _txtArr.push(item.translatedText)
      })

    const query = {
      format: "text",
      source,
      target,
      q: _txtArr.length ? _txtArr : q,
    }

    Axios.post(
      "https://translation.googleapis.com/language/translate/v2?key=AIzaSyDD3BEbF_rZGtnnM6uITB1irLf4qc4kbgM",
      query,
    )
      .then((res) => {
        if (res.data && res.data.data && res.data.data.translations) {
          setMenuLanguageSuccess(dispatch, res.data.data.translations)
        }
      })
      .catch((err) => console.log({ err }))
  }
}

const getMenuDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.GET_MENU_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getMenuDetailSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.GET_MENU_DETAIL_SUCCESS,
    payload: data,
  })
}

export const getAllergensList = () => {
  return (dispatch) => {
    dispatch({
      type: MenuActionTypes.GET_MENU_LIST_START,
    })

    const url = "/web/allergenes"

    axiosInstance
      .get(url)
      .then((res) => {
        getAllergensListSuccess(dispatch, res.data)
      })
      .catch(() => {
        getAllergensListFail(dispatch, "There was an error connection")
      })
  }
}

const getAllergensListFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuActionTypes.GET_ALLERGENS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  })
}

const getAllergensListSuccess = (dispatch, data) => {
  dispatch({
    type: MenuActionTypes.GET_ALLERGENS_LIST_SUCCESS,
    payload: data,
  })
}
const setMenuLanguageSuccess = (dispatch, data = []) => {
  dispatch({
    type: MenuActionTypes.MENU_LANGUAGE_TRANSLATION_SUCCESS,
    payload: data,
  })
}
