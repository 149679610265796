import React, { useEffect, useMemo, useState, useRef, useCallback } from "react"
import throttle from "lodash/throttle"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { Theme } from "@material-ui/core"

interface StyleProps {
  typography?: string
}

interface StyleTabsProps {
  value?: string
}

interface StyleTabProps {
  label?: string
  onClick?: any
  value?: any
}

const tabHeight = 39
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      width: "100%",
      backgroundColor: "#fff",
    },
  },
})((props: StyleTabsProps) => (
  <Tabs
    variant="scrollable"
    scrollButtons="auto"
    {...props}
    TabIndicatorProps={{ children: <div /> }}
  />
))

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "white",
    height: tabHeight,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props: StyleTabProps) => <Tab disableRipple {...props} />)

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    flexGrow: 1,
  },
  indicator: {
    // padding: theme.spacing(1)
  },
  demo2: ({ typography }) => ({
    backgroundColor: typography,
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
    zIndex: 100,
  }),
}))

const makeUnique = (hash, unique, i = 1) => {
  const uniqueHash = i === 1 ? hash : `${hash}-${i}`

  if (!unique[uniqueHash]) {
    unique[uniqueHash] = true
    return uniqueHash
  }

  return makeUnique(hash, unique, i + 1)
}

const textToHash = (text, unique = {}) => {
  return makeUnique(
    encodeURI(
      text
        .toLowerCase()
        .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, "")
        // eslint-disable-next-line no-useless-escape
        .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, "-")
        .replace(/-+/g, "-")
        .replace(/^-|-$/g, ""),
    ),
    unique,
  )
}
const noop = () => {}

const useThrottledOnScroll = (callback, delay) => {
  const throttledCallback = useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay],
  )

  useEffect(() => {
    if (throttledCallback === noop) return undefined

    window.addEventListener("scroll", throttledCallback)
    return () => {
      window.removeEventListener("scroll", throttledCallback)
      throttledCallback.cancel()
    }
  }, [throttledCallback])
}

const ScrollSpyTabs = (props) => {
  const [activeState, setActiveState] = useState(null)
  const { tabsInScroll, typography } = props

  const [y, setY] = useState(window.scrollY)

  const [scrollPosition, setScrollPosition] = useState("")

  let itemsServer = tabsInScroll.map((tab) => {
    const hash = textToHash(tab.text)
    return {
      icon: tab.icon || "",
      text: tab.text,
      component: tab.component,
      hash: hash,
      node: document.getElementById(hash),
    }
  })

  const itemsClientRef = useRef([])
  useEffect(() => {
    itemsClientRef.current = itemsServer
  }, [itemsServer])

  const clickedRef = useRef(false)
  const unsetClickedRef = useRef<any>(null)
  const findActiveIndex = useCallback(() => {
    // set default if activeState is null
    if (activeState === null) setActiveState(itemsServer[0].hash)

    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) return

    let active
    for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 0) {
        active = { hash: null }
        break
      }

      const item: any = itemsClientRef.current[i]

      let scrollFrom =
        scrollPosition == "down"
          ? document.documentElement.clientHeight / 1.5 + tabHeight + 20
          : document.documentElement.clientHeight / 5 + tabHeight + 20
      if (
        item.node &&
        item.node.offsetTop < document.documentElement.scrollTop + scrollFrom
      ) {
        active = item
        break
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash)
    }
  }, [activeState, itemsServer])

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(itemsServer.length > 0 ? findActiveIndex : null, 166)

  const handleClick = (hash) => () => {
    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false
    }, 1000)
    let obj = document.getElementById(hash)
    if (activeState !== hash && obj) {
      setActiveState(hash)
      if (window)
        window.scrollTo({
          top: obj.getBoundingClientRect().top + window.pageYOffset - 50,
          behavior: "smooth",
        })
    }
  }

  useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current)
    },
    [],
  )
  const styleProps: StyleProps = { typography }
  const classes = useStyles(styleProps)

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget
      if (y > window.scrollY) {
        setScrollPosition("scrolling up")
      } else if (y < window.scrollY) {
        setScrollPosition("down")
      }
      setY(window.scrollY)
    },
    [y],
  )

  useEffect(() => {
    setY(window.scrollY)
    window.addEventListener("scroll", handleNavigation)

    return () => {
      window.removeEventListener("scroll", handleNavigation)
    }
  }, [handleNavigation])

  return (
    <div>
      <nav className={classes.demo2}>
        <StyledTabs value={activeState ? activeState : itemsServer[0].hash}>
          {itemsServer.map((item2) => (
            <StyledTab
              key={item2.hash}
              label={item2.text}
              onClick={() => handleClick(item2.hash)()}
              value={item2.hash}
            />
          ))}
        </StyledTabs>
        <div className={classes.indicator} />
      </nav>

      <div className="container">
        {itemsServer.map((item1) => (
          <article id={item1.hash} key={item1.text}>
            {item1.component}
          </article>
        ))}
      </div>
    </div>
  )
}

export default ScrollSpyTabs
