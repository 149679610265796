import { combineReducers } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import venues from "@store/venues/VenueReducer"
import menu from "@store/menu/MenuReducer"
import cart from "@store/cart/cartReducer"
import auth from "@store/auth/AuthReducer"
import user from "@store/user/UserReducer"
import orderFromTable from "@store/orderFromTable/OrderFromTableReducer"

const venuesPersistConfig = {
  key: "venues",
  storage,
  whitelist: ["venueDetail", "restaurant", "selectedOption", "orderFromTable"],
}

const cartPersistConfig = {
  key: "cart",
  storage,
  whitelist: [
    "cart",
    "selectedCard",
    "address",
    "secret",
    "paymentId",
    "discountCode",
    "discountType",
    "discountAmount",
    "discountApplicable",
    "discountVoucherId",
  ],
}

const userPersistConfig = {
  key: "user",
  storage,
  whitelist: ["user", "orderId"],
}
const orderFromTablePersistConfig = {
  key: "orderFromTable",
  storage,
  whitelist: ["orderFromTable"],
}

const createReducer = (asyncReducers) => {
  const rootReducer = combineReducers({
    venues: persistReducer(venuesPersistConfig, venues),
    menu,
    cart: persistReducer(cartPersistConfig, cart),
    auth,
    user: persistReducer(userPersistConfig, user),
    orderFromTable: persistReducer(orderFromTablePersistConfig, orderFromTable),
    ...asyncReducers,
  })
  return rootReducer
}

export default createReducer
