import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { setItemSelected, addItemInCart } from "@store/cart/cartActions"
import MobileCenter from "@components/tables/tablecenter/components/MobileCenter"
import DesktopCenter from "./components/DesktopCenter"
import classes from "./tableCenter.module.css"
import { Heading2 } from "@components/Text/Text"
import { useTranslatedTextV2 } from "@helpers/utilsHelper"

interface Props {
  tabValue: number
  category: any
  scrollToRef: any
  isMobile: boolean
  onClick?: any
}

const TableCenter = ({
  category,
  tabValue,
  scrollToRef,
  isMobile,
  onClick,
}: Props) => {
  const dispatch = useDispatch()
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)

  const menuText = useTranslatedTextV2({
    index: 14,
    defaultTxt: `Menú`,
  })

  const [typography, setTypography] = useState("rgb(202, 156, 77)")

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const handleMenuItemClick = (item) => {
    const connectedItem = item.extraCategories?.find(
      (category) => category.connectedCategory,
    )
    if (connectedItem !== undefined) {
      const newItem = item.extraCategories?.find(
        (category) => category._id === connectedItem.connectedCategory,
      )
      let selectedItem = newItem?.items.find((item) => item.selected)
      if (!selectedItem) {
        selectedItem = newItem?.items[0]
      }
      const itemExtraCategories = item.extraCategories?.map((category) => {
        if (category.connectedCategory) {
          const newCatItem = {
            ...category,
            items: category.items.filter(
              (item) => item.connectedCategoryItem === selectedItem._id,
            ),
          }
          return newCatItem
        }
        return category
      })
      const finalItem = {
        ...item,
        extraCategories: itemExtraCategories,
      }

      const payload = {
        item: finalItem,
      }
      dispatch(setItemSelected(payload))
    } else {
      const payload = {
        item,
      }

      if (item.extraCategories.length > 0) {
        dispatch(setItemSelected(payload))
      } else {
        dispatch(addItemInCart(payload))
      }
    }
  }

  return (
    <div className={classes.centercontainer}>
      <div style={{ backgroundColor: typography }} className={classes.header}>
        <Heading2 className="text-white">{menuText}</Heading2>
      </div>
      <div className={[classes.tablebody, isMobile && "mb-48"].join(" ")}>
        {isMobile ? (
          <MobileCenter
            typography={typography}
            category={category}
            tabValue={tabValue}
            handleMenuItemClick={handleMenuItemClick}
            onClick={onClick}
          />
        ) : (
          <DesktopCenter
            tabValue={tabValue}
            category={category}
            scrollToRef={scrollToRef}
            handleMenuItemClick={handleMenuItemClick}
          />
        )}
      </div>
    </div>
  )
}

export default TableCenter
