import React from "react"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import { useHistory } from "react-router-dom"

import { Heading2, Heading5 } from "@components/Text/Text"

interface Props {
  onBack?: () => void
  title: string
  typography: string
  children: any
  rightButtonPress?: () => void
  rightButton?: boolean
  rightTitle?: string
  style?: any
}

const MobileHeader = ({
  onBack,
  title,
  typography,
  children,
  rightTitle,
  rightButton,
  rightButtonPress,
  style,
}: Props) => {
  const history = useHistory()

  const onBackPressed = () => {
    onBack ? onBack() : history.goBack()
  }
  return (
    <div
      className={"flex flex-col w-full bg-grey-lighter mb-40 min-h-screen"}
      style={style}
    >
      <div
        className={"flex justify-center items-center w-full h-36 fixed z-10"}
        style={{ backgroundColor: typography }}
      >
        <ArrowBackIosIcon
          style={{
            color: "white",
            fontSize: 32,
            left: "1rem",
          }}
          onClick={onBackPressed}
          className={"absolute"}
        />
        <Heading2 className={"text-white self-center"}>{title}</Heading2>
        {rightButton && (
          <Heading5
            style={{
              right: "1rem",
            }}
            className={"text-white absolute"}
            onClick={rightButtonPress}
          >
            {rightTitle}
          </Heading5>
        )}
      </div>
      {children}
    </div>
  )
}

export default MobileHeader
