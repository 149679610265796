import React from "react"
import { useSelector, useDispatch } from "react-redux"

import PriceButton from "@components/buttons/PriceButton"
import { cartCounter } from "@store/cart/cartHelpers"
import MobileCategoryItem from "./MobileCategoryItem"
import ScrollSpyTabs from "@components/Tabs/ScrolleSpyTabs"
import { useTranslatedTextV2 } from "@helpers/utilsHelper"

interface Props {
  category: any
  tabValue: number
  typography: string
  handleMenuItemClick: (item: any) => void
  onClick?: any
  scrollToRef?: (ref: any) => void
  setTabValue?: any
}

const MobileCenter = ({
  category,
  tabValue,
  handleMenuItemClick,
  typography,
  onClick,
  scrollToRef,
  setTabValue,
}: Props) => {
  const _txt = useTranslatedTextV2({ index: 15, defaultTxt: "Ver Pedido" })
  const cartItems = useSelector(({ cart }: RootState) => cart.cart.items)
  const dispatch = useDispatch()
  let content
  if (category) {
    const { totalItems, totalPrice } = cartCounter(cartItems)

    content = (
      <>
        <ScrollSpyTabs
          tabsInScroll={category.map((cat) => {
            return {
              text: cat.title,
              component: (
                <div>
                  <MobileCategoryItem
                    val={cat}
                    categoryTitle={cat.title}
                    handleMenuItemClick={handleMenuItemClick}
                    cartItems={cartItems}
                  />
                </div>
              ),
            }
          })}
          typography={typography}
        />

        {totalItems > 0 && (
          <div className="flex justify-center">
            <div
              className="fixed bottom-2 px-8 w-full"
              style={{ bottom: "2rem" }}
            >
              <PriceButton
                backgroundColor={typography}
                title={_txt}
                price={totalPrice}
                quantity={totalItems}
                onClick={onClick}
              />
            </div>
          </div>
        )}
      </>
    )
  } else {
    content = null
  }

  return content
}

export default MobileCenter
