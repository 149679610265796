import React, { FC, useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  makeStyles,
  Theme,
} from "@material-ui/core"
import clsx from "clsx"
import { Error } from "@material-ui/icons"

import { setUniProperty } from "@store/cart/cartActions"
import { Heading5, Body13 } from "@components/Text/Text"
import { formatImageUrl } from "@helpers/utilsHelper"
interface Props {
  item: ExtraCategory
  typography: string
  onRefChange?: any
}

interface StyleProps {
  typography?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    textAlign: "left",
    backgroundColor: "#dedede57",
    alignItems: "center",
    padding: "1rem",
  },
  rootForm: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 0,
  },
  checked: (props) => ({
    color: `${props.typography} !important`,
  }),
  focused: {
    color: "black !important",
  },
}))

const ExtraCategoriesUni: FC<Props> = ({ item, typography, onRefChange }) => {
  const { title, description: itemDescription } = item
  const dispatch = useDispatch()

  const styleProps: StyleProps = { typography }
  const classes = useStyles(styleProps)

  const sendTried = useSelector(({ cart }: RootState) => cart.newItem.sendTried)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)

  const [familyProperty, setFamilyProperty] = useState(item)
  const [selectedItem, setSelectedItem] = useState<string | null>(null)

  useEffect(() => {
    setFamilyProperty(item)
  }, [item])

  const updateMenuExtraCategoryItem = (id) => {
    setSelectedItem(id)

    const newFamilyItems: ExtraCategoryItem[] = familyProperty.items.map(
      (item) => {
        if (item._id === id) {
          return {
            ...item,
            selected: item.selected ? item.selected : !item.selected,
          }
        }
        return { ...item, selected: false }
      },
    )
    const amountSelected = newFamilyItems.filter((item) => item.selected).length
    const familyPropertyUpdated = {
      ...familyProperty,
      hasError: amountSelected === 0,
      items: newFamilyItems,
    }
    let payload = {
      extraCategory: familyPropertyUpdated,
    }
    setFamilyProperty(familyPropertyUpdated)
    dispatch(setUniProperty(payload))
  }

  useEffect(() => {
    familyProperty.items?.map((item: ExtraCategoryItem) => {
      if (item.selected) {
        updateMenuExtraCategoryItem(item._id)
      }

      return item
    })
  }, [])

  return (
    <>
      <FormControl className="w-full mx-12" component="fieldset">
        <div
          className={classes.root}
          ref={(ref) => {
            if (item.hasError && sendTried && ref) {
              onRefChange(ref, title)
            }
          }}
          id={item._id}
        >
          <Heading5
            className={clsx(
              "w-full flex flex-row self-start items-center",
              item.hasError && sendTried && "text-red",
            )}
          >
            {item.hasError && sendTried && <Error className="text-red mr-2" />}
            {title}
          </Heading5>
          <Body13>{itemDescription}</Body13>
        </div>
        {restaurant.accessLevel && restaurant.accessLevel !== "1" ? (
          <RadioGroup
            className="mx-12"
            value={selectedItem}
            onChange={(e) => updateMenuExtraCategoryItem(e.target.value)}
          >
            {familyProperty.items?.map((item: ExtraCategoryItem, index) => (
              <div className="flex flex-row justify-between items-center">
                <FormControlLabel
                  className="text-xs w-full"
                  value={item._id}
                  classes={{ root: classes.rootForm }}
                  labelPlacement="start"
                  control={<Radio classes={{ checked: classes.checked }} />}
                  label={
                    <div className={"flex items-center"}>
                      {item.image && (
                        <div className={"w-10 h-10 mt-2"}>
                          <img
                            src={formatImageUrl(item.image)}
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                              objectFit: "cover",
                              borderRadius: 4,
                            }}
                          />
                        </div>
                      )}
                      <Body13 style={{ marginLeft: 8, marginBottom: 8 }}>
                        {item.title}
                        {item.price && Number(item.price) > 0 ? (
                          <span className="pl-4" style={{ color: "grey" }}>
                            + {item.price}€
                          </span>
                        ) : null}
                      </Body13>
                    </div>
                  }
                />
              </div>
            ))}
          </RadioGroup>
        ) : (
          familyProperty.items?.map((item: ExtraCategoryItem, index) => (
            <Body13
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                marginLeft: "1.2rem",
              }}
            >
              {item.title}
              {item.price && Number(item.price) > 0 ? (
                <span className="pl-4" style={{ color: "grey" }}>
                  + {item.price}€
                </span>
              ) : null}
            </Body13>
          ))
        )}
      </FormControl>
    </>
  )
}

export default ExtraCategoriesUni
