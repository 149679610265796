import React from "react"
import { Box, Typography } from "@material-ui/core"

import { CancelOutlined } from "@material-ui/icons"
import CustomModal from "@components/modals/CustomModal"
import Button from "@components/buttons/Button"
import { getPositionFromAddress } from "@store/user/UserActions"
import { useDispatch } from "react-redux"

const AddressDetailModal = ({
  open,
  setOpen,
  onChange,
  form,
  typography,
  venueId,
  setOpenMap,
}) => {
  const dispatch = useDispatch()
  const { title, city, address, postcode } = form
  const canBeSubmit = () => {
    if (title && city && address && postcode && postcode.length > 4) {
      return false
    } else return true
  }

  const handleSubmit = () => {
    setOpen(false)
    dispatch(getPositionFromAddress({ address, city, postcode }))
    setTimeout(() => {
      setOpenMap(true)
    }, 1000)
  }
  return (
    <CustomModal showModal={open} onCloseModal={() => setOpen(false)}>
      <Box className={"flex flex-col w-11/12 md:w-2/5 bg-white"}>
        <Box
          className={
            "flex w-full flex-row justify-between items-center m-8 pl-2 pr-12"
          }
        >
          <Typography variant="h5" className={"m-0"}>
            Dirección de envío
          </Typography>
          <CancelOutlined className={"mr-2"} onClick={() => setOpen(false)} />
        </Box>
        <Box className={"w-full h-px bg-gray-400"}></Box>
        <Box className={"w-11/12 flex flex-col self-center"}>
          <label className={"mt-2 -mb-2"}>Nombre</label>
          <input
            className={
              "w-full h-24 border mt-6 md:mt-8 border-gray-500 border-solid rounded-sm pl-2 shadow-none text-gray-600 outline-none"
            }
            type="text"
            placeholder=" Casa, Oficina..."
            name="title"
            value={form.title}
            onChange={onChange}
          />
          <label className={"mt-2 -mb-4"}>Calle</label>
          <input
            className={
              "w-full h-24 border mt-6 md:mt-8 border-gray-500 border-solid rounded-sm pl-2 shadow-none text-gray-600 outline-none"
            }
            type="text"
            placeholder="Plaza Mayor n13"
            name="address"
            value={form.address}
            onChange={onChange}
          />
          <label className={"mt-2 -mb-4"}>Código Postal</label>
          <input
            className={
              "w-full h-24 border mt-6 md:mt-8 border-gray-500 border-solid rounded-sm pl-2 shadow-none text-gray-600 outline-none"
            }
            type="text"
            name="postcode"
            max="10"
            placeholder="41567"
            value={form.postcode}
            onChange={onChange}
          />
          <label className={"mt-2 -mb-4"}>Ciudad</label>
          <input
            className={
              "w-full h-24 border mt-6 md:mt-8 border-gray-500 border-solid rounded-sm pl-2 shadow-none text-gray-600 outline-none"
            }
            type="text"
            placeholder="Madrid"
            name="city"
            value={form.city}
            onChange={onChange}
          />
          <label className={"mt-2 -mb-2"}>Comentarios</label>
          <textarea
            className={
              "w-full h-48 border mt-6 md:mt-8 border-gray-500 border-solid rounded-sm pl-2 shadow-none text-gray-600 outline-none"
            }
            placeholder="Para acceder pulse la campana roja"
            name="details"
            value={form.details}
            onChange={onChange}
          />
          <Box
            className={
              "flex w-full flex-row justify-between items-center mt-6 mb-8"
            }
          >
            <Button
              title={"Cancelar"}
              backgroundColor={typography}
              style={{
                borderRadius: "4px",
                fontSize: "14px",
                width: "48%",
              }}
              classes={
                "w-4/5 h-24 font-semibold text-white py-2 px-4 border border-transparent outline-none"
              }
              onClick={() => setOpen(false)}
            />
            <Button
              title={"Siguiente"}
              backgroundColor={typography}
              style={{
                borderRadius: "4px",
                fontSize: "14px",
                width: "48%",
              }}
              classes={
                "w-4/5 h-24 font-semibold text-white py-2 px-4 border border-transparent outline-none"
              }
              disabled={canBeSubmit()}
              onClick={handleSubmit}
            />
          </Box>
        </Box>
      </Box>
    </CustomModal>
  )
}

export default AddressDetailModal
