import React, { useState } from "react"
import { Modal } from "@material-ui/core"
import { RemoveCircle, AddCircle } from "@material-ui/icons"
import { useDispatch, useSelector } from "react-redux"

import CloseButton from "@components/buttons/CloseButton"
import { addItemInCart } from "@store/cart/cartActions"
import PriceButton from "@components/buttons/PriceButton"
import { totalPriceCustomItem } from "@store/cart/cartHelpers"
import classes from "../modals.module.css"
import { Heading5, Body13 } from "@components/Text/Text"
import { formatImageUrl } from "@helpers/utilsHelper"
interface Props {
  item: MenuItem
  onClose: () => void
  typography: string
  isMobile?: boolean
}

const ItemDetailModal = ({ onClose, item, typography, isMobile }: Props) => {
  const { image, title, description } = item
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState(1)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: RootState) => venues.venueDetail)

  const onSubmit = () => {
    const payload = {
      item: {
        ...item,
        quantity,
      },
    }

    dispatch(addItemInCart(payload))
    onClose()
  }

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  return (
    <Modal
      className="flex justify-between items-center"
      open={true}
      onClose={onClose}
    >
      <div
        style={{ width: isMobile ? "90%" : "20rem" }}
        className="outline-none mx-12 relative"
      >
        <CloseButton
          onClick={onClose}
          style={{ marginRight: image ? 0 : 10 }}
          color={image ? "white" : "black"}
        />
        <div className={classes.modal_right}>
          {image && (
            <div
              className="w-full"
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)),url("${formatImageUrl(
                  image,
                )}")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
                height: 200,
              }}
            ></div>
          )}
          <div className={classes.info} style={{ marginTop: image ? 0 : 20 }}>
            <Heading5>{title}</Heading5>
            <Body13>{description}</Body13>
          </div>
        </div>
        {(venueDetail.collectionAvailable ||
          venueDetail.deliveryAvailable ||
          venueDetail.orderFromTableAvailable) && (
          <div className={classes.extraCategory_buttons}>
            <div className={classes.extraCategoryQuantity}>
              <div className={classes.button_decrease}>
                <RemoveCircle
                  style={{ color: typography }}
                  onClick={decreaseQuantity}
                  className={classes.buttons}
                />
              </div>
              <div className={classes.quantity}>{quantity}</div>
              <div className={classes.button_increase}>
                <AddCircle
                  style={{ color: typography }}
                  onClick={() => setQuantity(quantity + 1)}
                  className={classes.buttons}
                />
              </div>
            </div>
            <div className="flex justify-center w-full">
              <PriceButton
                backgroundColor={typography}
                onClick={onSubmit}
                classes={classes.extraCategory_submit_button}
                title="Añadir al Pedido"
                price={(quantity * totalPriceCustomItem(item)).toFixed(2)}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ItemDetailModal
