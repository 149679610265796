import { VenueActionTypes } from "@store/redux/actionTypes"
import { UserSettings } from "@models/UserSettings"
const INITIAL_STATE: VenueState = {
  venueList: [],
  venueDetail: {},
  restaurant: {},
  loading: false,
  selectedOption: "",
  collectionAvailability: false,
  deliveryAvailability: false,
}

interface Action {
  payload: any
  type: string
}

const VenueReducer = (
  state: VenueState = INITIAL_STATE,
  action: Action,
): VenueState => {
  switch (action.type) {
    case VenueActionTypes.SET_SELECT_OPTION: {
      return { ...state, selectedOption: action.payload }
    }

    case VenueActionTypes.GET_VENUE_LIST_START: {
      return { ...state, loading: true, venueList: [] }
    }
    case VenueActionTypes.GET_VENUE_LIST_SUCCESS: {
      return {
        ...state,
        venueList: action.payload,
        loading: false,
      }
    }
    case VenueActionTypes.GET_VENUE_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }

    case VenueActionTypes.GET_VENUE_DETAIL_START: {
      return { ...state, loading: true }
    }
    case VenueActionTypes.GET_VENUE_DETAIL_SUCCESS: {
      return {
        ...state,
        venueDetail: action.payload,
        loading: false,
      }
    }
    case VenueActionTypes.GET_VENUE_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }

    case VenueActionTypes.GET_CLIENT_DETAIL_START: {
      return { ...state, loading: true }
    }
    case VenueActionTypes.GET_CLIENT_DETAIL_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        restaurant: data,
        loading: false,
      }
    }
    case VenueActionTypes.GET_CLIENT_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
        restaurant: {},
      }
    }
    case VenueActionTypes.CHECK_COLLECTION_AVAILABILITY_START: {
      return { ...state, loading: true, collectionAvailability: false }
    }
    case VenueActionTypes.CHECK_COLLECTION_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        collectionAvailability: action.payload,
        loading: false,
      }
    }
    case VenueActionTypes.CHECK_COLLECTION_AVAILABILITY_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case VenueActionTypes.CHECK_DELIVERY_AVAILABILITY_START: {
      return { ...state, loading: true, deliveryAvailability: false }
    }
    case VenueActionTypes.CHECK_DELIVERY_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        deliveryAvailability: action.payload,
        loading: false,
      }
    }
    case VenueActionTypes.CHECK_DELIVERY_AVAILABILITY_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case VenueActionTypes.EMPTY_VENUE_DETAIL: {
      return {
        ...state,
        venueDetail: {},
      }
    }

    case VenueActionTypes.SET_PAYMENT_SETTING: {
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          settings: {
            ...state.restaurant.settings,
            ...action.payload,
          },
        },
        loading: false,
      }
    }

    default: {
      return state
    }
  }
}

export default VenueReducer
