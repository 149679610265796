import React from "react"
import { useDispatch } from "react-redux"
import Card from "@material-ui/core/Card"
import AddIcon from "@material-ui/icons/Add"
import MinimizeIcon from "@material-ui/icons/Minimize"
import { makeStyles, Theme } from "@material-ui/core/styles"
import clsx from "clsx"

import { hasFamilyPropertyItemSelected } from "@store/cart/cartHelpers"
import { TextField, Typography } from "@material-ui/core"
import {
  applyDiscountVoucher,
  increaseItemAmountOrderPage,
  decreaseItemAmountOrderPage,
} from "@store/cart/cartActions"
import { formatImageUrl } from "@helpers/utilsHelper"
import {
  Label19Bold,
  Heading3,
  Label14,
  Label17,
  Label13,
  Label14Bold,
} from "@components/Text/Text"
import Button from "@components/buttons/Button"
interface Props {
  items: any
  typography?: string
  deliveryPrice: number
  selectedOption: string
  subTotal: number
  total: number
  invalidDiscountCode: boolean
  discountQuantity: number
  alreadyUsed: boolean
  addMoreItems: boolean
  discountType: string
  discountAmount: number
  discountApplicable: boolean
  isVoucherMatchWithOrderType?: boolean
  hasDiscountVoucher: boolean
  setDiscountVoucherCode?: () => void
  discountVoucherCode?: string
}
interface StyleProps {
  typography?: string
}
const useCardStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    "& .Mui-focused": {
      color: props.typography,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: props.typography,
    },
  }),
}))
const ItemCard = ({
  items,
  total,
  subTotal,
  discountQuantity,
  alreadyUsed,
  typography,
  deliveryPrice,
  selectedOption,
  addMoreItems,
  invalidDiscountCode,
  discountVoucherCode,
  setDiscountVoucherCode,
  discountApplicable,
  discountType,
  hasDiscountVoucher,
  discountAmount,
  isVoucherMatchWithOrderType = true,
}) => {
  const useStyles = makeStyles(() => ({
    root: () => ({
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    }),
    margin: () => ({
      marginLeft: "10px",
      width: "70%",
    }),
    totals: () => ({
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      margin: "0 0 1rem 0",
      fontSize: "14px",
    }),
  }))

  const dispatch = useDispatch()
  const Classes = useStyles()
  const styleProps: StyleProps = { typography }
  const discountClasses = useCardStyles(styleProps)
  // const { totalPrice } = cartCounter(items)
  return (
    <Card
      elevation={0}
      className={[
        "flex flex-col self-center justify-start w-11/12 mt-16 bg-white shadow-2xl rounded-4",
        Classes.root,
      ].join()}
    >
      <Heading3 style={{ marginTop: 8, marginBottom: 8, marginLeft: 14 }}>
        Mi Pedido
      </Heading3>
      <>
        <>
          {items.map((item) => (
            <>
              <div className={"flex flex-row w-full p-8"}>
                <img
                  className="object-cover rounded"
                  src={formatImageUrl(item.image)}
                  alt={item.title}
                  style={{
                    width: "60px",
                    height: "60px",
                  }}
                />
                <div className={"flex flex-row w-full justify-between"}>
                  <div className={"flex flex-col ml-2"}>
                    <Label19Bold>{item.title}</Label19Bold>
                    {item.extraCategories &&
                      item.extraCategories.length > 0 &&
                      item.extraCategories.map(({ title, items }) => {
                        if (hasFamilyPropertyItemSelected(items)) {
                          return (
                            <>
                              <Typography variant="subtitle2">
                                {title}
                              </Typography>
                              {items.map(({ title, selected }) => {
                                if (selected) {
                                  return (
                                    <Typography
                                      style={{ color: "#c3c3c3" }}
                                      variant="body2"
                                    >
                                      {title}
                                    </Typography>
                                  )
                                }
                                return null
                              })}
                            </>
                          )
                        }
                        return null
                      })}
                  </div>
                  <div className={"flex flex-col items-center"}>
                    <Label19Bold
                      style={{
                        color: typography,
                      }}
                    >
                      € {item.price.toFixed(2)}
                    </Label19Bold>
                    <div
                      className={
                        "flex flex-row items-center justify-between w-full h-32 p-1 pl-2"
                      }
                    >
                      <div
                        className={"flex justify-center items-center w-12 h-12"}
                        style={{ backgroundColor: typography }}
                      >
                        <MinimizeIcon
                          style={{ color: "white", marginBottom: 14 }}
                          onClick={() =>
                            dispatch(decreaseItemAmountOrderPage({ item }))
                          }
                        />
                      </div>
                      <Heading3 className={"ml-2 mr-2"}>
                        {item.quantity}
                      </Heading3>
                      <div
                        className={"flex justify-center items-center w-12 h-12"}
                        style={{ backgroundColor: typography }}
                      >
                        <AddIcon
                          style={{ color: "white" }}
                          onClick={() =>
                            dispatch(increaseItemAmountOrderPage(item._id))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={"flex self-center h-px bg-grey mt-2 mb-4"}
                style={{ width: "96%" }}
              />
            </>
          ))}
        </>
        {hasDiscountVoucher && (
          <div>
            <hr />
            <Label14 className={clsx(Classes.margin)}>
              ¿Tienes un código de descuento
            </Label14>

            <div className="flex self-center w-full mt-6 mb-5">
              <TextField
                label={<Label17>Codigo Descuento</Label17>}
                name="discountVoucherCode"
                value={discountVoucherCode}
                className={clsx(Classes.margin, discountClasses.root, "w-full")}
                onChange={(e) => setDiscountVoucherCode(e.target.value)}
              />

              <Button
                title={"Aplicar"}
                backgroundColor={typography}
                classes={
                  "flex items-center justify-center self-center w-1/4 text-white py-2 px-4 border border-transparent cursor-pointer outline-none mt-6"
                }
                style={{
                  borderRadius: "25px",
                  fontSize: "8px",
                  bottom: 30,
                }}
                onClick={() =>
                  dispatch(
                    applyDiscountVoucher(discountVoucherCode, selectedOption),
                  )
                }
              />
            </div>
            <div className="flex flex-row ml-3">
              {addMoreItems && (
                <Label13
                  className={"text-red"}
                >{`El código de descuento es correcto pero la cantidad minima para solicitarlo es ${discountQuantity}€`}</Label13>
              )}
              {!isVoucherMatchWithOrderType &&
                !addMoreItems &&
                !invalidDiscountCode &&
                !alreadyUsed && (
                  <Label13
                    className={"text-red"}
                  >{`Cupón no disponible para este tipo de orden.`}</Label13>
                )}
              {invalidDiscountCode && (
                <Label13
                  className={"text-red"}
                >{`El codigo de descuento no existe`}</Label13>
              )}
              {alreadyUsed && (
                <Label13
                  className={"text-red"}
                >{`Discount code already used`}</Label13>
              )}
            </div>
          </div>
        )}
        <div
          className={"flex flex-col items-end mr-8"}
          style={{ marginTop: -18 }}
        >
          <div className={"flex flex-row justify-between w-7/12"}>
            <Heading3 style={{ textAlign: "right" }}>IVA(10%)</Heading3>
            <Heading3>{(total * 0.1).toFixed(2) + "€"}</Heading3>
          </div>
          <div className={"flex flex-row justify-between w-7/12"}>
            {hasDiscountVoucher && discountApplicable && (
              <div className={Classes.totals}>
                <Label14Bold className="text-red">
                  Discount {discountAmount}
                  {discountType === "percentage" ? "%" : "€"}
                </Label14Bold>
                <Label14Bold className="pull-left text-red">
                  -
                  {discountType === "percentage"
                    ? (subTotal * Number(discountAmount)) / 100
                    : Number(discountAmount)}{" "}
                  €
                </Label14Bold>
              </div>
            )}
          </div>
          {selectedOption && selectedOption == "2" && deliveryPrice > 0 && (
            <div
              className={"flex flex-row justify-between w-7/12"}
              style={{ marginTop: -22 }}
            >
              <Heading3 style={{ textAlign: "right" }}>
                Envío a Domicilio
              </Heading3>
              <Heading3> {deliveryPrice.toFixed(2) + "€"}</Heading3>
            </div>
          )}
          <div
            className={"flex flex-row justify-between w-7/12"}
            style={{ marginTop: -22 }}
          >
            <Heading3 style={{ textAlign: "right" }}>Total</Heading3>
            <Heading3 className={"text-black"}>
              {deliveryPrice > 0 && selectedOption && selectedOption == "2"
                ? (total + deliveryPrice).toFixed(2) + "€"
                : total.toFixed(2) + "€"}
            </Heading3>
          </div>
        </div>
      </>
    </Card>
  )
}

export default ItemCard
