import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import AddressMapModal from "@components/modals/AddressMapModal"
import AddressDetailModal from "@components/modals/AddressDetailModal"
import { addAddress } from "@store/user/UserActions"

const AddAddressModal = ({ openAddress, setOpenAddress, venueId }) => {
  const dispatch = useDispatch()
  const { latitude: lat, longitude: long } = useSelector(
    ({ user }: RootState) => user,
  )
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)

  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const [title, setTitle] = useState("")
  const [city, setCity] = useState("")
  const [details, setDetail] = useState("")
  const [address, setAddress] = useState("")
  const [postcode, setPostCode] = useState("")
  const [open, setOpen] = useState(false)
  const [latitude, setLatitude] = useState<number>(lat)
  const [longitude, setLongitude] = useState<number>(long)
  const { contactInfo } = venueDetail

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  useEffect(() => {
    setLatitude(lat)
    setLongitude(long)
  }, [lat, long])
  useEffect(() => {
    setTitle("")
    setCity("")
    setDetail("")
    setAddress("")
    setPostCode("")
  }, [contactInfo])

  const clearData = () => {
    setTitle("")
    setCity("")
    setDetail("")
    setAddress("")
    setPostCode("")
  }

  const onDetailChange = (e) => {
    switch (e.target.name) {
      case "address":
        setAddress(e.target.value)
        break
      case "title":
        setTitle(e.target.value)
        break
      case "city":
        setCity(e.target.value)
        break
      case "postcode":
        setPostCode(e.target.value)
        break
      case "details":
        setDetail(e.target.value)
        break
    }
  }
  const handleMapSubmit = () => {
    dispatch(
      addAddress(
        {
          title,
          city,
          address,
          postcode,
          details,
          longitude: longitude.toString(),
          latitude: latitude.toString(),
          userId: localStorage.getItem("userId"),
        },
        venueId,
      ),
    )
    setOpen(false)
  }
  return (
    <>
      <AddressMapModal
        showMap={open}
        setOpenMap={setOpen}
        typography={typography}
        clearData={clearData}
        latitude={latitude}
        setLatitude={setLatitude}
        longitude={longitude}
        setLongitude={setLongitude}
        onMapSubmit={handleMapSubmit}
      />
      <AddressDetailModal
        open={openAddress}
        setOpenMap={setOpen}
        setOpen={setOpenAddress}
        onChange={onDetailChange}
        form={{ title, city, address, postcode, details, longitude, latitude }}
        typography={typography}
        venueId={venueId}
      />
    </>
  )
}

export default AddAddressModal
