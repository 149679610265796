import React, { useState, useEffect, useRef } from "react"
import Cards from "react-credit-cards"
import TextField from "@material-ui/core/TextField"
import "react-credit-cards/es/styles-compiled.css"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  Select,
  Input,
} from "@material-ui/core"
import clsx from "clsx"
import _ from "lodash"

import MobileHeader from "@components/header/MobileHeader"
import { saveCard } from "@store/user/UserActions"
import { Label17 } from "@components/Text/Text"
import Button from "@components/buttons/Button"

interface StyleProps {
  typography?: string
  monthError?: boolean
  yearError?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    marginTop: "0.4rem",
    marginBottom: "0.4rem",
    "& .Mui-focused": {
      color: props.typography,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: props.typography,
    },
  }),
  rootMonth: (props) => ({
    margin: "0.4rem",
    "& .Mui-focused": {
      color: props.typography,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: props.monthError ? "red" : props.typography,
    },
  }),
  rootYear: (props) => ({
    margin: "0.4rem",
    "& .Mui-focused": {
      color: props.typography,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: props.yearError ? "red" : props.typography,
    },
  }),
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}))

const PaymentPage = () => {
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const { user } = useSelector(({ user }: RootState) => user)

  const [state, setState] = useState({
    cvc: "",
    expiry: "",
    name: "",
    number: "",
    month: "01",
    year: "20",
  })
  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const [focus, setFocus] = useState("")
  const [monthError, setMonthError] = useState(true)
  const [numberError, setnumberError] = useState(true)
  const [yearError, setYearError] = useState(true)
  const [cvcError, setCvcError] = useState(true)
  const [nameError, setNameError] = useState(true)

  const history = useHistory()
  const styleProps: StyleProps = { typography, monthError, yearError }
  const Classes = useStyles(styleProps)
  const dispatch = useDispatch()

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const handleInputFocus = (e) => {
    setFocus(e.target.name)
  }

  const handleInputChange = (e) => {
    if (e.target.name === "number") {
      e.target.value = Math.max(0, parseInt(e.target.value))
        .toString()
        .slice(0, 16)
      if (e.target.value.length == 16) {
        setnumberError(false)
      } else {
        setnumberError(true)
      }
    }

    if (e.target.name === "cvc") {
      e.target.value = e.target.value.toString().slice(0, 3)
      if (e.target.value.length !== 3) {
        setCvcError(true)
      } else {
        setCvcError(false)
      }
    }

    if (e.target.name === "month") {
      setMonthError(false)
    }

    if (e.target.name === "year") {
      setYearError(false)
    }

    if (e.target.name === "name") {
      if (e.target.value.length > 5) {
        setNameError(false)
      } else {
        setNameError(true)
      }
    }

    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const canBeSubmited = () => {
    return !numberError && !cvcError && !nameError
  }

  const saveCardDetails = () => {
    const { _id: userId } = user

    const cardDetails = {
      cardNumber: state.number,
      expMonth: state.month,
      expYear: state.year,
      cvc: state.cvc,
      userId: userId!!,
    }

    dispatch(saveCard(cardDetails, true))
  }

  return (
    <MobileHeader
      title={venueDetail.title}
      typography={typography}
      onBack={() => history.goBack()}
    >
      <div className="mx-6 flex flex-col items-center justify-center min-h-screen">
        <Cards
          cvc={state.cvc}
          expiry={state.expiry}
          focused={focus}
          name={state.name}
          number={state.number}
        />
        <div className="mt-24 flex flex-col items-center w-full px-32">
          <TextField
            label={<Label17>Nombre</Label17>}
            name="name"
            className={clsx(Classes.root, "w-full")}
            onFocus={handleInputFocus}
            onChange={handleInputChange}
          />

          <TextField
            label={<Label17>Número</Label17>}
            name="number"
            type="number"
            value={state.number}
            className={clsx(Classes.root, Classes.number, "w-full")}
            onFocus={handleInputFocus}
            onChange={handleInputChange}
            inputProps={{ maxLength: 16 }}
          />

          <div className="flex justify-between w-full">
            <FormControl
              className={clsx(Classes.rootMonth, "w-1/4")}
              variant={"outlined"}
            >
              <InputLabel className="mt-4 -ml-6" htmlFor={"month"}>
                {"MM"}
              </InputLabel>
              <Select
                name="month"
                native
                value={state.month}
                onChange={handleInputChange}
                input={<Input id="month" />}
              >
                {[
                  "01",
                  "02",
                  "03",
                  "04",
                  "05",
                  "06",
                  "07",
                  "08",
                  "09",
                  "10",
                  "11",
                  "12",
                ].map((month) => {
                  return (
                    <option className="text-center mt-6 mb-6" value={month}>
                      {month}
                    </option>
                  )
                })}
              </Select>
            </FormControl>

            <FormControl
              className={clsx(Classes.rootMonth, "w-1/4")}
              variant={"outlined"}
            >
              <InputLabel className="mt-4 -ml-6" htmlFor={"year"}>
                {"YY"}
              </InputLabel>
              <Select
                name="year"
                native
                value={state.year}
                onChange={handleInputChange}
                input={<Input id="year" />}
              >
                {[...Array(21).keys()].map((year) => {
                  return (
                    <option className="text-center mt-6 mb-6" value={20 + year}>
                      {20 + year}
                    </option>
                  )
                })}
              </Select>
            </FormControl>
            <TextField
              label={<Label17>CVC</Label17>}
              name="cvc"
              type="number"
              className={clsx(Classes.root, "w-1/4")}
              onFocus={handleInputFocus}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <Button
          title={"Guardar"}
          backgroundColor={typography}
          classes={
            "flex items-center justify-center self-center w-11/12 h-24 text-white py-2 px-4 border border-transparent cursor-pointer outline-none fixed"
          }
          style={{
            borderRadius: "25px",
            fontSize: "14px",
            bottom: 30,
            backgroundColor: !canBeSubmited() ? "lightgray" : "#f5aa00",
            color: !canBeSubmited() ? "black" : "white",
          }}
          disabled={!canBeSubmited()}
          onClick={saveCardDetails}
        />
      </div>
    </MobileHeader>
  )
}

export default PaymentPage
