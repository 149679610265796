import  {axiosInstance}  from "@api/axios"

import { OrderFromTableActionTypes } from "@store/redux/actionTypes";
import { getTableUrl } from "@api/endPoints"

export const getTable = (id) => {
  return (dispatch) => {
    dispatch({
      type: OrderFromTableActionTypes.GET_TABLE_START,
    });

    const url = getTableUrl(id);
    axiosInstance
      .get(url)
      .then((res) => {
        getTableSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getTableFail(dispatch, "There was an error connection");
      });
  };
};

const getTableFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderFromTableActionTypes.GET_TABLE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getTableSuccess = (dispatch, data) => {
  dispatch({
    type: OrderFromTableActionTypes.GET_TABLE_SUCCESS,
    payload: data,
  });
};