import React from "react"
import Button from "@components/buttons/Button"

import { Heading2, Heading5 } from "@components/Text/Text"

interface Props {
  typography?: string
  orderId?: string
}

const MobileOrderConfirmed = ({ typography, orderId }: Props) => {
  return (
    <div className={"flex flex-col justify-center h-screen"}>
      <div className={"flex flex-col w-full items-center justify-center"}>
        <img
          src={require("@assets/Images/cheers.png")}
          style={{ height: 120, width: 130 }}
        />
        <Heading2 style={{ marginTop: "2rem" }}>¡Felicidades!</Heading2>
        <Heading5
          style={{
            marginTop: "0.5rem",
            fontWeight: 500,
            color: "grey",
            textAlign: "center",
          }}
        >
          Tu Orden ha sido enviada
        </Heading5>
        <Button
          title={"Continuar"}
          backgroundColor={typography}
          classes={
            "flex items-center justify-center self-center w-4/5 mt-16 h-24 text-white py-2 px-4 border border-transparent cursor-pointer outline-none"
          }
          style={{
            borderRadius: "25px",
            fontSize: "12px",
          }}
          onClick={() => {
            window.location.href = `/order-details/${orderId}`
          }}
        />
      </div>
    </div>
  )
}

export default MobileOrderConfirmed
