import { ItemOrderMenu } from "@models/Cart"
import AmericanCard from "@assets/Images/american-card.png"
import MasterCard from "@assets/Images/mastercard-card.png"
import visaCard from "@assets/Images/visa-card.png"
import otherCard from "@assets/Images/other-card.png"

export const cartCounter = (itemsCart: ItemOrderMenu[]) => {
  let totalItems = 0
  let totalPrice = 0

  if (itemsCart) {
    itemsCart.map((item) => {
      totalItems = totalItems + Number(item.quantity)
      totalPrice = totalPrice + Number(item.price * item.quantity)
    })
  }

  return { totalItems, totalPrice: totalPrice.toFixed(2) }
}

export const getDiscountedPrice = (
  totalPrice,
  discountApplicable,
  discountType,
  discountAmount,
) => {
  let discountedPrice = Number(totalPrice)
  if (discountApplicable) {
    if (discountType === "percentage") {
      discountedPrice =
        discountedPrice - (discountedPrice * Number(discountAmount)) / 100
    } else if (discountType === "quantity") {
      discountedPrice = discountedPrice - Number(discountAmount)
    }
  }

  return discountedPrice
}

export const totalPriceCustomItem = (item: ItemOrderMenu) => {
  let newPrice = Number(item.price)

  item.extraCategories?.map((item: ExtraCategory) => {
    item.items?.map((item: ExtraCategoryItem) => {
      if (item.selected && item.price) {
        newPrice = Number(newPrice) + Number(item.price)
      }
    })
  })

  return newPrice
}

export const hasFamilyPropertyItemSelected = (items: ExtraCategoryItem[]) => {
  let hasSelected = false

  items?.map((item: ExtraCategoryItem) => {
    if (item.selected) {
      hasSelected = true
    }
  })

  return hasSelected
}

export const getCardImage = (card?: string) => {
  let cardImage

  switch (card) {
    case "Visa": {
      cardImage = visaCard
      break
    }
    case "MasterCard": {
      cardImage = MasterCard
      break
    }
    case "American Express": {
      cardImage = AmericanCard
      break
    }
    default: {
      cardImage = otherCard
      break
    }
  }

  return cardImage
}
