import { UserActionTypes } from "@store/redux/actionTypes"

const INITIAL_STATE: UserState = {
  user: {},
  addresses: [],
  orders: [],
  orderDetail: {},
  orderId: "",
  loading: false,
  latitude: 0,
  longitude: 0,
  address: {
    _id: "",
    addressId: "",
    title: "",
    address: "",
    city: "",
    postcode: "",
    details: "",
    latitude: 0,
    longitude: 0,
  },
}

interface Action {
  payload: any
  type: string
}

const AuthReducer = (
  state: UserState = INITIAL_STATE,
  action: Action,
): UserState => {
  switch (action.type) {
    case UserActionTypes.SET_USER_ADDRESS: {
      return { ...state, address: action.payload }
    }

    case UserActionTypes.USER_ADD_ADDRESS_START: {
      return { ...state, loading: true }
    }
    case UserActionTypes.USER_ADD_ADDRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case UserActionTypes.USER_ADD_ADDRESS_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case UserActionTypes.GET_POSTION_FROM_ADDRESS_START: {
      return { ...state, loading: true }
    }
    case UserActionTypes.GET_POSTION_FROM_ADDRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      }
    }
    case UserActionTypes.GET_POSTION_FROM_ADDRESS_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case UserActionTypes.ADD_USER_PHONE_START: {
      return { ...state, loading: true }
    }
    case UserActionTypes.ADD_USER_PHONE_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case UserActionTypes.ADD_USER_PHONE_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case UserActionTypes.USER_ADDRESS_LIST_START: {
      return { ...state, loading: true, addresses: [] }
    }
    case UserActionTypes.USER_ADDRESS_LIST_SUCCESS: {
      return {
        ...state,
        addresses: action.payload,
        loading: false,
      }
    }
    case UserActionTypes.USER_ADDRESS_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case UserActionTypes.GET_USER_DETAILS_START: {
      return { ...state, loading: true }
    }
    case UserActionTypes.GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
      }
    }
    case UserActionTypes.GET_USER_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }

    case UserActionTypes.DELETE_USER_DETAILS_SUCCESS: {
      return { ...state, loading: true, user: {} }
    }

    case UserActionTypes.GET_CARD_LIST_START:
      return {
        ...state,
        loading: true,
      }

    case UserActionTypes.GET_CARD_LIST_SUCCESS:
      return {
        ...state,
        cards: action.payload,
        loading: false,
      }

    case UserActionTypes.GET_CARD_LIST_FAIL:
      return {
        ...state,
        loading: false,
      }
    case UserActionTypes.SAVE_CARD_DETAILS_START: {
      return { ...state, loading: true }
    }
    case UserActionTypes.SAVE_CARD_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case UserActionTypes.SAVE_CARD_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case UserActionTypes.UPDATE_USER_START: {
      return { ...state, loading: true }
    }
    case UserActionTypes.UPDATE_USER_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case UserActionTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case UserActionTypes.CHANGE_PASSWORD_START: {
      return { ...state, loading: true }
    }
    case UserActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case UserActionTypes.CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case UserActionTypes.GET_ORDERS_LIST_START:
      return {
        ...state,
        loading: true,
      }

    case UserActionTypes.GET_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
      }

    case UserActionTypes.GET_ORDERS_LIST_FAIL:
      return {
        ...state,
        loading: false,
      }

    case UserActionTypes.GET_ORDER_DETAIL_START:
      return {
        ...state,
        orderDetail: {},
        loading: true,
      }

    case UserActionTypes.GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderDetail: action.payload,
        loading: false,
      }

    case UserActionTypes.GET_ORDER_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
      }

    case UserActionTypes.ORDER_CANCEL_START:
      return {
        ...state,
        loading: true,
      }

    case UserActionTypes.ORDER_CANCEL_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UserActionTypes.ORDER_CANCEL_FAIL:
      return {
        ...state,
        loading: false,
      }
    case UserActionTypes.SET_ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
        loading: false,
      }
    default: {
      return state
    }
  }
}

export default AuthReducer
