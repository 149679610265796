import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import CustomLoader from "@components/Loaders/CustomLoader"
import MobileHeader from "@components/header/MobileHeader"
import { getOrderDetail, orderCancel } from "@store/user/UserActions"
import ItemsCard from "./components/ItemsCard"
import LocationCard from "./components/LocationCard"
import OrderStepper from "./components/OrderStepper"
import Button from "@components/buttons/Button"
import { CartType } from "@models/Cart"

const MyOrderDetailPage = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = props.match.params

  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const { loading, orderDetail } = useSelector(({ user }: any) => user)

  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const [order, setOrder] = useState<any>(orderDetail)
  const [orderStatus, setOrderStatus] = useState(0)
  useEffect(() => {
    dispatch(getOrderDetail(id))
  }, [])

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])
  useEffect(() => {
    if (orderDetail && Object.keys(orderDetail).length > 0) {
      setOrder(orderDetail)
    }
  }, [orderDetail])
  useEffect(() => {
    if (order && Object.keys(order).length > 0) {
      let status = parseInt(order.status[0].status)
      if (order.type == CartType.DELIVERY) {
        switch (status) {
          case 2:
            setOrderStatus(1)
            break
          case 3:
            setOrderStatus(2)
            break
          case 4:
            setOrderStatus(3)
            break

          case 5:
            setOrderStatus(4)
            break
          default:
            setOrderStatus(status)
        }
      } else if (order.type == CartType.COLLECT) {
        switch (status) {
          case 2:
            setOrderStatus(1)
            break
          case 3:
            setOrderStatus(2)
            break
          case 7:
            setOrderStatus(3)
            break
          case 8:
            setOrderStatus(4)
            break
          default:
            setOrderStatus(status)
        }
      } else if (order.type == CartType.ORDER_FROM_TABLE) {
        switch (status) {
          case 0:
            setOrderStatus(0)
            break
          case 1:
            setOrderStatus(0)
            break
          case 7:
            setOrderStatus(1)
            break
          default:
            setOrderStatus(status)
        }
      } else {
        setOrderStatus(status)
      }
    }
  }, [order])
  return (
    <CustomLoader loading={loading}>
      <MobileHeader
        title={"Detalle Orden"}
        typography={typography}
        onBack={() => history.goBack()}
        style={{ marginBottom: 0 }}
        rightButtonPress={() => {
          dispatch(orderCancel(order._id, "6"))
        }}
      >
        <div className={"flex flex-col w-full self-center mt-36 mb-8"}>
          {order && Object.keys(order).length > 0 && (
            <>
              <OrderStepper
                typography={typography}
                status={orderStatus}
                delivery={order.type == "2" ? true : false}
                order={order}
              />
              <div
                className={`flex flex-col w-full ${
                  orderStatus == 6 ? "mt-56" : "mt-96"
                }`}
              >
                {order.type == "2" && (
                  <LocationCard
                    addressItem={order.address}
                    typography={typography}
                  />
                )}
                <ItemsCard
                  items={order.items}
                  discountVoucherId={order.discountVoucherId}
                  discountVoucherAmount={order.discountVoucherAmount}
                  typography={typography}
                />
              </div>
            </>
          )}
        </div>
        {order && Object.keys(order).length > 0 && orderStatus == 0 && (
          <Button
            title={"Cancelar orden"}
            backgroundColor={typography}
            classes={
              "flex items-center justify-center self-center w-11/12 h-24 text-white py-2 px-4 border border-transparent cursor-pointer outline-none md:relative fixed mt-0 md:mt-10"
            }
            style={{
              borderRadius: "25px",
              fontSize: "14px",
              bottom: 10,
            }}
            onClick={() => {
              dispatch(orderCancel(order._id, "6"))
            }}
          />
        )}
      </MobileHeader>
    </CustomLoader>
  )
}

export default MyOrderDetailPage
