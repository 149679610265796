import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import TableIcon from "@material-ui/icons/LocalDining"
import { useHistory } from "react-router"
import { useStripe } from "@stripe/react-stripe-js"

import Button from "@components/buttons/Button"
import ItemCard from "./ItemCard"
import { userAddressList, getCardList } from "@store/user/UserActions"
import { cartCounter, getDiscountedPrice } from "@store/cart/cartHelpers"
import PaymentCard from "./PaymentCard"
import VenueCard from "./VenueCard"
import LocationCard from "./LocationCard"
import CommentsCard from "./CommentsCard"
import RadioGroupCard from "@components/radioGroupCard/RadioGroupCard"
import {
  applyDiscountVoucher,
  getRedsysParam,
  setCartPendingPayment,
  submitOrder,
} from "@store/cart/cartActions"
import { CartType, ItemOrderMenu } from "@models/Cart"
import { setSelectedAddress, selectCard } from "@store/cart/cartActions"
import TopCard from "./TopCard"
import MinOrderCard from "./MinOrderCard"
import {
  checkCollectionAvailability,
  checkDeliveryAvailability,
} from "@store/venues/VenueActions"
import AddPhoneNumberModal from "@components/modals/AddPhoneNumberModal"
import { Menu } from "@models/Menu"

const MobileCheckoutForm = ({ venueId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const stripe = useStripe()
  const {
    discountApplicable,
    discountAmount,
    discountType,
    discountCode,
    addMoreItems,
    discountQuantity,
    invalidDiscountCode,
    alreadyUsed,
  } = useSelector(({ cart }: RootState) => cart)
  const cart = useSelector(({ cart }: RootState) => cart.cart)
  const cartItems = cart.items
  const { totalPrice } = cartCounter(cartItems)
  const menuList: Menu = useSelector(({ menu }: RootState) => menu.menuList)

  const { addresses, cards, user } = useSelector(({ user }: RootState) => user)

  const orderFromTable = useSelector(
    ({ orderFromTable }: any) => orderFromTable.orderFromTable,
  )
  const selectedOption = useSelector(({ venues }: any) => venues.selectedOption)
  const {
    address: selectedAddress,
    selectedCard,
    discountVoucherId,
    isVoucherMatchWithOrderType = true,
  } = useSelector(({ cart }: any) => cart)
  const settings = useSelector(
    ({ venues }: RootState) => venues.restaurant.settings,
  )
  const hasRedsys = settings?.redsys ? settings?.redsys : false

  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)
  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)

  const [typography, setTypography] = useState("rgb(202, 156, 77)")
  const [comments, setComments] = useState("")
  const [selectedValue, setSelectedValue] = useState("withCard")
  const [openPhoneModal, setPhoneModal] = useState(false)
  const [minOrderPrice, setMinOrderPrice] = useState(
    Number(venueDetail.orderInfo?.minOrderPrice),
  )
  const [discountVoucherCode, setDiscountVoucherCode] = useState("")
  const [total, setTotalPrice] = useState(parseInt(totalPrice))
  const [subTotal, setSubTotal] = useState(total)

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }

    const payByCash = restaurant.settings && restaurant.settings.payByCash
    const payByCard = restaurant.settings && restaurant.settings.payByCard

    if (payByCash && !payByCard) {
      setSelectedValue("withCash")
    }
    if (!payByCash && payByCard) {
      setSelectedValue("withCard")
    }
  }, [restaurant])

  useEffect(() => {
    dispatch(getCardList())
    if (discountVoucherId) {
      dispatch(applyDiscountVoucher(discountVoucherCode, selectedOption))
    }
  }, [])
  useEffect(() => {
    if (discountCode && !discountVoucherCode) {
      setDiscountVoucherCode(discountCode)
    }
  }, [discountCode])
  useEffect(() => {
    if (Object.keys(venueDetail) && venueDetail && venueDetail._id) {
      dispatch(userAddressList(localStorage.getItem("userId"), venueDetail._id))
    }
  }, [venueDetail])
  useEffect(() => {
    setMinOrderPrice(Number(selectedAddress?.minOrderPrice || 0))
  }, [selectedAddress])

  useEffect(() => {
    if (
      selectedAddress &&
      Object.keys(selectedAddress).length < 1 &&
      addresses &&
      addresses.length > 0 &&
      selectedOption == "2"
    ) {
      const address = addresses.find((address) => {
        if (
          address &&
          Object.keys(address).length > 1 &&
          address.hasOwnProperty("isValidAddress") &&
          address["isValidAddress"] == true
        ) {
          return address
        }
      })
      address && dispatch(setSelectedAddress(address))
    }
  }, [addresses])
  useEffect(() => {
    if (cart.items) {
      let { totalPrice } = cartCounter(cart.items)

      let discountedPrice = getDiscountedPrice(
        totalPrice,
        discountApplicable,
        discountType,
        discountAmount,
      )

      let grandTotal =
        selectedOption && selectedOption == "2" && cart.deliveryPrice
          ? Number(discountedPrice.toFixed(2)) + Number(cart.deliveryPrice)
          : Number(discountedPrice.toFixed(2))

      if (discountQuantity && discountQuantity > Number(totalPrice)) {
        dispatch(applyDiscountVoucher(discountVoucherCode, selectedOption))
      }

      setTotalPrice(grandTotal)
      setSubTotal(Number(totalPrice))
    }
  }, [cart.items, cart.deliveryPrice, discountApplicable])
  useEffect(() => {
    if (
      selectedCard == "" &&
      cards &&
      cards.length > 0 &&
      cards[0].id &&
      selectedValue == "withCard"
    )
      dispatch(selectCard(cards[0].id))
  }, [selectedCard, cards])

  const handleAddAddress = () => {
    history.push(`/mobileAddressNamePage`)
  }

  const viewAddressList = () => {
    history.push(`/menu/myAddress/${venueId}`)
  }

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value)
    if (e.target.value == "withCash") {
      dispatch(selectCard(""))
    }
    if (
      e.target.value == "withCard" &&
      selectedCard == "" &&
      cards &&
      cards.length > 0 &&
      cards[0].id
    ) {
      dispatch(selectCard(cards[0].id))
    }
  }

  const handleOrderSubmit = () => {
    let deliveryPrice = 0
    if (
      selectedOption === CartType.DELIVERY &&
      selectedAddress &&
      selectedAddress.minDeliveryPrice
    ) {
      deliveryPrice = Number(selectedAddress.minDeliveryPrice)
    }
    let totalPrice = 0
    cart.items.map((item: ItemOrderMenu) => {
      totalPrice = totalPrice + Number(item.price) * item.quantity
    })
    let payload = {
      clientId: localStorage.getItem("clientId"),
      userId: user._id,
      price: Number(totalPrice.toFixed(2)),
      items: cart.items,
      type: selectedOption,
      addressId: selectedAddress._id,
      venueId: venueDetail._id,
      deliveryPrice: selectedOption == "2" ? deliveryPrice : 0,
      orderTime: "",
      orderTable: orderFromTable._id,
      comments,
      card: selectedValue == "withCard" ? selectedCard : "",
      payByCard: selectedValue == "withCard" ? true : false,
      discountVoucherId,
      payByRedsys: hasRedsys && selectedValue == "withCard" ? true : false,
      isFromSinqro: menuList.isSinqro !== undefined && menuList.isSinqro,
    }
    if (canBeSubmit()) {
      if (!user.phone || user.phone == "") {
        setPhoneModal(true)
        return
      }
      if (selectedOption === CartType.ORDER_FROM_TABLE) {
        if (payload.payByRedsys) {
          dispatch(setCartPendingPayment(payload))
          dispatch(getRedsysParam(payload))
        } else {
          dispatch(submitOrder(payload, stripe))
        }
      } else if (selectedOption === CartType.COLLECT) {
        dispatch(
          checkCollectionAvailability(
            venueId,
            false,
            history,
            true,
            payload,
            stripe,
          ),
        )
      } else if (selectedOption === CartType.DELIVERY) {
        dispatch(
          checkDeliveryAvailability(
            venueId,
            false,
            history,
            true,
            payload,
            stripe,
          ),
        )
      }
    }
  }

  const canBeSubmit = () => {
    if (selectedValue == "withCard" && !hasRedsys && selectedCard == "") {
      return false
    }
    if (selectedOption == "2" && selectedAddress._id == undefined) {
      return false
    }
    return true
  }

  return (
    <>
      <TopCard
        total={total}
        deliveryPrice={Number(selectedAddress.minDeliveryPrice)}
        selectedOption={selectedOption}
      />
      <RadioGroupCard
        typography={typography}
        selectedValue={selectedValue}
        onRadioChange={handleRadioChange}
      />
      {selectedOption == CartType.DELIVERY &&
        cartItems?.length > 0 &&
        total < minOrderPrice && (
          <MinOrderCard
            items={cartItems}
            typography={typography}
            minOrderPrice={minOrderPrice}
          />
        )}
      {selectedValue === "withCard" && !hasRedsys && (
        <PaymentCard
          typography={typography}
          cards={cards}
          selectedCard={selectedCard}
        />
      )}
      {selectedOption == CartType.COLLECT ? (
        <VenueCard
          icon={
            <LocationOnIcon
              style={{ color: typography }}
              className={"ml-4 mr-6"}
            />
          }
          venueName={venueDetail.title}
          typography={typography}
        />
      ) : (
        selectedOption !== CartType.ORDER_FROM_TABLE && (
          <LocationCard
            typography={typography}
            addressItem={selectedAddress}
            addresses={addresses}
            addAddress={handleAddAddress}
            viewAddressList={viewAddressList}
          />
        )
      )}

      {selectedOption === CartType.ORDER_FROM_TABLE && (
        <VenueCard
          icon={
            <TableIcon style={{ color: typography }} className={"ml-4 mr-6"} />
          }
          venueName={orderFromTable.title}
          typography={typography}
        />
      )}

      <CommentsCard
        typography={typography}
        value={comments}
        onValueChange={(v) => {
          setComments(v.currentTarget.value)
        }}
      />

      {cartItems && cartItems.length > 0 && (
        <ItemCard
          items={cartItems}
          typography={typography}
          deliveryPrice={Number(selectedAddress.minDeliveryPrice)}
          selectedOption={selectedOption}
          setDiscountVoucherCode={setDiscountVoucherCode}
          discountVoucherCode={discountVoucherCode}
          subTotal={subTotal}
          total={total}
          invalidDiscountCode={invalidDiscountCode}
          discountQuantity={discountQuantity}
          alreadyUsed={alreadyUsed}
          addMoreItems={addMoreItems}
          discountAmount={discountAmount}
          discountType={discountType}
          discountApplicable={discountApplicable}
          hasDiscountVoucher={restaurant.permission?.hasDiscountVoucher}
          isVoucherMatchWithOrderType={isVoucherMatchWithOrderType}
        />
      )}
      <Button
        title={"Hacer pedido"}
        backgroundColor={typography}
        classes={
          "flex items-center justify-center self-center w-11/12 h-24 text-white py-2 px-4 border border-transparent cursor-pointer outline-none fixed"
        }
        style={{
          borderRadius: "25px",
          fontSize: "14px",
          bottom: 10,
        }}
        onClick={handleOrderSubmit}
        disabled={
          !canBeSubmit() ||
          (selectedOption == CartType.DELIVERY &&
            cartItems?.length > 0 &&
            total < minOrderPrice)
        }
      />

      <AddPhoneNumberModal
        setOpen={setPhoneModal}
        open={openPhoneModal}
        user={user}
        typography={typography}
      />
    </>
  )
}

export default MobileCheckoutForm
