import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import MobileHeader from "@components/header/MobileHeader"
import { Card } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"

import EditProfileButton from "./components/EditProfileButton"
import { logout } from "@store/auth/AuthActions"

interface StyleProps {
  typography?: string
}

const MobileProfile = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const venueDetail = useSelector(({ venues }: any) => venues.venueDetail)
  const restaurant = useSelector(({ venues }: RootState) => venues.restaurant)

  const [typography, setTypography] = useState("rgb(202, 156, 77)")

  useEffect(() => {
    let typography = restaurant.settings?.typography
    if (typography) {
      setTypography(typography)
    }
  }, [restaurant])

  const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: () => ({
      boxShadow:
        "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      borderRadius: "8px",
    }),
  }))
  const styleProps: StyleProps = { typography }
  const Classes = useStyles(styleProps)

  const handleLogoutClick = () => {
    let userId = localStorage.getItem("userId")
    if (userId) dispatch(logout(true))
  }
  const handleEditProfileClick = () => {
    history.push("/update-profile")
  }
  const handleChangePasswordClick = () => {
    history.push("/change-password")
  }
  const handleMyOrderClick = () => {
    history.push("/my_orders")
  }
  return (
    <MobileHeader
      title={venueDetail.title}
      typography={typography}
      onBack={() => history.goBack()}
      style={{ marginBottom: 0 }}
    >
      <Card
        className={[
          "flex flex-col w-11/12 bg-white self-center mt-48 pl-6 pr-6",
          Classes.root,
        ].join(" ")}
      >
        <EditProfileButton
          title={"Editar Perfil"}
          typography={typography}
          border
          onClick={handleEditProfileClick}
        />
        <EditProfileButton
          title={"Cambiar Contraseña"}
          typography={typography}
          border
          onClick={handleChangePasswordClick}
        />
        <EditProfileButton
          title={"Mis Ordenes"}
          typography={typography}
          onClick={handleMyOrderClick}
        />
      </Card>
      <Card
        className={[
          "flex flex-col w-11/12 bg-white self-center mt-20 pl-6 pr-6",
          Classes.root,
        ].join(" ")}
      >
        <EditProfileButton
          title={"Salir"}
          typography={typography}
          onClick={handleLogoutClick}
        />
      </Card>
    </MobileHeader>
  )
}

export default MobileProfile
